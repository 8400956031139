import { Box } from "@mui/material";
import { useState } from "react";
import { UserCustomField } from "../components/CustomFieldForm";
import UserTag from "../components/UserTag";
import { searchUsers } from "../hooks/useUsers";
import { useUsers } from "../services/UserSettingsService";

type EditableUserTagProps = {
  value: string | null;
  onChange: (userId: string) => void;
};
export const EditableUserTag = ({ value, onChange }: EditableUserTagProps) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <Box display="flex" width="200px" sx={{ cursor: "pointer" }} onClick={() => setIsEditing(true)}>
      {isEditing ? (
        <UserCustomField
          onBlur={() => setIsEditing(false)}
          fullWidth
          error={false}
          errorMessage=""
          onChange={onChange}
          value={value}
          searchUsers={searchUsers}
          useUser={useUsers}
          label="Select or search"
        />
      ) : (
        <UserTag pointer userId={value ?? undefined} name={value ? undefined : "Unassigned"} />
      )}
    </Box>
  );
};
