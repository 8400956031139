import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { riskRegistryListTableColumns } from "../../organisms/compliance/tables/RiskRegistryListTableColumns";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";

export const RiskScenarioLoader = () => {
  const theme = useTheme();
  const columnsVisibility = riskRegistryListTableColumns({
    onUnlink: (risk) => {},
    vendor: false,
  });
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "15px 0 15px 0",
      }}
    >
      <SecondaryBox>
        <DetailsLoader />
      </SecondaryBox>
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      >
        <Box height="100%">
          <Box
            paddingBottom={"0px"}
            borderRadius="6px"
            border="1px solid"
            minHeight="100%"
            maxHeight="100%"
            overflow="auto"
            borderColor={theme.palette.custom.secondaryBorder}
            bgcolor={theme.palette.custom.secondaryBackground}
          >
            <APITableLoader
              columnsVisibility={columnsVisibility}
              tableSize="small"
              secondComponent={true}
              tableSearch={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
