import { useQuery } from "react-query";
import { ExecutionPlanFetchOptions } from "../models/types";
import { getExecutionPlans } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useExecutionPlans = (params: ExecutionPlanFetchOptions) => {
  return useQuery([QueryKey.ExecutionPlan, params], async () => {
    const { data } = await getExecutionPlans(params);
    return data;
  });
};

export const fetchExecutionPlans = (params: ExecutionPlanFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.ExecutionPlan, params], async () => {
    const { data } = await getExecutionPlans(params);
    return data;
  });
};

export const searchExecutionPlans = async (searchText: string, workflowId?: string) => {
  const executionPlans = await fetchExecutionPlans({
    workflow: workflowId ?? "",
    search: searchText,
  });
  return executionPlans.results;
};
