import { useOrganizationContext } from "../../../../context/OrganizationContext";
import { useComplianceElements } from "../../../../hooks/useComplianceElements";
import { ComplianceElement } from "../../../../models/types";
import { APITable } from "../../../APITable";
import { elementDetailsActionsColumnsVisibility } from "./columns/ElementDetailsActionsColumnsVisibility";

type ElementAdditionalActionsTableProps = {
  elementId: string;
  element: ComplianceElement;
};

export const ElementAdditionalActionsTable = ({
  elementId,
  element,
}: ElementAdditionalActionsTableProps) => {
  const { setSelectedElement } = useOrganizationContext();
  return (
    <APITable
      hideOnEmpty={true}
      useGetData={useComplianceElements}
      smallEmptyHeight={true}
      collapsable
      title={`Additional Actions`}
      hideColumnVisibility
      queryParams={{
        "element_type[]": ["Action"],
        "object_relates_to_id[]": [elementId],
      }}
      collapsedInitialState={true}
      columnsVisibility={elementDetailsActionsColumnsVisibility}
      rowOnClick={(evidence) => {
        setSelectedElement(evidence.id);
      }}
      tableSize="small"
      displayTags={true}
    />
  );
};
