import { Box, TextField, Typography, useTheme } from "@mui/material";
import CKEditorField from "../../../components/custom-fields/CKEditorField";

type CreateVendorFormProps = {
  vendorName: string;
  setVendorName: (vendorName: string) => void;
  vendorNameError: boolean;
  mainContactName: string;
  setMainContactName: (mainContactName: string) => void;
  description: string;
  setDescription: (description: string) => void;
};

export const CreateVendorForm = (props: CreateVendorFormProps) => {
  const {
    vendorName,
    setVendorName,
    vendorNameError,
    mainContactName,
    setMainContactName,
    description,
    setDescription,
  } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Vendor General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Add name, description and main contact name.
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Vendor Name
        </Typography>
        <Typography variant="body2">
          Enter a concise and descriptive name for your vendor
        </Typography>
        <TextField
          error={vendorNameError}
          helperText={vendorNameError && "Please enter name"}
          value={vendorName}
          onChange={(e) => setVendorName(e.target.value)}
          required
          label="Name"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Main Contact Name
        </Typography>
        <Typography variant="body2">Enter the main contact name for this vendor.</Typography>
        <TextField
          value={mainContactName}
          onChange={(e) => setMainContactName(e.target.value)}
          label="Main Contact Name"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Description
        </Typography>
        <Typography variant="body2">Enter a brief description of the vendor.</Typography>
        <CKEditorField value={description} onChange={setDescription} />
      </Box>
    </Box>
  );
};
