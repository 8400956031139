import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { SearchableDropdown } from "../../../../components/SearchableDropdown";
import { ExternalTask, GetJiraIssue } from "../../../../models/types";
import { searchJiraIssues } from "../../../../services/OrganizationService";

type SelectExistingJiraIssueProps = {
  jiraIssue: ExternalTask | null;
  onCreateNew: () => void;
  onAdd: (jiraIssue: ExternalTask | null) => void;
};
export const SelectExistingJiraIssue = ({
  jiraIssue,
  onAdd,
  onCreateNew,
}: SelectExistingJiraIssueProps) => {
  const [selected, setSelected] = useState<GetJiraIssue | null>(jiraIssue);
  const searchIssues = async (searchText: string) => {
    const res = await searchJiraIssues(searchText);
    return res.data;
  };
  const theme = useTheme();
  const inheritColor = theme.palette.custom.secondaryTypography;

  const handleSelectIssue = () => {
    try {
      onAdd(selected);
    } catch (error) {}
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={inheritColor}>
        Issue
      </Typography>
      <SearchableDropdown<GetJiraIssue>
        value={selected}
        allowEmptySearch={false}
        label="Search for Jira Issue"
        emptyMessage="Start typing to search for keywords within the issue summary or description"
        onChange={setSelected}
        getOptions={searchIssues}
        getOptionLabel={(option) => option.summary ?? ""}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        getSummaryDescription={(object) => [object?.summary ?? object.key, object.key]}
        actionButton={["Create new issue", () => onCreateNew()]}
      />
      <Button onClick={() => handleSelectIssue()}>Add</Button>
    </Box>
  );
};
