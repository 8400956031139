import { Box, Stack, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useUsersPermissions } from "../hooks/useUsers";
import { SmallModal } from "./SmallModal";
import { UserPermissionJsonView } from "./UserPermissionJsonView";
import { UserPermissionTableView } from "./UserPermissionTableView";
import { usePolicies } from "../hooks/usePolicies";
import { IAMPolicy } from "../models/types";
import {
  BASE_ADMIN_POLICY,
  BASE_AUDITOR_POLICY,
  BASE_USER_POLICY,
} from "../utilities/BasePermissions";

type ViewUserFinalPermissionsModalProps = {
  open: boolean;
  onClose: () => void;
  userId: string;
  addedRoles?: string[];
  permission?: string;
};

export const ViewUserFinalPermissionsModal = (props: ViewUserFinalPermissionsModalProps) => {
  const { open, onClose, userId, addedRoles, permission } = props;
  const [jsonView, setJsonView] = useState(false);
  const [finalPermission, setFinalPermissions] = useState<IAMPolicy[]>([]);
  const { data: permissions, isLoading: isLoadingPermissions } = useUsersPermissions(userId ?? "");

  const { data: policies, isLoading: isLoadingPolicies } = usePolicies({ role: addedRoles });

  const loading = isLoadingPermissions || isLoadingPolicies;

  useEffect(() => {
    if ((policies?.count ?? 0) > 0) {
      setFinalPermissions(
        policies?.results?.map((policy) => policy.value as unknown as IAMPolicy) ?? []
      );
    } else {
      if (permission === "User") {
        setFinalPermissions(BASE_USER_POLICY);
      } else if (permission === "Admin") {
        setFinalPermissions(BASE_ADMIN_POLICY);
      } else if (permission === "Auditor") {
        setFinalPermissions(BASE_AUDITOR_POLICY);
      }
    }
  }, [policies]);

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      title="User Permissions"
      size="medium"
      rightComponent={
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="h4">Table</Typography>
          <Switch
            size="medium"
            checked={jsonView}
            onChange={(_, checked) => setJsonView(checked)}
            edge="end"
          />
          <Typography variant="h4">JSON</Typography>
        </Box>
      }
    >
      {!loading && (
        <Stack gap="10px">
          {jsonView ? (
            <UserPermissionJsonView permissions={finalPermission} />
          ) : (
            <UserPermissionTableView permissions={finalPermission} />
          )}
        </Stack>
      )}
    </SmallModal>
  );
};
