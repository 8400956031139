import {
  EvidenceRequirementFetchOptions,
  EvidenceTest,
  EvidenceTestHistory,
  EvidenceTestHistoryFetchOptions,
  ObjectSummary,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const evidenceEndpoints = {
  evidenceRequirements: () => `/evidence_tests`,
  evidenceRequirement: (evidenceId: string) => `/evidence_tests/${evidenceId}`,
  evidenceEvaluate: (evidenceId: string) => `/evidence_tests/${evidenceId}/evaluate`,
  evidenceTestHistory: () => `/evidence_test_history`,
  summary: () => "/evidence_tests/summary",
};

export const getEvidenceTest = (params: EvidenceRequirementFetchOptions) => {
  return httpGetAuthenticated<ListResponse<EvidenceTest>>(
    evidenceEndpoints.evidenceRequirements(),
    {
      params,
    }
  );
};

export const getEvidenceRequirement = (evidenceId: string) => {
  return httpGetAuthenticated<EvidenceTest>(evidenceEndpoints.evidenceRequirement(evidenceId), {
    params: {
      fairo_data: true,
    },
  });
};

export const getEvidenceEvaluation = (evidenceId: string) => {
  return httpGetAuthenticated<EvidenceTest>(evidenceEndpoints.evidenceEvaluate(evidenceId), {
    params: {
      fairo_data: true,
    },
  });
};

export const getEvidenceRequirementTestHistory = (params: EvidenceTestHistoryFetchOptions) => {
  return httpGetAuthenticated<ListResponse<EvidenceTestHistory>>(
    evidenceEndpoints.evidenceTestHistory(),
    {
      params,
    }
  );
};

export const addEvidenceRequirement = async (payload: {
  name: string;
  description?: string;
  evidence_test_type: string;
  evidence_logic: string;
  scope_type: string;
  scope: string;
  scope_filter?: string | null;
  assignee?: string | null;
  frequency: string;
  sla: number;
  evidence_instructions?: string[];
}) => {
  const { data } = await httpPostAuthenticated<EvidenceTest>(
    evidenceEndpoints.evidenceRequirements(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.EvidenceRequirement] });
  return data;
};

export const editEvidenceRequirement = async (
  evidenceRequirementId: string,
  payload: {
    name?: string;
    description?: string;
    evidence_test_type?: string;
    evidence_logic?: any;
    scope_type?: string;
    scope?: string;
    scope_filter?: string;
    frequency?: string;
    sla?: number;
    assignee?: string | null;
    evidence_instructions?: string[];
  }
) => {
  const { data } = await httpPatchAuthenticated(
    evidenceEndpoints.evidenceRequirement(evidenceRequirementId),
    payload
  );
  queryClient.invalidateQueries({
    queryKey: [QueryKey.EvidenceRequirement, evidenceRequirementId],
  });
  return data;
};

export const getEvidenceTestSummary = (params: EvidenceRequirementFetchOptions) => {
  return httpGetAuthenticated<ObjectSummary>(evidenceEndpoints.summary(), params);
};

export const deleteEvidenceTest = async (evidenceId: string) => {
  const resp = await httpDeleteAuthenticated(evidenceEndpoints.evidenceRequirement(evidenceId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.EvidenceRequirement, evidenceId] });
  return resp;
};
