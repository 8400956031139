import { Box, Typography, useTheme } from "@mui/material";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { assetListColumnsVisibility } from "../../utilities/columns-visibility/AssetListColumnsVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { GraphLoader } from "../../organisms/skeleton-loaders/GraphLoader";

export const AssetListPageLoader = () => {
  const theme = useTheme();

  return (
    <Box paddingBottom="60px" paddingTop="15px">
      <Box
        sx={{
          height: "100%",
          padding: "15px",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          borderWidth: "1px",
          borderRadius: "6px",
          borderColor: theme.palette.divider,
          borderStyle: "solid",
          backgroundColor: theme.palette.background.paper,
          overflow: "auto",
        }}
      >
        <Box display="flex" gap="15px" width="100%" height="300px">
          <Box width="60%">
            <Box
              display="flex"
              flexDirection="column"
              padding="10px"
              gap="10px"
              width="100%"
              border={`1px solid ${theme.palette.custom.secondaryBorder}`}
              borderRadius="6px"
              height="100%"
            >
              <GraphLoader
                title="Progress"
                variant="circular"
                height="190px"
                width="190px"
                legend={true}
                legendHeight="68px"
                legendWidth="120px"
              />
            </Box>
          </Box>
          <Box width="40%">
            <Box
              display="flex"
              flexDirection="column"
              padding="10px"
              gap="10px"
              width="100%"
              border={`1px solid ${theme.palette.custom.secondaryBorder}`}
              borderRadius="6px"
              height="100%"
            >
              <Typography variant="h3">Get Started</Typography>
              {[...Array(5)].map((_, idx) => (
                <RectangularLoader width="100%" height="34px" />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            overflow: "visible",
          }}
        >
          <Box height={"100%"}>
            <Box
              paddingBottom={"0px"}
              borderRadius="6px"
              border="1px solid"
              minHeight="100%"
              maxHeight="100%"
              overflow="auto"
              borderColor={theme.palette.custom.secondaryBorder}
              bgcolor={theme.palette.custom.secondaryBackground}
            >
              <APITableLoader
                columnsVisibility={assetListColumnsVisibility}
                tableSize="small"
                secondComponent={true}
                tableSearch={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
