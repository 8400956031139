import { useQuery } from "react-query";
import { ImpactAssessmentFetchOptions } from "../models/types";
import { getImpactAssessment, getImpactAssessments } from "../services/ImpactAssessmentService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useImpactAssessments = (params: ImpactAssessmentFetchOptions) => {
  return useQuery([QueryKey.ImpactAssessment, params], async () => {
    const { data } = await getImpactAssessments(params);
    return data;
  });
};

export const useImpactAssessment = (impactAssessmentId: string) => {
  return useQuery([QueryKey.ImpactAssessment, impactAssessmentId], async () => {
    if (impactAssessmentId === "") return undefined;
    const { data } = await getImpactAssessment(impactAssessmentId);
    return data;
  });
};

export const fetchImpactAssessments = (params: any) => {
  return queryClient.fetchQuery([QueryKey.ImpactAssessment, params], async () => {
    const { data } = await getImpactAssessments(params);
    return data;
  });
};

export const fetchImpactAssessment = (impactAssessmentId: string) => {
  return queryClient.fetchQuery([QueryKey.ImpactAssessment, impactAssessmentId], async () => {
    const { data } = await getImpactAssessment(impactAssessmentId);
    return data;
  });
};

export const searchImpactAssessments = async (searchText: string) => {
  const assessments = await fetchImpactAssessments({ search: searchText });
  return assessments.results;
};
