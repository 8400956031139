import { Box, Typography } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const CustomFieldLoader = () => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap="5px" alignItems="center">
          <Typography variant="h3">Custom Fields</Typography>
          <RectangularLoader width="30px" height="30px" />
        </Box>
        <RectangularLoader width="100px" height="35px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="3px">
        <Box display="flex" padding="3px 0px" justifyContent="space-between" alignItems="center">
          <RectangularLoader width="140px" height="30px" />
          <Box display="flex" gap="5px" alignItems="center">
            <RectangularLoader width="30px" height="30px" />
            <RectangularLoader width="30px" height="30px" />
          </Box>
        </Box>
        <RectangularLoader width="86px" height="24px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="3px">
        <Box display="flex" padding="3px 0px" justifyContent="space-between" alignItems="center">
          <RectangularLoader width="140px" height="30px" />
          <Box display="flex" gap="5px" alignItems="center">
            <RectangularLoader width="30px" height="30px" />
            <RectangularLoader width="30px" height="30px" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="6px">
          <RectangularLoader width="330px" height="14px" />
          <RectangularLoader width="330px" height="14px" />
          <RectangularLoader width="330px" height="14px" />
          <RectangularLoader width="330px" height="14px" />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <RectangularLoader width="159px" height="35px" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="3px">
        <Box display="flex" padding="3px 0px" justifyContent="space-between" alignItems="center">
          <RectangularLoader width="140px" height="30px" />
          <Box display="flex" gap="5px" alignItems="center">
            <RectangularLoader width="30px" height="30px" />
            <RectangularLoader width="30px" height="30px" />
          </Box>
        </Box>
        <RectangularLoader width="86px" height="24px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="3px">
        <Box display="flex" padding="3px 0px" justifyContent="space-between" alignItems="center">
          <RectangularLoader width="140px" height="30px" />
          <Box display="flex" gap="5px" alignItems="center">
            <RectangularLoader width="30px" height="30px" />
            <RectangularLoader width="30px" height="30px" />
          </Box>
        </Box>
        <RectangularLoader width="86px" height="24px" />
      </Box>
    </>
  );
};
