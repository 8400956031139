import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { UserCustomField } from "../../../components/CustomFieldForm";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useJiraIntegration } from "../../../hooks/useJiraIntegration";
import { searchUsers } from "../../../hooks/useUsers";
import {
  ExecutionTask,
  ExecutionTaskStatus,
  ExternalTask,
  JiraIssue,
  RegistryFieldType,
  TaskType,
} from "../../../models/types";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { addExecutionTask, editExecutionTask } from "../../../services/ExecutionPlanService";
import { useUsers } from "../../../services/UserSettingsService";
import { getTaskTypesByObjectType } from "../../../utilities/TasksHelper";
import { CustomInputValidation } from "../../CustomInputValidation";
import { CreateJiraTaskModal } from "./CreateJiraTask";

type BaseCreateTaskProps = {
  relatedObjectId: string | null;
  relatedObjectType: RegistryFieldType;
  task: ExecutionTask | null;
  onCreateMetadata?: any;
  onClose: () => void;
};

export const BaseCreateTask = ({
  relatedObjectId,
  relatedObjectType,
  task,
  onCreateMetadata,
  onClose,
}: BaseCreateTaskProps) => {
  const [name, setName] = useState<string>("");
  const [taskType, setTaskType] = useState<TaskType | null>(null);
  const [assignee, setAssignee] = useState<string | null>(null);
  const [comments, setComments] = useState<string>("");
  const [selectedObject, setSelectedObject] = useState<string | null>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [openJiraModal, setOpenJiraModal] = useState<boolean>(false);
  const [externalTaskId, setExternalTaskId] = useState<ExternalTask | null>(null);
  const [status, setStatus] = useState<ExecutionTaskStatus>("PENDING");
  const [jiraIssue, setJiraIssue] = useState<JiraIssue | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [taskTypeError, setTaskTypeError] = useState<string | null>(null);
  const theme = useTheme();
  const inheritColor = theme.palette.custom.secondaryTypography;
  const [loading, setLoading] = useState<boolean>(false);
  const { data: jiraInfo } = useJiraIntegration();
  const editStatusOptions: ExecutionTaskStatus[] = ["PENDING", "COMPLETED", "CANCELED"];
  useEffect(() => {
    if (task) {
      setName(task.name);
      setTaskType(task.task_type);
      setAssignee(task.assignee);
      setComments(task.comments ?? "");
      setExternalTaskId(task.task_external_ids?.[0] ?? null);
      setJiraIssue(null);
      setStatus(task.status);
    }
  }, [task]);

  const isFormValid = () => {
    const isValidName = !!name || (name && name !== "");
    const isValidTaskType = !!taskType;

    if (!isValidName) {
      setNameError("Please provide a task name");
    } else {
      setNameError(null);
    }
    if (!isValidTaskType) {
      setTaskTypeError("Please select the task type");
    } else {
      setTaskTypeError(null);
    }
    return isValidName && isValidTaskType;
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      if (!isFormValid()) {
        return;
      }
      if (isUpdate) {
        await editExecutionTask(task.id, {
          task_type: taskType as TaskType,
          assignee: assignee,
          name: name,
          issue: jiraIssue
            ? {
                assignee: jiraIssue?.assignee?.id ?? null,
                project: jiraIssue.project.id,
                issue_type: jiraIssue.issue_type.id,
                summary: jiraIssue.summary,
                description: jiraIssue.description,
              }
            : null,
          task_external_ids: externalTaskId
            ? [{ id: externalTaskId.id, key: externalTaskId.key }]
            : [],
          integration_type: externalTaskId ? "jira" : null,
          status: status,
          comments: comments,
        });
      } else {
        await addExecutionTask({
          name: name,
          task_type: taskType,
          parent_object_id: relatedObjectId ?? selectedObject,
          parent_type: relatedObjectType,
          assignee: assignee,
          issue: jiraIssue
            ? {
                assignee: jiraIssue?.assignee?.id,
                project: jiraIssue.project.id,
                issue_type: jiraIssue.issue_type.id,
                summary: jiraIssue.summary,
                description: jiraIssue.description,
              }
            : null,
          ...(externalTaskId && {
            task_external_ids: [{ id: externalTaskId.id, key: externalTaskId.key }],
            integration_type: "jira",
          }),
          status: "PENDING",
          metadata: {
            ...onCreateMetadata,
            ...(selectedQuestion && { question_ref: selectedQuestion }),
          },
          comments: comments,
        });
      }
      onClose();
      openSnackbar(`Task successfully ${isUpdate ? "updated" : "created"}`, "success");
    } catch (error) {
      openSnackbar(`Failed to ${isUpdate ? "update" : "create"} task`, "error");
    } finally {
      setLoading(false);
    }
  };
  const isUpdate = !!task;

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h3" color={inheritColor}>
        Task Name
      </Typography>
      <CustomInputValidation
        value={name}
        onChange={setName}
        required={true}
        valueError={!!nameError}
        errorMessage={nameError ?? ""}
        field_type="String"
      />
      {!relatedObjectId && (
        <>
          <Typography variant="h3" color={inheritColor}>
            Related {relatedObjectType}
          </Typography>
          <CustomInputValidation
            value={selectedObject}
            onChange={setSelectedObject}
            errorMessage=""
            field_type={relatedObjectType}
            valueError={false}
          />
        </>
      )}
      {relatedObjectType === "Questionnaire" && (
        <>
          <Typography variant="h3" color={inheritColor}>
            Question
          </Typography>
          <CustomInputValidation
            questionnaireId={selectedObject ?? ""}
            value={selectedQuestion}
            onChange={setSelectedQuestion}
            errorMessage=""
            field_type="Question"
            valueError={false}
          />
        </>
      )}
      <Typography variant="h3" color={inheritColor}>
        Task Type
      </Typography>
      <SearchableDropdown<TaskType>
        value={taskType}
        onChange={setTaskType}
        getOptions={(search: string) =>
          getTaskTypesByObjectType(relatedObjectType).filter((option) =>
            option.toLowerCase().includes(search.toLowerCase())
          )
        }
        error={!!taskTypeError}
        helperText={taskTypeError ?? ""}
        getOptionLabel={(option) => option ?? ""}
        isOptionEqualToValue={(a, b) => a === b}
        getSummaryDescription={(object: string) => [object, ""]}
      />
      <Typography variant="h3" color={inheritColor}>
        Assignee
      </Typography>
      <UserCustomField
        value={assignee}
        onChange={setAssignee}
        searchUsers={searchUsers}
        useUser={useUsers}
        error={false}
        errorMessage=""
      />
      {isUpdate && (
        <>
          <Typography variant="h3" color={inheritColor}>
            Status
          </Typography>
          <SearchableDropdown<ExecutionTaskStatus>
            value={status}
            onChange={(option) => setStatus(option ?? "PENDING")}
            getOptions={(search: string) =>
              editStatusOptions.filter((option) =>
                option.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => ""}
            isOptionEqualToValue={(a, b) => a === b}
            startAdornment={
              !!status ? (
                <Box display="flex" alignItems="center">
                  <StatusBadge status={status as any} />
                </Box>
              ) : null
            }
            renderOption={(props, option) => {
              return <StatusBadge status={option as any} />;
            }}
          />
        </>
      )}
      {!!jiraInfo && (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" color={inheritColor}>
            External Issue
          </Typography>
          {!jiraIssue && !externalTaskId && (
            <TableTextWithEllipsis
              onClick={() => setOpenJiraModal(true)}
              value="Link Jira Issue"
              fullWidth={false}
              hideTooltip
              color={theme.palette.custom.hyperlink}
            />
          )}
          {(jiraIssue || externalTaskId) && (
            <TableTextWithEllipsis
              onClick={() => {
                setExternalTaskId(null);
                setJiraIssue(null);
              }}
              value="Unlink"
              fullWidth={false}
              hideTooltip
              color={theme.palette.custom.hyperlink}
            />
          )}
        </Box>
      )}
      {jiraIssue && (
        <DefaultBadge
          icon="jira"
          onClick={() => setOpenJiraModal(true)}
          value={`[${jiraIssue?.issue_type.name}] ${jiraIssue?.summary}`}
        />
      )}
      {externalTaskId && (
        <DefaultBadge
          icon="jira"
          onClick={() => setOpenJiraModal(true)}
          value={
            externalTaskId?.issue_type
              ? `[${externalTaskId?.issue_type}] ${externalTaskId?.summary}`
              : externalTaskId?.key
          }
        />
      )}
      <Typography variant="h3" color={inheritColor}>
        Comments
      </Typography>
      <CKEditorField value={comments} onChange={setComments} />
      <LoadingButton loading={loading} variant="contained" onClick={async () => handleCreate()}>
        {isUpdate ? "Save" : "Create"}
      </LoadingButton>
      <CreateJiraTaskModal
        jiraIssue={jiraIssue}
        setJiraIssue={setJiraIssue}
        externalTaskId={externalTaskId}
        setExternalTaskId={setExternalTaskId}
        open={openJiraModal}
        onClose={() => setOpenJiraModal(false)}
      />
    </Box>
  );
};
