import { Stack, useTheme } from "@mui/material";
import { useUsers } from "../hooks/useUsers";
import DataComponentBuilder from "./DataComponentBuilder";
import { OrgChartCard } from "./OrgChartCard";
import { OrgChartFlow } from "./graph-flow/OrgChartFlow";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import { InviteUserModal } from "./InviteUserModal";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { inviteUser, updateOrgChartSettings } from "../services/UserSettingsService";
import { addDepartment, deleteDepartment } from "../services/DepartmentService";
import { CreateDepartmentModal } from "./CreateDepartmentModal";
import { ViewUserFinalPermissionsModal } from "./ViewUserFinalPermissionsModal";
import { ConfirmationModal } from "./ConfirmationModal";

type OrgChartSummaryProps = {
  filteredUserIds?: string[];
};

export const OrgChartSummary = (props: OrgChartSummaryProps) => {
  const { filteredUserIds } = props;
  const theme = useTheme();
  const { organization } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<string>("");
  const [openCreateDepartment, setOpenCreateDepartment] = useState<boolean>(false);
  const [openViewFinalPermissions, setOpenViewFinalPermissions] = useState<boolean>(false);
  const [addedRoles, setAddedRoles] = useState<string[]>([]);
  const [permission, setPermission] = useState<string>("");
  const [departmentId, setDepartmentId] = useState<string | null>(null);
  const [openEditDepartment, setOpenEditDepartment] = useState<boolean>(false);
  const [openDeleteDepartment, setOpenDeleteDepartment] = useState<boolean>(false);

  const {
    data: users,
    isLoading,
    isFetching,
    error,
  } = useUsers({ id: filteredUserIds, page_size: 1000, api_key: false });

  const nodes = users?.results.map((user) => ({
    id: user.id,
    name: user.name,
    job_title: user.job_title,
    department: user.department_name?.name ?? "",
    department_color: user.department_color ?? "#5BA3C0",
    manager: user.manager ?? "",
    manager_name: user.manager_name?.name ?? "",
    is_invited: user.is_invited,
    data: { label: user.name },
    position: { x: 0, y: 0 },
  }));
  const edges = users?.results
    .filter((user) => user.manager)
    .map((user, index) => ({
      id: `${index}`,
      source: user.manager!,
      target: user.id,
      type: "smoothstep",
      style: { stroke: theme.palette.custom.primaryBorder, strokeWidth: 1.5 },
    }));

  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : "calc(80vh - 56px)";

  return (
    <DataComponentBuilder
      isLoading={isLoading || isFetching}
      error={error}
      data={nodes}
      renderData={(nodeItems) => {
        return (
          <Stack
            spacing={2}
            style={{
              height: pageSize,
            }}
          >
            <InviteUserModal
              open={isOpen}
              onClose={() => {
                setIsOpen(false);
                setEditUserId("");
              }}
              openCreateDepartment={() => setOpenCreateDepartment(true)}
              userId={editUserId}
              setOpenViewFinalPermissions={() => setOpenViewFinalPermissions(true)}
              setAddedRoles={setAddedRoles}
              setDepartmentId={setDepartmentId}
              setOpenEditDepartment={() => setOpenEditDepartment(true)}
              setOpenDeleteDepartment={() => setOpenDeleteDepartment(true)}
            />
            <ConfirmationModal
              open={openDeleteDepartment}
              title="Delete Department"
              description="Are you sure you want to delete this department? Once it's deleted, it's gone for good"
              descriptionVariant="body2"
              onClose={() => {
                setOpenDeleteDepartment(false);
                setDepartmentId(null);
              }}
              onAccept={async () => {
                await deleteDepartment(departmentId!);
              }}
              acceptText="Delete"
            />
            <CreateDepartmentModal
              open={openCreateDepartment || openEditDepartment}
              onClose={() => {
                setOpenCreateDepartment(false);
                setOpenEditDepartment(false);
                setDepartmentId(null);
              }}
              departmentId={departmentId ?? ""}
            />
            <ViewUserFinalPermissionsModal
              open={openViewFinalPermissions}
              onClose={() => setOpenViewFinalPermissions(false)}
              userId={editUserId}
              addedRoles={addedRoles}
              permission={permission}
            />
            <div style={{ flexGrow: 1, width: "100%" }}>
              <OrgChartFlow
                nodes={nodeItems}
                edges={edges ?? []}
                renderNode={(user) => {
                  return <OrgChartCard user={user} />;
                }}
                onNodeClick={(user) => {
                  setIsOpen(true);
                  setAddedRoles(user?.roles?.map((role: any) => role.id) ?? []);
                  setPermission(user.permissions);
                  setEditUserId(user.id);
                }}
              />
            </div>
          </Stack>
        );
      }}
    />
  );
};
