import { Box, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";

const CollapsableComponent = (props: {
  children: React.ReactNode;
  label: string;
  labelColor: string;
  onChange: () => void;
  visible: boolean;
  showIcon?: boolean;
}) => {
  const { children, onChange, label, labelColor, visible, showIcon = true } = props;
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      gap="10px"
      padding="9.5px 10px 9.5px 10px"
    >
      {children}
      <Box display="flex" gap="8px" alignItems="center">
        <Typography variant="body2" color={labelColor}>
          {label}
        </Typography>
        {showIcon && (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => onChange()}
            alignItems="center"
            display="flex"
          >
            <NavbarIcon variant={visible ? "chevron-up-sm" : "chevron-down-sm"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export { CollapsableComponent };
