import { LoadingButton } from "@mui/lab";
import { alpha, Box, useTheme } from "@mui/material";
import { deleteCookie, getCookie } from "../../context/CookiesContext";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { AdminViewSelector } from "../../molecules/header/AdminViewSelector";
import { refreshAccessToken } from "../../services/AuthService";
import queryClient from "../../state/QueryStore";

type FairoAdminBannerProps = {
  setOpenSeeAllOrganizations: () => void;
};

export const FairoAdminBanner = (props: FairoAdminBannerProps) => {
  const { setOpenSeeAllOrganizations } = props;
  const theme = useTheme();
  const { currentAdminView } = useOrganizationContext();
  const orgId = getCookie("organization_id");

  const switchToFairo = async () => {
    deleteCookie("organization_id");
    const tokens = await refreshAccessToken();
    queryClient.clear();
    if (tokens) {
      window.location.href = "/organization";
    }
  };

  return (
    <Box
      padding="8px"
      display="flex"
      borderRadius="3px"
      width="100%"
      bgcolor={alpha(theme.palette.custom.primaryStatusBackgroundColor, 0.3)}
      alignItems="center"
      justifyContent="space-between"
    >
      {/* {currentAdminView === "default" &&
        <TableTextWithEllipsis variant="h5" color={theme.palette.custom.primaryTypography} value="Fairo Default View" />}
      {currentAdminView === "admin" &&
        <TableTextWithEllipsis variant="h5" color={theme.palette.custom.primaryTypography} value="Fairo Admin View" />} */}
      <AdminViewSelector />
      <Box display="flex" flexGrow={1} gap="10px" alignItems="center">
        {orgId ? (
          <LoadingButton
            sx={{
              minWidth: "160px",
            }}
            variant="contained"
            onClick={switchToFairo}
            color="error"
          >
            Switch to Fairo
          </LoadingButton>
        ) : (
          <LoadingButton
            sx={{
              minWidth: "160px",
            }}
            variant="contained"
            onClick={setOpenSeeAllOrganizations}
          >
            See Organizations
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
