import { Box, Typography, useTheme } from "@mui/material";
import { getThreeLevelRiskScoreColor, RiskScoreBadge } from "../../atoms/StatusBadge";
import { SearchableDropdown } from "../../components/SearchableDropdown";

type DataOriginFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};

/**
 *
 * @param DataOriginFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */
export const DataOriginField = (props: DataOriginFieldProps) => {
  const { value, onChange, required, valueError, errorMessage } = props;
  const theme = useTheme();
  type RiskOptionsType = { name: string; score: 1 | 2 | 3 | null };

  const handleValue = (valueKey: string, newValue: RiskOptionsType | null) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, [valueKey]: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  const options: RiskOptionsType[] = [
    { name: "Not Applicable", score: null },
    { name: "Redacted", score: 1 },
    { name: "Redacted", score: 2 },
    { name: "Redacted", score: 3 },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Internal
      </Typography>
      <SearchableDropdown<RiskOptionsType>
        value={getValue("internal")}
        required={required}
        onChange={(option) => handleValue("internal", option)}
        getOptions={(search: string) =>
          options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
        }
        label="Select or search"
        getOptionLabel={(option) => option?.name ?? ""}
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("internal") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("internal").score, theme)}
                size="small"
                score={getValue("internal").score}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && option.score && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option.score, theme)}
                  size="small"
                  score={option.score}
                />
              )}
              <Typography variant="body2">{option?.name}</Typography>
            </Box>
          );
        }}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        External
      </Typography>
      <SearchableDropdown<RiskOptionsType>
        value={getValue("external")}
        required={required}
        label="Select or search"
        onChange={(option) => handleValue("external", option)}
        getOptions={(search: string) =>
          options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
        }
        getOptionLabel={(option) => option?.name ?? ""}
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("external") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("external").score, theme)}
                size="small"
                score={getValue("external").score}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && option.score && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option.score, theme)}
                  size="small"
                  score={option.score}
                />
              )}
              <Typography variant="body2">{option?.name}</Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
