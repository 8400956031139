import { useLocation } from "react-router-dom";
import { HomeDashboardSelector } from "../../molecules/header/HomeDashboardSelector";
import { UsersPageSelector } from "../../molecules/header/UsersPageSelector";

export const HeaderConditionalComponent = () => {
  const { pathname } = useLocation();
  return (
    <>
      {(pathname === "/organization" || pathname === "/organization/overview") && (
        <HomeDashboardSelector />
      )}
      {pathname === "/organization/users" && <UsersPageSelector />}
    </>
  );
};
