import { Box, Typography, useTheme } from "@mui/material";
import { getThreeLevelRiskScoreColor, RiskScoreBadge } from "../../atoms/StatusBadge";

type DataOriginFieldProps = {
  value: {
    internal: {
      name: string;
      score: 1 | 2 | 3 | null;
    };
    external: {
      name: string;
      score: 1 | 2 | 3 | null;
    };
  };
};

export const DataOriginReadOnly = ({ value }: DataOriginFieldProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      {value.internal.score && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Internal:
          </Typography>
          <RiskScoreBadge
            bgcolor={getThreeLevelRiskScoreColor(value.internal.score, theme)}
            score={value.internal.score}
            size="small"
          />
          <Typography variant="body2">{value.internal.name}</Typography>
        </Box>
      )}
      {value.external.score && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            External:
          </Typography>
          <RiskScoreBadge
            bgcolor={getThreeLevelRiskScoreColor(value.external.score, theme)}
            score={value.external.score}
            size="small"
          />
          <Typography variant="body2">{value.external.name}</Typography>
        </Box>
      )}
    </Box>
  );
};
