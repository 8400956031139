import { Box, TextField, Typography, useTheme } from "@mui/material";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { searchUsers } from "../../../hooks/useUsers";
import { User } from "../../../models/types";

type CreateUseCaseFormProps = {
  name: string;
  setName: (name: string) => void;
  nameError: boolean;
  useCaseOwner: User | null;
  setUseCaseOwner: (owner: User | null) => void;
  description: string;
  setDescription: (description: string) => void;
};

export const CreateUseCaseForm = (props: CreateUseCaseFormProps) => {
  const { name, setName, nameError, useCaseOwner, setUseCaseOwner, description, setDescription } =
    props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Use Case General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Add name, description and select the use case owner.
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Use Case Name
        </Typography>
        <Typography variant="body2">
          Enter a concise and descriptive name for your use case
        </Typography>
        <TextField
          error={nameError}
          helperText={nameError && "Please enter name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          label="Name"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Use Case Owner
        </Typography>
        <Typography variant="body2">Select the user responsible for this use case.</Typography>
        <SearchableDropdown<User>
          label="Select or Search"
          value={useCaseOwner}
          onChange={setUseCaseOwner}
          getOptionLabel={(user) => user.name}
          getOptions={searchUsers}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          getSummaryDescription={(user) => [user.name, user.username]}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Description
        </Typography>
        <Typography variant="body2">
          Provide a detailed explanation of your use case. Include key objectives, expected
          outcomes, and any relevant details that will help in understanding the purpose and scope
          of this use case.
        </Typography>
        <CKEditorField value={description} onChange={setDescription} />
      </Box>
    </Box>
  );
};
