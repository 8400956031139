import { useQuery } from "react-query";
import { DepartmentFetchOptions } from "../models/types";
import { getDepartment, getDepartments } from "../services/DepartmentService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useDepartments = (params: DepartmentFetchOptions) => {
  return useQuery([QueryKey.Department, params], async () => {
    const { data } = await getDepartments(params);
    return data;
  });
};

export const useDepartment = (departmentId: string) => {
  return useQuery([QueryKey.Department, departmentId], async () => {
    if (departmentId === "") {
      return undefined;
    }
    const { data } = await getDepartment(departmentId);
    return data;
  });
};

export const fetchDepartments = (params: DepartmentFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Department, params], async () => {
    const { data } = await getDepartments(params);
    return data;
  });
};

export const searchDepartments = async (searchText: string) => {
  const { results } = await fetchDepartments({ search: searchText });
  return results;
};
