import { AxiosError, AxiosResponse } from "axios";

interface PaginatedResult<T> {
  totalCount: number;
  hasNext: boolean;
  results: T[];
}

interface CreateOrUpdateResult<T> {
  success: boolean;
  data: T | undefined;
  errors:
    | {
        general: any;
        fields: any;
      }
    | undefined;
}

export type ListResponse<T> = {
  count?: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

const parsePaginatedResponseData = <T>(
  respData: ListResponse<T>,
  converter: (raw: any) => T
): PaginatedResult<T> => {
  const { count, next, results } = respData;

  return {
    hasNext: next !== null,
    totalCount: count ?? 0,
    results: results.map((raw) => converter(raw)),
  };
};

const createOrUpdateParser = <T>({
  call,
  dataConverter,
}: {
  call: () => Promise<AxiosResponse<any, any>>;
  dataConverter: (data: any) => T;
}): Promise<CreateOrUpdateResult<T>> => {
  return call()
    .then((response) => {
      return {
        success: true,
        data: dataConverter(response.data),
        errors: undefined,
      };
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const respData = error.response.data as any;

        const nonFieldErrors = respData.non_field_errors;
        const otherError =
          respData.detail && typeof respData.detail === "string" ? respData.detail : undefined;

        const nonFieldError = nonFieldErrors ?? otherError;
        if (nonFieldError) {
          return {
            success: false,
            errors: {
              general: nonFieldError,
              fields: undefined,
            },
            data: undefined,
          };
        }

        return {
          success: false,
          errors: {
            general: undefined,
            fields: error.response.data,
          },
          data: undefined,
        };
      }

      return {
        success: false,
        errors: {
          general: "Error occurred",
          fields: undefined,
        },
        data: undefined,
      };
    });
};

export const getAPIErrorMessage = (
  e: AxiosError<{ detail: string }>,
  defaultMessage: string = "Something went wrong"
): string => {
  const errorMessage = e?.response?.data?.detail;
  return errorMessage ?? defaultMessage;
};

export type { PaginatedResult, CreateOrUpdateResult };
export { parsePaginatedResponseData, createOrUpdateParser };
