import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Box, Typography, useTheme } from "@mui/material";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useState } from "react";
import "./styles.css";

const CKEditorField = (props) => {
  const [initialValue, setInitialValue] = useState(null);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const height = props.large ? "550px" : props.small ? "98.3px" : "140px";
  return (
    <Box
      sx={{
        "--ck-color-base-background": isDarkMode
          ? theme.palette.custom.planBackground
          : theme.palette.custom.textEditorBackground,
        "--ck-color-toolbar-background": isDarkMode
          ? theme.palette.custom.planBackground
          : theme.palette.custom.textEditorBackground,
        "--ck-color-text": theme.palette.custom.secondaryTypography,
        "--ck-color-base-border": theme.palette.custom.gray,
        "--ck-color-toolbar-border": theme.palette.custom.gray,
        ".ck-content": {
          color: theme.palette.custom.secondaryTypography,
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
          fontSize: "14px",
          ".text-tiny": {
            fontSize: "12px",
          },
          ".text-small": {
            fontSize: "14px",
          },
          ".text-big": {
            fontSize: "18px",
          },
          ".text-huge": {
            fontSize: "22px",
          },
        },
        ".ck-editor__main": {
          "max-height": height,
        },
        ".ck-editor__editable": {
          "min-height": height,
        },
      }}
    >
      <Typography variant="subtitle1" fontWeight={500}>
        {props.label}
      </Typography>
      <CKEditor
        editor={Editor}
        data={props.value}
        disabled={props.readOnly}
        onChange={(_, editor) => {
          initialValue !== editor.getData()
            ? props.setShowButtonWithCKEditor?.(true)
            : props.setShowButtonWithCKEditor?.(false);
          return props.onChange(editor.getData());
        }}
        onReady={(editor) => {
          setInitialValue(editor.getData());
        }}
      />
      {props.errorMessage && (
        <Typography fontSize="12px" margin="4px 14px 0" variant="body3" color="error">
          {props.errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default CKEditorField;
