import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SubMenuItemDisplay } from "./SubMenuItemDisplay";

export const SubMenuDisplay = ({
  title,
  subMenuItems,
  onClose,
  top,
  left,
  visible,
}: {
  top: string | number;
  left: string | number;
  title: string;
  subMenuItems: { label: string; route: string }[];
  onClose: () => void;
  visible: boolean;
}) => {
  const [opacity, setOpacity] = useState<number>(0);
  useEffect(() => {
    setOpacity(visible ? 1 : 0);
  }, [visible]);
  const theme = useTheme();
  return visible ? (
    <Box
      sx={{
        position: "absolute",
        top: top,
        left: left,
        padding: "10px",
        opacity: opacity,
        transition: "opacity 0.4s ease",
      }}
      bgcolor={theme.palette.custom.secondaryBackground}
      borderRadius="6px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      boxShadow="0px 4px 6px 2px #00000014"
    >
      <Box gap="5px" display="flex" flexDirection="column">
        <Typography padding="5px" variant="h5" color={theme.palette.custom.primaryTypography}>
          {title}
        </Typography>
        {subMenuItems?.map((item, index) => (
          <SubMenuItemDisplay
            onClose={() => onClose()}
            key={index}
            label={item.label}
            route={item.route}
          />
        ))}
      </Box>
    </Box>
  ) : null;
};
