import { Box, Typography, useTheme } from "@mui/material";
import { getThreeLevelRiskScoreColor, RiskScoreBadge } from "../../atoms/StatusBadge";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { QuestionChoice } from "../../models/types";

type RiskLevelFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  choices: QuestionChoice[];
};

/**
 *
 * @param RiskLevelFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */
export const RiskLevelField = (props: RiskLevelFieldProps) => {
  const { value, onChange, required, valueError, errorMessage, choices } = props;
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <SearchableDropdown<string>
        value={value}
        required={required}
        label="Select or search"
        onChange={(option) => {
          onChange(option ?? null);
        }}
        getOptions={(search: string) =>
          choices
            .filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.value)
        }
        getOptionLabel={(option) => choices.find((opt) => opt.value === option)?.label ?? ""}
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          value ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(Number(value), theme)}
                size="small"
                score={Number(value)}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(Number(option), theme)}
                size="small"
                score={Number(option)}
              />
              <Typography variant="body2">
                {choices.find((opt) => opt.value === option)?.label}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
