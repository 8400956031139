import {
  PotentialHarmCategory,
  PotentialHarmDisproportionalityOption,
  PotentialHarmImpactOption,
  PotentialHarmScopeOption,
  PotentialHarmSeverityOption,
} from "../models/types";

export const getPotentialHarmScore = (
  severity: number,
  scope: number,
  disproportionality: number,
  impact: number
): number => {
  return (severity + scope + disproportionality) * impact;
};

export const PotentialHarmCategoryOptions: PotentialHarmCategory[] = [
  "Health / Physical Injury",
  "Emotional / Psycological",
  "Loss of Opportunity",
  "Economic Impact",
  "Loss of Liberty",
  "Loss of Privacy",
];

export const PotentialHarmSeverityOptions: PotentialHarmSeverityOption[] = [
  { score: 1, name: "Redacted" },
  { score: 2, name: "Redacted" },
  { score: 3, name: "Redacted" },
  { score: 4, name: "Redacted" },
  { score: 5, name: "Redacted" },
  { score: 6, name: "Redacted" },
  { score: 7, name: "Redacted" },
  { score: 8, name: "Redacted" },
  { score: 9, name: "Redacted" },
  { score: 10, name: "Redacted" },
];

export const PotentialHarmScopeOptions: PotentialHarmScopeOption[] = [
  { score: 1, name: "Redacted" },
  { score: 2, name: "Redacted" },
  { score: 3, name: "Redacted" },
  { score: 4, name: "Redacted" },
  { score: 5, name: "Redacted" },
  { score: 6, name: "Redacted" },
  { score: 7, name: "Redacted" },
  { score: 8, name: "Redacted" },
  { score: 9, name: "Redacted" },
  { score: 10, name: "Redacted" },
];

export const PotentialHarmDisproportionalityOptions: PotentialHarmDisproportionalityOption[] = [
  { score: 1, name: "Redacted" },
  { score: 2, name: "Redacted" },
  { score: 3, name: "Redacted" },
  { score: 4, name: "Redacted" },
  { score: 5, name: "Redacted" },
  { score: 6, name: "Redacted" },
  { score: 7, name: "Redacted" },
  { score: 8, name: "Redacted" },
  { score: 9, name: "Redacted" },
  { score: 10, name: "Redacted" },
];

export const PotentialHarmImpactOptions: PotentialHarmImpactOption[] = [
  { score: 1, name: "Redacted" },
  { score: 2, name: "Redacted" },
  { score: 3, name: "Redacted" },
  { score: 4, name: "Redacted" },
  { score: 5, name: "Redacted" },
  { score: 6, name: "Redacted" },
  { score: 7, name: "Redacted" },
  { score: 8, name: "Redacted" },
  { score: 9, name: "Redacted" },
  { score: 10, name: "Redacted" },
];
