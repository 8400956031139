import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import UserTag from "../../../components/UserTag";
import { BaseFilterOptions, BaseFilters, ExecutionTask, FilterOption } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { getTaskTypeDescription } from "../../../utilities/UIHelper";

export const tasksFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const TasksColumnsVisibility = ({
  onEdit,
  showCompleted,
}: {
  onEdit: (taskId: string) => void;
  showCompleted: boolean;
}): IColumnVisibility<ExecutionTask>[] => [
  {
    field: "parent_object",
    headerName: "Task",
    visible: true,
    columnMinWidth: 400,
    renderer: (task) => <TableTextWithEllipsis value={task.name} />,
  },
  {
    field: "parent_type",
    headerName: "Type",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (task) => <DefaultBadge value={getTaskTypeDescription(task)} />,
  },
  {
    field: "assignee",
    headerName: "Assignee",
    visible: true,
    columnMinWidth: 180,
    columnMaxWidth: 180,
    renderer: (task) => (task.assignee ? <UserTag name={task.assignee_name} /> : ""),
  },
  // ...(showCompleted ? [{
  //   field: "completed_date",
  //   headerName: "Completed Date",
  //   visible: true,
  //   renderer: (task) => defaultDateDisplay(task.completed_date),
  // }] as IColumnVisibility<ExecutionTask>[] : []),
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 150,
    renderer: (task) => <StatusBadge status={task.status.toUpperCase() as any} />,
  },
];
