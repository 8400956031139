import { DefaultBadge, StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { BaseFilterOptions, BaseFilters, EvidenceTest, FilterOption } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { DefaultBadgeList } from "../../molecules/DefaultBadgeList";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { getFrameworkIcon } from "../../utilities/FrameworkIconHelper";

export const evidenceTestListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

// Required fields: Owner, Teams, Name, Compliance Progress, Needs Attention, Overall risk, Potential Value, Lifecycle stage. (Use New table)

export const evidenceTestListColumnsVisibility = ({
  showType = true,
  hideOwnerName = false,
  showFrameworks = true,
}: {
  showType?: boolean;
  hideOwnerName?: boolean;
  showFrameworks?: boolean;
}): IColumnVisibility<EvidenceTest>[] => [
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 280,
    renderer: (evidence) => <TableTextWithEllipsis value={evidence.name} />,
  },
  ...(showType
    ? ([
        {
          field: "evidence_test_type",
          headerName: "Type",
          visible: true,
          columnMinWidth: 100,
          columnMaxWidth: 100,
          renderer: (evidence) => <DefaultBadge value={evidence.evidence_test_type} />,
        },
      ] as IColumnVisibility<EvidenceTest>[])
    : []),
  ...(showFrameworks
    ? ([
        {
          field: "frameworks",
          headerName: "Frameworks",
          visible: true,
          columnMinWidth: 170,
          columnMaxWidth: 170,
          renderer: (evidence) => (
            <DefaultBadgeList
              renderIcon={(framework) => getFrameworkIcon(framework, "small")}
              badges={evidence.frameworks.map((framework) => framework.name)}
            />
          ),
        },
      ] as IColumnVisibility<EvidenceTest>[])
    : []),
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 170,
    columnMaxWidth: 170,
    renderer: (evidence) => <StatusBadge status={evidence.status} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMinWidth: hideOwnerName ? 80 : 200,
    columnMaxWidth: hideOwnerName ? 80 : 200,
    renderer: (evidence) =>
      evidence.assignee ? (
        <UserTag
          hideName={hideOwnerName}
          showNameInitials={hideOwnerName}
          name={evidence?.assignee_name}
        />
      ) : (
        ""
      ),
  },
];
