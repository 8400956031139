import { LoadingButton } from "@mui/lab";
import { Box, DialogContent, Stack, Typography, TypographyProps } from "@mui/material";
import { useState } from "react";
import { Awaitable } from "../utilities/common";
import { SmallModal } from "./SmallModal";

type ConfirmationModalProps = {
  /** Whether the confirmation modal is open */
  open: boolean;
  /** Title of the modal */
  title: string;
  /** Description text for the modal */
  description: string;
  /** Variant to apply on the description */
  descriptionVariant?: TypographyProps["variant"];
  /** Text to display on the accept button. Defaults to "Yes" */
  acceptText?: string;
  /** Callback when confirmation is accepted */
  onAccept: () => Awaitable<void>;
  /** Text to display on the reject button. Defaults to "Cancel" */
  rejectText?: string;
  /** Callback when confirmation is accepted. Defaults to the `onClose` callback */
  onReject?: () => Awaitable<void>;
  /** Callback to close the modal */
  onClose: () => void;
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open,
    title,
    description,
    descriptionVariant = "body2",
    acceptText,
    onAccept,
    rejectText,
    onReject,
    onClose,
  } = props;
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const onAcceptClick = async () => {
    setIsAccepting(true);
    try {
      await onAccept();
      onClose();
    } finally {
      setIsAccepting(false);
    }
  };
  const onRejectClick = async () => {
    setIsRejecting(true);
    try {
      await (onReject ?? onClose)();
      onClose();
    } finally {
      setIsRejecting(false);
    }
  };
  return (
    <SmallModal open={open} onClose={onClose} title={title}>
      <Stack gap="10px">
        <DialogContent>
          <Typography variant={descriptionVariant} whiteSpace="pre-wrap">
            {description}
          </Typography>
        </DialogContent>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          alignItems="center"
          gap="5px"
        >
          <LoadingButton
            variant="text"
            color="error"
            fullWidth={false}
            onClick={onRejectClick}
            loading={isRejecting}
            disabled={isAccepting}
          >
            {rejectText ?? "Cancel"}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            fullWidth={false}
            onClick={onAcceptClick}
            loading={isAccepting}
            disabled={isRejecting}
          >
            {acceptText ?? "Yes"}
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};
