import { StatusBadge } from "../../../../../atoms/StatusBadge";
import UserTag from "../../../../../components/UserTag";
import {
  BaseFilterOptions,
  BaseFilters,
  ComplianceElement,
  FilterOption,
} from "../../../../../models/types";
import { IColumnVisibility } from "../../../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../../../molecules/TableTextWithEllipsis";

export const elementDetailsActionsFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const elementDetailsActionsColumnsVisibility: IColumnVisibility<ComplianceElement>[] = [
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 400,
    renderer: (element) => <TableTextWithEllipsis value={element.name} />,
  },
  {
    field: "status",
    headerName: "Status",
    columnMinWidth: 170,
    columnMaxWidth: 170,
    visible: true,
    renderer: (element) => <StatusBadge status={element.status as any} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMinWidth: 80,
    columnMaxWidth: 80,
    renderer: (element) =>
      element.assignee_name ? (
        <UserTag showNameInitials hideName name={element.assignee_name} />
      ) : (
        ""
      ),
  },
];
