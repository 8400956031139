import { RegistryFieldType, TaskType } from "./../models/types";

export const getTaskTypesByObjectType = (objectType: RegistryFieldType): TaskType[] => {
  const tasksTypes: TaskType[] = ["Review", "Approval"];

  if (objectType === "Questionnaire") {
    return ["Questionnaire Task", "Question Review Task", "Questionnaire Review Task"];
  }

  if (objectType === "Evidence") {
    return ["Compliance Task"];
  }

  return tasksTypes;
};
