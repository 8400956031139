import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { NeedsAttentionSummary } from "../../../atoms/NeedsAttentionSummary";
import { useComplianceElementSummary } from "../../../hooks/useComplianceElements";
import { ComplianceElement } from "../../../models/types";
import { CustomProgressBar } from "../../OverviewCard";
import { RectangularLoader } from "../../skeleton-loader/RectangularLoader";

type ElementDetailsProgressFormProps = {
  element: ComplianceElement;
};

export const ElementDetailsProgressForm = ({ element }: ElementDetailsProgressFormProps) => {
  const theme = useTheme();
  const inheritColor = theme.palette.custom.secondaryTypography;
  const { data: progress } = useComplianceElementSummary(element.id, { fairo_data: true });
  const total_count =
    element?.evidences_progress && element?.policies_progress && element?.documentations_progress
      ? element?.evidences_progress?.total +
        element?.policies_progress?.total +
        element?.documentations_progress?.total
      : null;
  const total_completed =
    element?.evidences_progress && element?.policies_progress && element?.documentations_progress
      ? element?.evidences_progress?.completed +
        element?.policies_progress?.completed +
        element?.documentations_progress?.completed
      : null;
  const hasProgress =
    total_count !== null && total_count !== 0 && total_completed !== null && total_completed !== 0;
  return !hasProgress ? null : progress ? (
    <Box display="flex" flexDirection="column" gap="5px">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h3" color={inheritColor}>
          Overall Progress
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="row" gap="5px">
          <Typography variant="h5" color={inheritColor}>
            Needs Attention
          </Typography>
          <Tooltip
            title={NeedsAttentionSummary(progress.summary)}
            placement="top-start"
            style={{ height: "20px" }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <NavbarIcon
                variant="tooltip"
                color="none"
                sx={{ width: "16px", height: "16px", padding: "0px" }}
              />
            </span>
          </Tooltip>
          <Typography variant="body2">{progress?.total - progress?.completed}</Typography>
        </Box>
      </Box>
      <CustomProgressBar
        fullWidth
        completed={progress?.completed}
        showCount
        total={progress?.total}
      />
    </Box>
  ) : (
    <RectangularLoader width="100%" height="50px" />
  );
};
