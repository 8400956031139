import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchEvidenceMappings } from "../../hooks/useEvidenceMapping";
import { getFinalNumberFromPath, getLastPathByRegex } from "../../utilities/UIHelper";

const EVIDENCE_NAVIGATOR_KEY = "library-navigator";

const useEvidencePathState = (locationState: any, path: string) => {
  const evidenceId = locationState?.evidenceId;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    evidenceId: string;
  }>(
    [EVIDENCE_NAVIGATOR_KEY, path],
    async () => {
      if (evidenceId != null) {
        return { evidenceId };
      }
      // the patter is /compliance/library/fairo/anything

      const isFairoPath = !!path.match(/\/fairo\/.*/g);

      const list = await fetchEvidenceMappings({
        evidence_test_num: [number],
        fairo_data: isFairoPath,
      });
      const obj = list.results.find((o) => o.evidence_test_num === Number(number));
      if (!obj) {
        navigate("/oversight/library");
        return { evidenceId: null };
      }
      return { evidenceId: obj.id };
    },
    { suspense: true }
  );
};

const navigateToEvidence = (params: {
  navigate: NavigateFunction;
  evidenceId: string;
  evidenceNumber: number;
}) => {
  const { evidenceId, navigate, evidenceNumber } = params;
  navigate(`/oversight/evidence-tests/Evidence-${evidenceNumber}`, {
    state: {
      evidenceId,
    },
  });
};

export { navigateToEvidence, useEvidencePathState };
