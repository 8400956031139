import { useQuery } from "react-query";
import {
  getJiraAppIntegrationInfo,
  searchJiraIssueTypes,
  searchJiraProjects,
  searchJiraUsers,
} from "../services/PluginService";
import { QueryKey } from "../state/QueryStore";
import { useAuth } from "./useAuth";

export const useJiraIntegration = () => {
  const { organization } = useAuth();
  return useQuery(
    [QueryKey.jiraIntegrationGet, organization?.id],
    async () => {
      const resp = await getJiraAppIntegrationInfo();
      return resp.data;
    },
    {
      enabled: organization != null,
    }
  );
};

export const useJiraProjects = () => {
  return useQuery([QueryKey.JiraProjects], async () => {
    const { data } = await searchJiraProjects();
    return data;
  });
};

export const useJiraUsers = () => {
  return useQuery([QueryKey.JiraUsers], async () => {
    const { data } = await searchJiraUsers();
    return data;
  });
};

export const useJiraIssueTypes = (projectId: string) => {
  return useQuery([QueryKey.JiraIssueTypes, projectId], async () => {
    if (projectId === "") return [];
    const { data } = await searchJiraIssueTypes(projectId);
    return data;
  });
};
