import { Box, Typography, useTheme } from "@mui/material";
import { DefaultBadge, RiskScoreBadge } from "../atoms/StatusBadge";
import { Question, RegistryFieldType } from "../models/types";
import { TagListReadOnlyCustomField } from "../molecules/custom-fields/TagListCustomField";
import { ShowMore } from "../molecules/ShowMore";
import { defaultDateDisplay } from "../utilities/UIHelper";
import { DisplayElementBadge } from "./compliance/elements/DisplayElementBadge";
import { DataOriginReadOnly } from "./custom-fields/DataOriginReadOnly";
import { PotentialHarmListFieldReadOnly } from "./custom-fields/PotentialHarms";
import { RelationshipComplexityReadOnly } from "./custom-fields/RelationshipComplexityReadOnly";
import { SingleSelectionReadOnly } from "./custom-fields/SingleSelection";

type DisplayReadOnlyCustomFieldProps = {
  field_type: RegistryFieldType;
  question?: Question;
  value: string | string[] | number;
  sx?: any;
  padding?: string;
  disabled: boolean;
};

export const NameAndDescriptionReadOnly = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.description}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const ExternalLink = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.description}
            </Typography>
            <Typography
              onClick={() => !disabled && window.open(obj.link, "_blank")}
              variant="body2"
              color={theme.palette.custom.blueTypography}
              sx={{
                cursor: disabled ? "default" : "pointer",
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.link}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const DisplayReadOnlyCustomField = (props: DisplayReadOnlyCustomFieldProps) => {
  const { field_type, value, disabled, padding = "10px", question } = props;
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box paddingLeft={padding}>
      {(() => {
        switch (field_type) {
          case "Date":
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {typeof value === "string" ? defaultDateDisplay(value) : ""}
              </Typography>
            );
          case "Name and Description list":
            return (
              <NameAndDescriptionReadOnly
                value={typeof value === "string" ? value : ""}
                disabled={disabled}
              />
            );
          case "Potential Harm":
            return (
              <PotentialHarmListFieldReadOnly
                value={typeof value === "string" ? JSON.parse(value) : ""}
                disabled={disabled}
              />
            );
          case "External Link":
            return (
              <ExternalLink value={typeof value === "string" ? value : ""} disabled={disabled} />
            );
          case "String list":
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px">
                {Array.isArray(value) &&
                  value.map((item, index) => <DefaultBadge key={index} value={item} />)}
              </Box>
            );
          case "Element list":
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px">
                {Array.isArray(value) &&
                  value.map((item, index) => <DisplayElementBadge key={index} elementId={item} />)}
              </Box>
            );
          case "Tag":
            return <TagListReadOnlyCustomField value={typeof value === "string" ? [value] : []} />;
          case "Tag List":
            return (
              <TagListReadOnlyCustomField
                value={typeof value === "string" ? JSON.parse(value.replace(/'/g, '"')) : []}
              />
            );
          case "Formatted Text":
            return (
              <ShowMore
                description={typeof value === "string" ? value : ""}
                maxChar={250}
                richText={true}
              />
            );
          case "Risk Level":
            return (
              <Box display="flex" alignItems="center" flexDirection="row" gap="10px">
                <RiskScoreBadge size="small" score={Number(value)} />
                <Typography variant="body2">
                  {question?.choices?.find((opt) => opt.value === value)?.label}
                </Typography>
              </Box>
            );
          case "Single Selection":
            return (
              <SingleSelectionReadOnly
                value={typeof value === "string" ? value : ""}
                choices={question?.choices ?? []}
              />
            );
          case "Data Origin":
            return typeof value === "string" ? (
              <DataOriginReadOnly value={JSON.parse(value)} />
            ) : null;
          case "Relationship Complexity":
            return typeof value === "string" ? (
              <RelationshipComplexityReadOnly value={JSON.parse(value)} />
            ) : null;
          default:
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {value}
              </Typography>
            );
        }
      })()}
    </Box>
  );
};
