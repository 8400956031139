import { Box, Typography } from "@mui/material";
import { RiskScoreBadge } from "../../atoms/StatusBadge";
import { RelationshipComplexity } from "../../models/types";

type RelationshipComplexityFieldProps = {
  value: RelationshipComplexity;
};

export const RelationshipComplexityReadOnly = ({ value }: RelationshipComplexityFieldProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      {value.expertise && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Expertise:
          </Typography>
          <RiskScoreBadge score={value.expertise} size="small" />
        </Box>
      )}
      {value.causality && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Causality:
          </Typography>
          <RiskScoreBadge score={value.causality} size="small" />
        </Box>
      )}
      {value.linearity && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Linearity:
          </Typography>
          <RiskScoreBadge score={value.linearity} size="small" />
        </Box>
      )}
      {value.reducibility && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Reducibility:
          </Typography>
          <RiskScoreBadge score={value.reducibility} size="small" />
        </Box>
      )}
      {value.solvability && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <Typography minWidth="61px" variant="h4">
            Solvability:
          </Typography>
          <RiskScoreBadge score={value.solvability} size="small" />
        </Box>
      )}
    </Box>
  );
};
