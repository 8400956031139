import { useEffect } from "react";
import { useQuestionnaire } from "../../hooks/useQuestionnaires";
import { FieldHandlerProps, Question } from "../../models/types";
import { SearchableDropdown } from "../../components/SearchableDropdown";

export const QuestionCustomField = (
  props: FieldHandlerProps & {
    questionnaireId: string;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, onChange, error, required, questionnaireId, setObjectAttributes } = props;
  const { data: questionnaire } = useQuestionnaire(questionnaireId);

  useEffect(() => {
    if (questionnaire?.questions && setObjectAttributes) {
      setObjectAttributes(questionnaire.questions ?? {});
    }
  }, [questionnaire]);

  return (
    <SearchableDropdown<Question>
      label="Question"
      required={required}
      error={error}
      value={questionnaire?.questions.find((q) => q.ref === value) || null}
      onChange={(newValue) => (newValue !== null ? onChange(newValue.ref) : onChange(null))}
      getOptions={(search: string) =>
        questionnaire?.questions.filter((option) =>
          option.title.toLowerCase().includes(search.toLowerCase())
        ) ?? []
      }
      isOptionEqualToValue={(a, b) => a.ref === b.ref}
      getOptionLabel={(obj) => obj.title ?? "-"}
    />
  );
};
