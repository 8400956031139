import { CircularProgress, Typography } from "@mui/material";
import React, { ReactElement } from "react";

type Props<T> = {
  isLoading: boolean;
  error: any;
  data: T | undefined;
  renderData: (data: T) => ReactElement;
};

/**Used to render data based widgets which have states: loading, error, data */
const DataComponentBuilder = <T,>({ isLoading, error, data, renderData }: Props<T>) => {
  if (isLoading) {
    return <CircularProgress className="centered-element" />;
  }

  if (error) {
    return <Typography>{typeof error === "string" ? error : "Error occurred"}</Typography>;
  }

  return renderData(data!);
};

export default DataComponentBuilder;
