import { Box, Tooltip } from "@mui/material";
import { RiskScoreBadge, StatusBadge } from "../../../atoms/StatusBadge";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import UserTag from "../../../components/UserTag";
import { BaseFilterOptions, BaseFilters, FilterOption, RiskRegistry } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { getRiskScore } from "../../../utilities/RiskScore";

export const riskRegistryTableFilters: FilterOption<BaseFilters>[] = [...BaseFilterOptions];

export const riskRegistryListTableColumns = ({
  onUnlink,
  vendor,
}: {
  onUnlink: (risk: RiskRegistry) => void;
  vendor: boolean;
}): IColumnVisibility<RiskRegistry>[] => [
  {
    field: "id",
    headerName: vendor ? "Vendor" : "Use Case",
    visible: true,
    renderer: (risk) => (
      <TableTextWithEllipsis
        value={vendor ? risk?.vendor_name?.name ?? "" : risk?.use_case_name?.name ?? ""}
      />
    ),
    columnMinWidth: 500,
  },
  {
    field: "inherent_risk_impact",
    headerName: "Inherent Risk",
    visible: true,
    renderer: (risk) =>
      risk.inherent_risk_likelihood && risk.inherent_risk_impact ? (
        <RiskScoreBadge
          score={getRiskScore(risk.inherent_risk_likelihood, risk.inherent_risk_impact)}
        />
      ) : (
        ""
      ),
    columnMaxWidth: 110,
    columnMinWidth: 110,
  },
  {
    field: "residual_risk_impact",
    headerName: "Residual Risk",
    visible: true,
    renderer: (risk) =>
      risk.residual_risk_impact && risk.residual_risk_likelihood ? (
        <RiskScoreBadge
          score={getRiskScore(risk.residual_risk_likelihood, risk.residual_risk_impact)}
        />
      ) : (
        ""
      ),
    columnMaxWidth: 110,
    columnMinWidth: 110,
  },
  {
    field: "risk_mitigation_strategy",
    headerName: "Treatment Plan",
    visible: true,
    renderer: (risk) => <TableTextWithEllipsis value={risk.risk_mitigation_strategy ?? ""} />,
    columnMaxWidth: 150,
    columnMinWidth: 150,
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (risk) => <StatusBadge status={risk.status} />,
    columnMinWidth: 150,
    columnMaxWidth: 150,
  },
  {
    field: "owner",
    headerName: "Owner",
    visible: true,
    renderer: (risk) => (risk.owner ? <UserTag name={risk.owner_name?.name} /> : ""),
    columnMinWidth: 200,
    columnMaxWidth: 200,
  },
  {
    field: "created_on",
    headerName: "",
    visible: true,
    renderer: (risk) => (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
          onUnlink(risk);
        }}
      >
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={"Unlink"}
          arrow
          placement="bottom"
          sx={{
            cursor: "pointer",
          }}
        >
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <NavbarIcon
              variant="unlink"
              sx={{
                width: "30px",
                height: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            />
          </Box>
        </Tooltip>
      </Box>
    ),
    columnMinWidth: 40,
    columnMaxWidth: 40,
  },
];
