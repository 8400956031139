import { useQuery } from "react-query";
import { VendorFetchOptions } from "../models/types";
import { getVendor, getVendors } from "../services/VendorService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useVendors = (params: VendorFetchOptions) => {
  return useQuery([QueryKey.Vendor, params], async () => {
    try {
      const { data } = await getVendors(params);
      return data;
    } catch (error) {
      return { count: 0, next: null, previous: null, results: [] };
    }
  });
};

export const fetchVendors = (params: VendorFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Vendor, params], async () => {
    const { data } = await getVendors(params);
    return data;
  });
};

export const useVendor = (vendorId: string) => {
  return useQuery([QueryKey.Vendor, vendorId], async () => {
    if (!vendorId) {
      return null;
    }
    const { data } = await getVendor(vendorId);
    return data;
  });
};

export const fetchVendor = (vendorId: string) => {
  return queryClient.fetchQuery([QueryKey.Vendor, vendorId], async () => {
    const { data } = await getVendor(vendorId);
    return data;
  });
};

export const searchVendor = async (searchText: string) => {
  const vendors = await fetchVendors({ search: searchText });
  return vendors.results;
};
