import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchImpactAssessment, fetchImpactAssessments } from "../hooks/useImpactAssessment";
import { fetchQuestionnaires } from "../hooks/useQuestionnaires";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const IMPACT_ASSESSMENT_NAVIGATOR_KEY = "impact-assessment-navigator";

export const useImpactAssessmentPathState = (locationState: any, path: string) => {
  const impactAssessmentId = locationState?.impactAssessmentId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    impactAssessmentId: string;
    edit: boolean;
  }>(
    [IMPACT_ASSESSMENT_NAVIGATOR_KEY, path],
    async () => {
      if (impactAssessmentId != null) {
        return { impactAssessmentId, edit };
      }

      const impactAssessments = await fetchImpactAssessments({ impact_assessment_num: [number] });
      const impactAssessment = impactAssessments.results.find(
        (t) => t.impact_assessment_num === Number(number)
      );
      if (!impactAssessment) {
        navigate("/oversight/impact-assessment");
        return { impactAssessmentId: null, edit };
      }

      return { impactAssessmentId: impactAssessment.id, edit };
    },
    { suspense: true }
  );
};

export const useImpactAssessmentReviewPathState = (locationState: any, path: string) => {
  const impactAssessmentId = locationState?.impactAssessmentId;
  const questionnaireId = locationState?.questionnaireId;
  const pathComponents = path.split("/");
  const impactAssessment = pathComponents[pathComponents.length - 4];
  const questionnaire = pathComponents[pathComponents.length - 2];
  const impactAssessmentNumber = getFinalNumberFromPath(impactAssessment);
  const questionnaireNumber = getFinalNumberFromPath(questionnaire);
  const navigate = useNavigate();
  return useQuery<{
    impactAssessmentId: string;
    questionnaireId: string;
  }>(
    [IMPACT_ASSESSMENT_NAVIGATOR_KEY, path],
    async () => {
      if (impactAssessmentId != null && questionnaireId != null) {
        return { impactAssessmentId, questionnaireId };
      }

      const impactAssessments = await fetchImpactAssessments({
        impact_assessment_num: [impactAssessmentNumber],
      });
      const impactAssessment = impactAssessments.results.find(
        (t) => t.impact_assessment_num === Number(impactAssessmentNumber)
      );
      if (!impactAssessment) {
        navigate("/oversight/impact-assessment");
        return { impactAssessmentId: null, questionnaireId: null };
      }

      const questionnaires = await fetchQuestionnaires({
        questionnaire_num: [questionnaireNumber],
      });
      const questionnaire = questionnaires.results.find(
        (t) => t.questionnaire_num === Number(questionnaireNumber)
      );
      if (
        !questionnaire ||
        !impactAssessment.sections.some((t) => t.id === questionnaire.section)
      ) {
        console.log("questionnaire not found");
        navigate("/oversight/impact-assessment");
        return { impactAssessmentId: null, questionnaireId: null };
      }

      return { impactAssessmentId: impactAssessment.id, questionnaireId: questionnaire.id };
    },
    { suspense: true }
  );
};

export const useImpactAssessmentSectionReviewPathState = (locationState: any, path: string) => {
  const impactAssessmentId = locationState?.impactAssessmentId;
  const sectionId = locationState?.sectionId;
  const pathComponents = path.split("/");
  const impactAssessment = pathComponents[pathComponents.length - 4];
  const section = pathComponents[pathComponents.length - 2];
  const impactAssessmentNumber = getFinalNumberFromPath(impactAssessment);
  const sectionNum = getFinalNumberFromPath(section);
  const navigate = useNavigate();
  return useQuery<{
    impactAssessmentId: string;
    sectionId: string;
  }>(
    [IMPACT_ASSESSMENT_NAVIGATOR_KEY, path],
    async () => {
      if (impactAssessmentId != null && sectionId != null) {
        return { impactAssessmentId, sectionId };
      }

      const impactAssessments = await fetchImpactAssessments({
        impact_assessment_num: [impactAssessmentNumber],
      });
      const impactAssessment = impactAssessments.results.find(
        (t) => t.impact_assessment_num === Number(impactAssessmentNumber)
      );
      if (!impactAssessment) {
        navigate("/oversight/impact-assessment");
        return { impactAssessmentId: null, sectionId: null };
      }

      const impactAssessmentDetails = await fetchImpactAssessment(impactAssessment.id);

      const section = impactAssessmentDetails.sections?.find(
        (t) => t.section_num === Number(sectionNum)
      );
      if (!section) {
        navigate(`/oversight/impact-assessment/impact-assessment-${impactAssessmentNumber}`);
        return { impactAssessmentId: impactAssessment.id, sectionId: null };
      }

      return { impactAssessmentId: impactAssessment.id, sectionId: section.id };
    },
    { suspense: true }
  );
};

const navigateToImpactAssessment = (params: {
  navigate: NavigateFunction;
  impactAssessmentId: string;
  impactAssessmentNumber: number;
  edit?: boolean;
  section?: number;
  returnLink?: boolean;
}) => {
  const {
    impactAssessmentId,
    impactAssessmentNumber,
    edit,
    navigate,
    section,
    returnLink = false,
  } = params;
  if (returnLink) {
    return `/oversight/impact-assessment/impact-assessment-${impactAssessmentNumber}${
      section ? `?section=${section}` : ""
    }`;
  }
  navigate(
    `/oversight/impact-assessment/impact-assessment-${impactAssessmentNumber}${
      section ? `?section=${section}` : ""
    }`,
    {
      state: {
        impactAssessmentId,
        edit,
      },
    }
  );
};

const navigateToImpactAssessmentReview = (params: {
  navigate: NavigateFunction;
  impactAssessmentId: string;
  impactAssessmentNumber: number;
  questionnaireNumber: number;
  questionnaireId: string;
}) => {
  const {
    impactAssessmentId,
    impactAssessmentNumber,
    questionnaireNumber,
    navigate,
    questionnaireId,
  } = params;
  navigate(
    `/oversight/impact-assessment/impact-assessment-${impactAssessmentNumber}/questionnaire/questionnaire-${questionnaireNumber}/review`,
    {
      state: {
        impactAssessmentId,
        questionnaireId,
      },
    }
  );
};

const navigateToImpactAssessmentSectionReview = (params: {
  navigate: NavigateFunction;
  impactAssessmentId: string;
  impactAssessmentNumber: number;
  sectionId: string;
  sectionNumber: number;
}) => {
  const { impactAssessmentId, impactAssessmentNumber, sectionId, navigate, sectionNumber } = params;
  navigate(
    `/oversight/impact-assessment/impact-assessment-${impactAssessmentNumber}/section/section-${sectionNumber}/review`,
    {
      state: {
        impactAssessmentId,
        sectionId,
      },
    }
  );
};

export {
  navigateToImpactAssessment,
  navigateToImpactAssessmentReview,
  navigateToImpactAssessmentSectionReview,
};
