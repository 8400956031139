import { useQuery } from "react-query";
import { ComplianceElementFetchOptions } from "../models/types";
import { getComplianceElement, getComplianceElements } from "../services/ComplianceElements";
import { getVersionHistory } from "../services/VersionHistoryService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getComplianceElementSummary } from "./../services/ComplianceElements";

export const useComplianceElements = (params: ComplianceElementFetchOptions) => {
  return useQuery([QueryKey.ComplianceElement, params], async () => {
    try {
      const { data } = await getComplianceElements(params);
      return data;
    } catch (error) {
      return { count: 0, next: null, previous: null, results: [] };
    }
  });
};

export const useComplianceElementSummary = (
  elementId: string,
  params: ComplianceElementFetchOptions
) => {
  return useQuery([QueryKey.ComplianceElement, params], async () => {
    const { data } = await getComplianceElementSummary(elementId, params);
    return data;
  });
};

export const fetchComplianceElements = (params: ComplianceElementFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.ComplianceElement, params], async () => {
    const { data } = await getComplianceElements(params);
    return data;
  });
};

export const searchComplianceElements = async (searchText: string) => {
  const complianceElements = await fetchComplianceElements({
    search: searchText,
    fairo_data: true,
  });
  return complianceElements.results;
};

export const searchComplianceElementsWithParams =
  (params: ComplianceElementFetchOptions) => async (searchText: string) => {
    const complianceElements = await fetchComplianceElements({
      search: searchText,
      ...params,
    });
    return complianceElements.results;
  };

export const useComplianceElement = (
  elementId: string,
  params: { fairo_data: boolean },
  version_num?: number
) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.ComplianceElement, elementId, version_num]
    : [QueryKey.ComplianceElement, elementId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [elementId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        if (!elementId) return null;
        const { data } = await getComplianceElement(elementId, params);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchComplianceElement = (elementId: string, params: { fairo_data: boolean }) => {
  return queryClient.fetchQuery([QueryKey.ComplianceElement, elementId], async () => {
    const { data } = await getComplianceElement(elementId, params);
    return data;
  });
};
