import { Box, Typography, useTheme } from "@mui/material";
import {
  getFourLevelRiskScoreColor,
  getThreeLevelRiskScoreColor,
  RiskScoreBadge,
} from "../../atoms/StatusBadge";
import { SearchableDropdown } from "../../components/SearchableDropdown";

type RelationshipComplexityFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};

/**
 *
 * @param RelationshipComplexityFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */
export const RelationshipComplexityField = (props: RelationshipComplexityFieldProps) => {
  const { value, onChange, required, valueError, errorMessage } = props;
  const theme = useTheme();
  type RiskOptionsType = { name: string; score: 1 | 2 | 3 | 4 | null };

  const expertiseOptions: RiskOptionsType[] = [
    { name: "Redacted", score: 1 },
    { name: "Redacted", score: 2 },
    { name: "Redacted", score: 3 },
    { name: "Redacted", score: 4 },
  ];

  const threeLevelOptions: RiskOptionsType[] = [
    { name: "Redacted", score: 1 },
    { name: "Redacted", score: 2 },
    { name: "Redacted", score: 3 },
  ];

  const handleValue = (valueKey: string, newValue: number | null) => {
    const oldValue = JSON.parse(value || "{}");
    const option = expertiseOptions.find((option) => option.score === newValue);
    const newValueJson = { ...oldValue, [valueKey]: option?.score ?? null };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Expertise
      </Typography>
      <SearchableDropdown<number | null>
        label="Select or search"
        value={getValue("expertise")}
        required={required}
        onChange={(option) => handleValue("expertise", option)}
        getOptions={(search: string) =>
          expertiseOptions
            .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.score)
        }
        getOptionLabel={(option) =>
          expertiseOptions.find((opt) => opt.score === option)?.name ?? ""
        }
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("expertise") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getFourLevelRiskScoreColor(getValue("expertise"), theme)}
                size="small"
                score={getValue("expertise")}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && (
                <RiskScoreBadge
                  bgcolor={getFourLevelRiskScoreColor(option, theme)}
                  size="small"
                  score={option}
                />
              )}
              <Typography variant="body2">
                {expertiseOptions.find((opt) => opt.score === option)?.name}
              </Typography>
            </Box>
          );
        }}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Causality
      </Typography>
      <SearchableDropdown<number | null>
        label="Select or search"
        value={getValue("causality")}
        required={required}
        onChange={(option) => handleValue("causality", option)}
        getOptions={(search: string) =>
          threeLevelOptions
            .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.score)
        }
        getOptionLabel={(option) =>
          threeLevelOptions.find((opt) => opt.score === option)?.name ?? ""
        }
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("causality") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("causality"), theme)}
                size="small"
                score={getValue("causality")}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option, theme)}
                  size="small"
                  score={option}
                />
              )}
              <Typography variant="body2">
                {threeLevelOptions.find((opt) => opt.score === option)?.name}
              </Typography>
            </Box>
          );
        }}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Linearity
      </Typography>
      <SearchableDropdown<number | null>
        label="Select or search"
        value={getValue("linearity")}
        required={required}
        onChange={(option) => handleValue("linearity", option)}
        getOptions={(search: string) =>
          threeLevelOptions
            .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.score)
        }
        getOptionLabel={(option) =>
          threeLevelOptions.find((opt) => opt.score === option)?.name ?? ""
        }
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("linearity") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("linearity"), theme)}
                size="small"
                score={getValue("linearity")}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option, theme)}
                  size="small"
                  score={option}
                />
              )}
              <Typography variant="body2">
                {threeLevelOptions.find((opt) => opt.score === option)?.name}
              </Typography>
            </Box>
          );
        }}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Reducibility
      </Typography>
      <SearchableDropdown<number | null>
        label="Select or search"
        value={getValue("reducibility")}
        required={required}
        onChange={(option) => handleValue("reducibility", option)}
        getOptions={(search: string) =>
          threeLevelOptions
            .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.score)
        }
        getOptionLabel={(option) =>
          threeLevelOptions.find((opt) => opt.score === option)?.name ?? ""
        }
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("reducibility") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("reducibility"), theme)}
                size="small"
                score={getValue("reducibility")}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option, theme)}
                  size="small"
                  score={option}
                />
              )}
              <Typography variant="body2">
                {threeLevelOptions.find((opt) => opt.score === option)?.name}
              </Typography>
            </Box>
          );
        }}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Solvability
      </Typography>
      <SearchableDropdown<number | null>
        label="Select or search"
        value={getValue("solvability")}
        required={required}
        onChange={(option) => handleValue("solvability", option)}
        getOptions={(search: string) =>
          threeLevelOptions
            .filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))
            .map((option) => option.score)
        }
        getOptionLabel={(option) =>
          threeLevelOptions.find((opt) => opt.score === option)?.name ?? ""
        }
        isOptionEqualToValue={(a, b) => a === b}
        startAdornment={
          getValue("solvability") ? (
            <Box display="flex" alignItems="center">
              <RiskScoreBadge
                bgcolor={getThreeLevelRiskScoreColor(getValue("solvability"), theme)}
                size="small"
                score={getValue("solvability")}
              />{" "}
            </Box>
          ) : null
        }
        renderOption={(props, option) => {
          return (
            <Box display="flex" width="100%" gap="5px" alignItems="center">
              {option && (
                <RiskScoreBadge
                  bgcolor={getThreeLevelRiskScoreColor(option, theme)}
                  size="small"
                  score={option}
                />
              )}
              <Typography variant="body2">
                {threeLevelOptions.find((opt) => opt.score === option)?.name}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
