import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import DeleteIconButton from "../../components/DeleteIconButton";
import { SmallModal } from "../../components/SmallModal";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useExecutionTask } from "../../hooks/useExecutionTask";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { deleteExecutionTask } from "../../services/ExecutionPlanService";
import { GithubTaskSummary } from "../GithubSummary";
import { JiraTaskSummary } from "../JiraSummary";
import { BaseTaskModal } from "./tasks/BaseTaskModal";
type ExecutionTaskModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Optional test result for update purposes */
  taskId: string;
  disabled?: boolean;
};

export const HeaderAndValue = (props: { header: string; value: string }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" flexDirection="row" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        {props.header}
      </Typography>
      <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
        {props.value}
      </Typography>
    </Box>
  );
};

export const ExecutionTaskModal = (props: ExecutionTaskModalProps) => {
  const { open, onClose, taskId, disabled } = props;
  const { data: task } = useExecutionTask(taskId);
  const navigate = useNavigate();
  const { setSelectedTask } = useOrganizationContext();
  const [assignedUser, setAssignedUser] = useState<string>("");
  const theme = useTheme();
  const [isUpdate, setIsUpdate] = useState(false);
  const isCompleted = task?.status === "COMPLETED";
  const isJira = task?.action_type === "External-Jira";
  const isGithub = task?.action_type === "External-Github";
  const isReview = task?.action_type === "Review";
  const isApproval = task?.action_type === "Approval";
  const secondTitle = isJira
    ? "External Jira"
    : isGithub
    ? "External Github"
    : isReview
    ? "Review"
    : isApproval
    ? "Approval"
    : "";
  return (
    <SmallModal
      size={"medium"}
      open={open}
      onClose={onClose}
      isLoading={!task}
      title="Task"
      customTitle={
        <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
          {!!task && <TableTextWithEllipsis variant="h2" value={task.name} />}
          {!task && <RectangularLoader width="120px" />}
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="row" gap="5px">
          {!isUpdate && !isCompleted && (
            <IconButton
              color={theme.palette.custom.hyperlink}
              height="18px"
              width="18px"
              onClick={() => setIsUpdate(!isUpdate)}
              variant="edit-icon"
            />
          )}
          {isUpdate && (
            <DeleteIconButton
              deleteConfirmationDialogTitle="Remove Execution Task"
              deleteConfirmationDialogDescription={`Are you sure you want to remove Execution Task-${task?.task_num}? Once it’s deleted it’s gone for good`}
              onDeleteConfirm={async () => {
                await deleteExecutionTask(taskId);
                onClose();
              }}
              filledButton
              disabled={disabled}
            />
          )}
        </Box>
      }
    >
      <Box gap="10px" width="100%" display="flex" flexDirection="column">
        {!isJira && !isGithub && (
          <BaseTaskModal
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            onClose={onClose}
            taskId={taskId}
          />
        )}
        {task && isJira && (
          <JiraTaskSummary setIsUpdate={setIsUpdate} isUpdate={isUpdate} task={task} />
        )}
        {task && isGithub && (
          <GithubTaskSummary setIsUpdate={setIsUpdate} isUpdate={isUpdate} task={task} />
        )}
      </Box>
    </SmallModal>
  );
};
