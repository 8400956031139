import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { PartialUser } from "../models/types";
import FormTimestamp from "./FormTimestamp";

type ITimestampWithAction = {
  createdOn?: string;
  createdBy?: PartialUser;
  updatedOn?: string;
  updatedBy?: PartialUser;
  padding?: string;
  direction?: "row" | "column";
  isUpdated?: boolean;
  isLoading: boolean;
  buttonLabel: string;
  onClick: () => void;
  disabled?: boolean;
  showUpdated?: boolean;
  showCreated?: boolean;
};

const TimestampWithAction = (props: ITimestampWithAction) => {
  const {
    createdOn,
    createdBy,
    updatedOn,
    updatedBy,
    padding = "10px 0",
    direction = "column",
    isUpdated,
    isLoading,
    buttonLabel,
    onClick,
    disabled,
    showUpdated = true,
    showCreated = true,
  } = props;
  const hasTimestamp = createdOn && createdBy && updatedOn && updatedBy;
  return (
    <Box
      gap="10px"
      paddingTop={padding}
      display="flex"
      flexDirection={direction}
      alignItems={direction === "row" ? "center" : "flex-start"}
      justifyContent={hasTimestamp ? "space-between" : "flex-end"}
      flexWrap="wrap"
    >
      {hasTimestamp && (
        <FormTimestamp
          padding="0px"
          direction={direction}
          createdBy={createdBy}
          updatedBy={updatedBy}
          createdOn={createdOn}
          updatedOn={updatedOn}
          showUpdated={showUpdated}
          showCreated={showCreated}
        />
      )}
      {isUpdated && !disabled && (
        <LoadingButton
          fullWidth={direction === "column"}
          variant="contained"
          loading={isLoading}
          onClick={() => onClick()}
        >
          {buttonLabel}
        </LoadingButton>
      )}
    </Box>
  );
};

export { TimestampWithAction };
