import { Box } from "@mui/material";
import { useFrameworks } from "../../../hooks/useFramework";
import {
  AssetFetchOptions,
  ComplianceFramework,
  ComplianceFrameworkFetchOptions,
  EvidenceTestStatusOptions,
} from "../../../models/types";
import { AssigneeFilter } from "../AssigneeFilter";
import { ClearAllFilters } from "../ClearAll";
import { GenericFilter } from "../GenericFilter";
import { StatusFilter } from "../StatusFilter";

type AssetFiltersProps = {
  params: AssetFetchOptions;
  setParams: (params: AssetFetchOptions) => void;
  showFrameworks?: boolean;
  showStatus?: boolean;
  frameworkIds?: string[];
};

export const AssetFilters = ({
  params,
  setParams,
  frameworkIds,
  showFrameworks = true,
  showStatus = true,
}: AssetFiltersProps) => {
  const statusOptions: EvidenceTestStatusOptions[] = [
    "DEACTIVATED",
    "DUE SOON",
    "N/A",
    "OK",
    "OVERDUE",
    "PENDING",
  ];

  return (
    <Box display="flex" flexDirection="row" gap="10px" flexGrow={1}>
      {showStatus && (
        <StatusFilter
          label="Status"
          statusOptions={statusOptions}
          selectedStatus={params?.["test_status[]"] ?? []}
          setSelectedStatus={(status) => setParams({ ...params, "test_status[]": status })}
        />
      )}
      <AssigneeFilter
        selectedAssignee={params?.assignee ?? []}
        setSelectedAssignee={(assignee) => setParams({ ...params, assignee })}
      />
      {showFrameworks && (
        <GenericFilter<ComplianceFramework, ComplianceFrameworkFetchOptions>
          useGetData={useFrameworks}
          params={{
            ...(frameworkIds && { id: frameworkIds }),
          }}
          selected={params?.["asset_framework[]"] ?? []}
          setSelected={(framework: string[]) =>
            setParams({ ...params, "asset_framework[]": framework })
          }
          getItemName={(item) => item.name}
          label="Frameworks"
        />
      )}
      <ClearAllFilters params={params} setParams={setParams} />
    </Box>
  );
};
