import { useQuery } from "react-query";
import { UseCaseFetchOptions } from "../models/types";
import { getUseCase, getUseCases, getUseCaseSummary } from "../services/UseCaseService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useUseCases = (params: UseCaseFetchOptions) => {
  return useQuery([QueryKey.UseCase, params], async () => {
    const { data } = await getUseCases(params);
    return data;
  });
};

export const fetchUseCases = (params: UseCaseFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.UseCase, params], async () => {
    const { data } = await getUseCases(params);
    return data;
  });
};

export const searchUseCases = async (searchText: string) => {
  const tests = await fetchUseCases({ search: searchText });
  return tests.results;
};

export const useUseCase = (categoryId: string) => {
  return useQuery([QueryKey.UseCase, categoryId], async () => {
    if (categoryId === "") return null;
    const { data } = await getUseCase(categoryId);
    return data;
  });
};

export const useUseCaseSummary = (params: UseCaseFetchOptions) => {
  return useQuery([QueryKey.UseCase, params], async () => {
    const { data } = await getUseCaseSummary(params);
    return data;
  });
};
