import { LoadingButton } from "@mui/lab";

export const TemplateDownloadButton = ({
  template,
  fileName,
}: {
  template: string;
  fileName: string;
}) => {
  const handleDownload = () => {
    const header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'></head><body>";
    const footer = "</body></html>";

    const blob = new Blob([header + template + footer], { type: "application/msword" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.doc`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LoadingButton sx={{ minWidth: "115px" }} variant="contained" onClick={handleDownload}>
      Download
    </LoadingButton>
  );
};
