import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import FairoLogo from "../../atoms/navbar/Logo";
import config from "../../config/config";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useSubscription } from "../../context/SubscriptionContext";
import { useAppThemeContext } from "../../context/ThemeContext";
import { useAuth } from "../../hooks/useAuth";
import { useNotifications } from "../../hooks/useNotification";
import Navigation from "../../pages/navigation/Navigation";
import { getPlanHierarchy } from "../../utilities/PlanHierarchy";
import { FeaturePaywallModal } from "../modals/FeaturePaywallModal";
import { HeaderConditionalComponent } from "./HeaderConditionalComponent";
import { OrganizationThreeDotsMenu } from "./OrganizationThreeDotsMenu";

export const AuthenticatedHeader = () => {
  const [mlflowPaywallOpen, setMlflowPaywallOpen] = useState(false);
  const { currentPlan } = useSubscription();
  const { user, isAuditor } = useAuth();
  const { pathname } = useLocation();
  const isAdmin = user?.permissions === "Admin";
  const theme = useTheme();
  const { toggleTheme } = useAppThemeContext();
  const { setNotificationsOpen } = useOrganizationContext();
  const navigate = useNavigate();
  const { data: notifications } = useNotifications({
    "read[]": "False",
    page_size: 1,
  });
  const isEnterprise = currentPlan?.name === "Fairo Enterprise";
  return (
    <Box
      bgcolor={theme.palette.background.paper}
      width="100%"
      alignItems="center"
      gap="20px"
      borderBottom={`2.5px solid ${theme.palette.custom.secondaryBorder}`}
      display="flex"
      flexDirection="row"
      padding="10px 20px"
    >
      <FairoLogo labelVisible={false} width={"27px"} />
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Navigation rightComponent={<></>} showOnlyFirst={false} showFirstLast={false} />
        <Box gap="5px" display="flex" flexDirection="row" alignItems="center">
          <HeaderConditionalComponent />
          {!isAuditor() && (
            <IconButton
              variant="mlflow"
              height="28px"
              padding="0px"
              width="28px"
              onClick={
                getPlanHierarchy(currentPlan?.name ?? "") >= getPlanHierarchy("Fairo Enterprise")
                  ? () => {
                      window.open(`${config.mlflow.baseUrl}/mlflow-static`, "_blank");
                    }
                  : () => {
                      setMlflowPaywallOpen(true);
                    }
              }
            />
          )}
          <IconButton
            variant="theme"
            height="28px"
            width="28px"
            padding="0px"
            onClick={toggleTheme}
          />
          <IconButton
            variant={
              notifications?.results && notifications.results.length > 0
                ? "notification_alert"
                : "notification"
            }
            height="28px"
            padding="0px"
            width="28px"
            onClick={() => setNotificationsOpen(true)}
          />
          {isAdmin && (
            <IconButton
              selected={pathname.includes("/organization/settings")}
              variant="settings"
              height="26px"
              padding="0px"
              width="26px"
              onClick={() => navigate("/organization/settings")}
            />
          )}
          <OrganizationThreeDotsMenu />
        </Box>
      </Box>
      <FeaturePaywallModal
        open={mlflowPaywallOpen}
        onClose={() => {
          setMlflowPaywallOpen(false);
        }}
        route="mlflow"
      />
    </Box>
  );
};
