import { Box, IconButton, Typography } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import UserTag from "../../components/UserTag";
import { Question, QuestionnaireAnswer } from "../../models/types";
import { useUsers } from "../../services/UserSettingsService";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import { DisplayReadOnlyCustomField } from "../DisplayReadOnlyCustomField";

type DisplayAnswerProps = {
  conflicts: boolean;
  answer_number: number;
  answer: QuestionnaireAnswer;
  selected: boolean;
  onSelect: () => void;
  hasMoreThanOneAnswer: boolean;
  disabled: boolean;
  hasSelectedAnswer: boolean;
  question: Question;
};

export const DisplayAnswer = (props: DisplayAnswerProps) => {
  const {
    conflicts,
    answer,
    onSelect,
    selected,
    answer_number,
    hasMoreThanOneAnswer,
    disabled,
    hasSelectedAnswer,
    question,
  } = props;
  const { data: user } = useUsers(answer.created_by.id);
  // Display typography with low opacity when there's an answer selected and the current answer is not selected
  const disableTypographyEffect = hasSelectedAnswer && !selected;
  return (
    <Box
      sx={{
        opacity: disabled ? 0.5 : 1,
      }}
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box
        sx={{
          opacity: disableTypographyEffect ? 0.5 : 1,
          cursor: hasMoreThanOneAnswer ? "pointer" : "default",
        }}
        display="flex"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => hasMoreThanOneAnswer && onSelect()}
      >
        {hasMoreThanOneAnswer && (
          <Box display="flex" alignItems="center">
            <IconButton disabled={disabled} onClick={() => onSelect()}>
              <NavbarIcon
                variant={selected ? "radio-checked" : "radio-unchecked"}
                sx={{
                  height: "18px",
                  width: "18px",
                  minHeight: "18px",
                  minWidth: "18px",
                }}
              />
            </IconButton>
          </Box>
        )}
        <Typography variant="h3">Answer-{answer_number}</Typography>
        <Box display="flex" alignItems="center" paddingLeft="10px" gap="10px">
          <Typography variant="body2">{defaultDateDisplay(answer.created_on)}</Typography>
          <UserTag name={user?.name} />
        </Box>
      </Box>
      <DisplayReadOnlyCustomField
        question={question}
        field_type={answer.field_type}
        value={answer.answer}
        disabled={disableTypographyEffect}
      />
    </Box>
  );
};
