import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { CardTableItemLoader } from "./CardTableLoader";

export const FrameworksListLoader = () => {
  const theme = useTheme();
  return (
    <Box paddingTop="15px" paddingBottom="80px">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      >
        <Box height={`calc(80vh - 56px)`}>
          <Box
            paddingBottom={"0px"}
            borderRadius="6px"
            border="1px solid"
            minHeight="100%"
            maxHeight="100%"
            overflow="auto"
            borderColor={theme.palette.custom.secondaryBorder}
            bgcolor={theme.palette.custom.secondaryBackground}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                paddingLeft: "15px",
                paddingRight: "15px",
                justifyContent: "center",
                paddingTop: "15px",
                rowGap: "23px",
                paddingBottom: "50px",
                columnGap: "50px",
              }}
            >
              {[...Array(8)].map((_, idx) => (
                <CardTableItemLoader key={idx} width="100%" height="119px" />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
