import { SmallModal } from "../../../components/SmallModal";
import { useCustomFieldValues } from "../../../hooks/useCustomFields";
import { DisplayCustomFieldValues } from "../../../molecules/custom-fields/DisplayCustomFieldValues";

type ReadOnlyCustomFieldValuesModalProps = {
  open: boolean;
  onClose: () => void;
  objectId: string;
};
export const ReadOnlyCustomFieldValuesModal = (props: ReadOnlyCustomFieldValuesModalProps) => {
  const { open, onClose, objectId } = props;
  const { data: customFieldValues, isLoading } = useCustomFieldValues(objectId);
  return (
    <SmallModal
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      size="medium"
      title="Additional Details"
    >
      <DisplayCustomFieldValues customFields={customFieldValues} />
    </SmallModal>
  );
};
