import { PlanHeirarchy } from "../models/types";

export const PlanHierarchyOptions: PlanHeirarchy[] = [
  { index: 1, name: "Fairo Core" },
  { index: 2, name: "Fairo Trial" },
  { index: 2, name: "Fairo Premium" },
  { index: 3, name: "Fairo Enterprise" },
  { index: 4, name: "Fairo Organization" },
];

export const getPlanHierarchy = (planName: string): number => {
  return PlanHierarchyOptions.find((option) => option.name === planName)?.index ?? 2;
};
