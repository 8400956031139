import { Box } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomCircularProgress } from "../../../../atoms/CustomCircularProgress";
import { navigateToAsset } from "../../../../components/AssetNavigator";
import { useOrganizationContext } from "../../../../context/OrganizationContext";
import { useAssets } from "../../../../hooks/useAssets";
import { AssetFetchOptions, ComplianceElement } from "../../../../models/types";
import { APITable } from "../../../APITable";
import { AssetFilters } from "../../../filters/assets/AssetFilters";
import { elementDetailsDocumentsColumnsVisibility } from "./columns/ElementDetailsColumnsVisibility";

type ElementDetailsPoliciesTableProps = {
  elementId: string;
  element: ComplianceElement;
};

export const ElementDetailsPoliciesTable = ({
  elementId,
  element,
}: ElementDetailsPoliciesTableProps) => {
  const navigate = useNavigate();
  const [params, setParams] = useState<AssetFetchOptions>({});
  const { setSelectedElement } = useOrganizationContext();

  const hasParamsAdded =
    params &&
    ((params["test_status[]"] && params["test_status[]"].length > 0) ||
      (params.assignee && params.assignee.length > 0));

  return (
    <APITable
      hideOnEmpty={!hasParamsAdded}
      useGetData={useAssets}
      smallEmptyHeight={true}
      collapsable
      title={`Policies`}
      hideColumnVisibility
      collapsedInitialState={true}
      queryParams={{
        "object_relates_to_id[]": [elementId],
        "asset_type_name[]": ["Policy"],
        ...params,
      }}
      secondComponent={
        <AssetFilters showFrameworks={false} params={params} setParams={setParams} />
      }
      titleRightComponent={
        <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
          {element?.policies_progress && (
            <CustomCircularProgress progress={element.policies_progress} />
          )}
        </Box>
      }
      columnsVisibility={elementDetailsDocumentsColumnsVisibility}
      rowOnClick={(asset) => {
        navigateToAsset({ navigate, assetId: asset.id, assetNumber: asset.asset_num, asset });
        setSelectedElement(null);
      }}
      tableSize="small"
      displayTags={true}
    />
  );
};
