import { DefaultBadge } from "../../../atoms/StatusBadge";
import { useOrganizationContext } from "../../../context/OrganizationContext";
import { useComplianceElement } from "../../../hooks/useComplianceElements";
import { RectangularLoader } from "../../../molecules/skeleton-loader/RectangularLoader";

export const DisplayElementBadge = ({ elementId }: { elementId: string }) => {
  const { data: element, isLoading } = useComplianceElement(elementId, {
    fairo_data: true,
  });
  const { setSelectedElement } = useOrganizationContext();
  return isLoading ? (
    <RectangularLoader height="14px" width="50px" />
  ) : (
    <DefaultBadge onClick={() => setSelectedElement(elementId)} value={element?.name} />
  );
};
