import { ICustomFieldFormData } from "../components/custom-fields/CustomFieldForm";
import {
  IActionAddRoleRequest,
  IActionRemoveRoleRequest,
  ICreateActionRequestBody,
  IUpdateActionRequestBody,
} from "../models/Action";
import { ICreateAssetRequestBody, IUpdateAssetRequestBody } from "../models/Asset";
import { parseAssetTypeResult } from "../models/AssetType";
import {
  ICreateCustomFieldRequestBody,
  ICustomField,
  IUpdateCustomFieldRequestBody,
  parseCustomFieldResult,
  parseGetCustomFieldResult,
} from "../models/ICustomField";
import {
  ICreateProcessEdgeRequestBody,
  IUpdateProcessEdgeRequestBody,
  parseCreateProcessEdgeResult,
} from "../models/IProcessEdge";
import {
  ICreateProcessExecutionPlanRequestBody,
  IGetProcessExecutionPlanListRequestBody,
} from "../models/IProcessExecutionPlan";
import {
  ICreateProcessGraphRequestBody,
  IUpdateProcessGraphRequestBody,
} from "../models/IProcessGraph";
import {
  ICreateProcessNodeRequestBody,
  IUpdateProcessNodeRequestBody,
  parseProcessNodeResult,
} from "../models/IProcessNode";
import { ICreateUserRequestBody, IUser, parseUserFromListResult } from "../models/IUser";
import { ICreateWorkflowRequestBody, IUpdateWorkflowRequestBody } from "../models/IWorkflow";
import {
  ICreateRoleRequestBody,
  IRoleAddUserRequest,
  IRoleRemoveUserRequest,
  IUpdateRoleRequestBody,
} from "../models/Role";
import { ICreateTestCaseRequestBody, IUpdateTestCaseRequestBody } from "../models/TestCase";
import {
  Action,
  ActionFetchOptions,
  Asset,
  AssetFetchOptions,
  AssetStatusOptions,
  Organization,
  Role,
  RoleFetchOptions,
  TestCase,
  TestCaseFetchOptions,
  TestResult,
  TestResultFetchOptions,
  User,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { createOrUpdateParser, ListResponse } from "../utilities/ApiResponseHelper";
import {
  getPaginatedListMethod,
  objectCustomFieldValueMethods,
  objectFileAttachmentMethods,
  objectMethods,
  objectRelationships,
} from "../utilities/DataServiceHelper";
import {
  ApiEndpoint,
  customFieldEndpoint,
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
  httpPostPublic,
} from "./ApiService";

const dataEndpoints = {
  testCases: () => "/test_cases",
  testResults: () => "/test_results",
  testResult: (testResultId: string) => `/test_results/${testResultId}`,
  testCase: (testCaseId: string) => `/test_cases/${testCaseId}`,
  assets: () => "/assets",
  asset: (assetId: string) => `/assets/${assetId}`,
  roles: () => "/roles",
  role: (roleId: string) => `/roles/${roleId}`,
  actions: () => "/actions",
  action: (actionId: string) => `/actions/${actionId}`,
  actionAddRole: (actionId: string) => `/actions/${actionId}/add_role?fairo_data=true`,
  actionRemoveRole: (actionId: string) => `/actions/${actionId}/remove_role?fairo_data=true`,
  roleAddUser: (roleId: string) => `/roles/${roleId}/add_user?fairo_data=true`,
  roleRemoveUser: (roleId: string) => `/roles/${roleId}/remove_user?fairo_data=true`,
};
export const getTestResults = (params: TestResultFetchOptions) => {
  return httpGetAuthenticated<ListResponse<TestResult>>(dataEndpoints.testResults(), {
    params,
  });
};

export const getTestResult = (testResultId: string) => {
  return httpGetAuthenticated<TestResult>(dataEndpoints.testResult(testResultId), {});
};

export const getTestCases = (params: TestCaseFetchOptions) => {
  return httpGetAuthenticated<ListResponse<TestCase>>(dataEndpoints.testCases(), {
    params,
  });
};

export const addTestCase = async (testCaseData: { name: string; description: string }) => {
  const resp = await httpPostAuthenticated<TestCase>(dataEndpoints.testCases(), testCaseData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });
  return resp;
};

export const editTestCase = async (
  testCaseId: string,
  testData: {
    name?: string;
    description?: string;
    steps?: string;
    preconditions?: string;
    references?: string[];
  }
) => {
  const resp = await httpPatchAuthenticated(dataEndpoints.testCase(testCaseId), testData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });
  return resp;
};

export const deleteTestCase = async (testCaseId: string) => {
  const resp = await httpDeleteAuthenticated(dataEndpoints.testCase(testCaseId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });
  return resp;
};

export const editAsset = async (
  assetId: string,
  assetData: {
    name?: string;
    status?: AssetStatusOptions;
    assignee?: string | null;
    description?: string;
    type?: string;
    recurrence?: number;
    metadata?: any;
  }
) => {
  const resp = await httpPatchAuthenticated(dataEndpoints.asset(assetId), assetData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Asset, assetId] });
  return resp;
};
export const deleteAsset = async (assetId: string) => {
  const resp = await httpDeleteAuthenticated(dataEndpoints.asset(assetId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Asset] });
  return resp;
};

export const editAction = async (
  actionId: string,
  actionData: {
    name: string;
    description?: string;
    action_type: string;
  }
) => {
  const resp = await httpPatchAuthenticated(dataEndpoints.action(actionId), actionData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });
  return resp;
};

export const deleteAction = async (actionId: string) => {
  const resp = await httpDeleteAuthenticated(dataEndpoints.action(actionId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });
  return resp;
};

export const getAssets = (params: AssetFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Asset>>(dataEndpoints.assets(), {
    params,
  });
};

export const addAsset = async (assetData: {
  name: string;
  type: string;
  description: string;
  recurrence?: number;
  status?: AssetStatusOptions;
  metadata?: any;
  assignee?: string | null;
}) => {
  const resp = await httpPostAuthenticated<Asset>(dataEndpoints.assets(), assetData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Asset] });
  return resp;
};

export const getRoles = (params: RoleFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Role>>(dataEndpoints.roles(), {
    params,
  });
};

export const addRole = async (roleData: { name: string; description: string }) => {
  const resp = await httpPostAuthenticated<Role>(dataEndpoints.roles(), roleData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  return resp;
};

export const editRole = async (
  roleId: string,
  roleData: {
    name: string;
    description?: string;
  }
) => {
  const resp = await httpPatchAuthenticated(dataEndpoints.role(roleId), roleData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  return resp;
};

export const deleteRole = async (roleId: string) => {
  const resp = await httpDeleteAuthenticated(dataEndpoints.role(roleId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  return resp;
};

export const getActions = (params: ActionFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Action>>(dataEndpoints.actions(), {
    params,
  });
};

export const addAction = async (actionData: {
  name: string;
  description?: string;
  action_type: string;
}) => {
  const resp = await httpPostAuthenticated<Action>(dataEndpoints.actions(), actionData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });
  return resp;
};

export const addRoleToAction = async (actionId: string, roleId: string) => {
  const resp = await httpPostAuthenticated(dataEndpoints.actionAddRole(actionId), {
    role_id: roleId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });

  return resp;
};

export const removeRoleFromAction = async (actionId: string, roleId: string) => {
  const resp = await httpPostAuthenticated(dataEndpoints.actionRemoveRole(actionId), {
    role_id: roleId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });

  return resp;
};

export const addUserToRole = async (userId: string, roleId: string) => {
  const resp = await httpPostAuthenticated(dataEndpoints.roleAddUser(roleId), { user_id: userId });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });

  return resp;
};

export const removeUserFromRole = async (userId: string, roleId: string) => {
  const resp = await httpPostAuthenticated(dataEndpoints.roleRemoveUser(roleId), {
    user_id: userId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Role] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });

  return resp;
};

//#region [User]
const registerUser = async (params: ICreateUserRequestBody) => {
  return createOrUpdateParser<any>({
    call: () => httpPostPublic(ApiEndpoint.userRegister, params),
    dataConverter: (data) => data,
  });
};

const registerUserInOrganization = async (orgId: string, params: ICreateUserRequestBody) => {
  return createOrUpdateParser<any>({
    call: () =>
      httpPostAuthenticated(ApiEndpoint.organization + "/" + orgId + "/register_user", params),
    dataConverter: (data) => data,
  });
};

const getUser = async (myUsername?: string): Promise<IUser> => {
  return httpGetAuthenticated(ApiEndpoint.userRetrieve, {}).then((response) => {
    const users: any[] = response.data.results;

    if (!myUsername) {
      // TODO: this assumes i am the only user in my org, ideally should be different api
      return users[0];
    }

    return users.find((e) => e.username === myUsername);
  });
};

const getUserByID = async (userID: string) => {
  return httpGetAuthenticated(ApiEndpoint.userRetrieve + "/" + userID, {}).then((response) => {
    const data = response.data;

    return {
      ...data,
      name: data.first_name + " " + data.last_name,
    } as User;
  });
};

const getUsers = async (page: number, page_size: number) => {
  return getPaginatedListMethod({
    endpoint: ApiEndpoint.userRetrieve,
    queryParams: {
      page,
      page_size,
    },
    itemConverter: (raw) => parseUserFromListResult(raw),
  });
};

export const getJoinedUsers = async (page: number, page_size: number) => {
  return getPaginatedListMethod({
    endpoint: ApiEndpoint.userRetrieve,
    queryParams: {
      page,
      page_size,
      joined: true,
    },
    itemConverter: (raw) => parseUserFromListResult(raw),
  });
};

export const getInvitedUsers = async (page: number, page_size: number) => {
  return getPaginatedListMethod({
    endpoint: ApiEndpoint.userRetrieve,
    queryParams: {
      page,
      page_size,
      is_invited: true,
    },
    itemConverter: (raw) => parseUserFromListResult(raw),
  });
};
//#endregion

//#region [Organization]
const getOrganization = async (orgId: string): Promise<Organization | null> => {
  return httpGetAuthenticated(ApiEndpoint.organization, {}).then((response) => {
    const orgs: any[] = response.data.results;
    if (orgs.length == 0) return null;

    const myOrg = orgs.find((org) => org.id === orgId);
    return myOrg || null;
  });
};

const getOrganizations = (params: any) => {
  return httpGetAuthenticated<ListResponse<Organization>>(ApiEndpoint.organization, { params });
};

const getStripeKey = async (): Promise<any | null> => {
  return httpGetAuthenticated(ApiEndpoint.organization + "/config", {}).then((response) => {
    return response;
  });
};

const createPaymentIntention = async (params: { price: Number }): Promise<any | null> => {
  return httpPostAuthenticated(ApiEndpoint.organization + "/payment_intent", params).then(
    (response) => {
      return response;
    }
  );
};

const createOrganization = (params: { name: string; description: string }) => {
  return createOrUpdateParser<Organization>({
    call: () => httpPostAuthenticated(ApiEndpoint.organization, params),
    dataConverter: (data) => data,
  });
};
//#endregion

//#region [Workflow]

const workflowMethods = objectMethods<ICreateWorkflowRequestBody, IUpdateWorkflowRequestBody>({
  baseEndpoint: ApiEndpoint.workflows,
});

//#endregion

//#region [ProcessGraph]

const processGraphMethods = objectMethods<
  ICreateProcessGraphRequestBody,
  IUpdateProcessGraphRequestBody
>({
  baseEndpoint: ApiEndpoint.processGraphs,
});
//#endregion

//#region [ProcessExecutionPlan]

const processExecutionPlanMethods = objectMethods<
  ICreateProcessExecutionPlanRequestBody,
  IGetProcessExecutionPlanListRequestBody
>({
  baseEndpoint: ApiEndpoint.processExecutionPlans,
});
//#endregion

//#region [ProcessNode]
const processNodeMethods = objectMethods<
  ICreateProcessNodeRequestBody,
  IUpdateProcessNodeRequestBody
>({
  baseEndpoint: ApiEndpoint.processNodes,
});

const getProcessNodes = async (processGraphId: string, id?: string[], deleted?: boolean) => {
  return httpGetAuthenticated(ApiEndpoint.processNodes + `?process_graph=${processGraphId}`, {
    params: {
      id,
      deleted,
    },
  }).then((response) => {
    const respData = response.data;
    return (respData.results as any[]).map((raw) => parseProcessNodeResult(raw));
  });
};

const processNodeRelationshipsMethods = objectRelationships({
  baseEndpoint: ApiEndpoint.processNodes,
});
//#endregion

//#region [ProcessEdge]
const processEdgeMethods = objectMethods<
  ICreateProcessEdgeRequestBody,
  IUpdateProcessEdgeRequestBody
>({
  baseEndpoint: ApiEndpoint.processEdges,
});
const createEdge = (params: ICreateProcessEdgeRequestBody) => {
  return httpPostAuthenticated(ApiEndpoint.processEdges, params).then((response) => {
    return parseCreateProcessEdgeResult(response.data);
  });
};
//#endregion

//#region [Roles]
const roleMethods = objectMethods<ICreateRoleRequestBody, IUpdateRoleRequestBody>({
  baseEndpoint: ApiEndpoint.roles,
});

const roleAddUser = ({ roleId, username }: IRoleAddUserRequest) => {
  return httpPostAuthenticated(`${ApiEndpoint.roles}/${roleId}/add_user`, {
    username,
  });
};

const roleRemoveUser = ({ roleId, username }: IRoleRemoveUserRequest) => {
  return httpPostAuthenticated(`${ApiEndpoint.roles}/${roleId}/remove_user`, {
    username,
  });
};

//#endregion

//#region [Actions]
const actionMethods = objectMethods<ICreateActionRequestBody, IUpdateActionRequestBody>({
  baseEndpoint: ApiEndpoint.actions,
});

const actionAddRole = ({ actionId, roleId }: IActionAddRoleRequest) => {
  return httpPostAuthenticated(`${ApiEndpoint.actions}/${actionId}/add_role`, {
    role_id: roleId,
  });
};

const actionRemoveRole = ({ actionId, roleId }: IActionRemoveRoleRequest) => {
  return httpPostAuthenticated(`${ApiEndpoint.actions}/${actionId}/remove_role`, {
    role_id: roleId,
  });
};
//#endregion

//#region [TestCases]
const testCaseMethods = objectMethods<ICreateTestCaseRequestBody, IUpdateTestCaseRequestBody>({
  baseEndpoint: ApiEndpoint.testCases,
});
//#endregion

//#region [Assets]
const assetMethods = objectMethods<ICreateAssetRequestBody, IUpdateAssetRequestBody>({
  baseEndpoint: ApiEndpoint.assets,
});

const getAssetTypes = async (params: { fairo_data?: boolean }) => {
  return httpGetAuthenticated(ApiEndpoint.assetTypes, { params }).then((response) => {
    const respData = response.data;
    return (respData.results as any[]).map((raw) => parseAssetTypeResult(raw));
  });
};
//#endregion

//#region [CustomFields]
const getCustomFields = async () => {
  return httpGetAuthenticated(ApiEndpoint.customFields, {}).then((response) => {
    const respData = response.data;
    return (respData.results as any[]).map((raw) => parseCustomFieldResult(raw));
  });
};

const getCustomField = async (customFieldId: string): Promise<ICustomField> => {
  return httpGetAuthenticated(customFieldEndpoint(customFieldId), {}).then((response) => {
    return parseGetCustomFieldResult(customFieldId, response.data);
  });
};

const createCustomField = (params: ICreateCustomFieldRequestBody) => {
  return createOrUpdateParser<ICustomFieldFormData>({
    call: () => httpPostAuthenticated(ApiEndpoint.customFields, params),
    dataConverter: (data) => data,
  });
};

const updateCustomField = (customFieldId: string, params: IUpdateCustomFieldRequestBody) => {
  return createOrUpdateParser<ICustomFieldFormData>({
    call: () => httpPatchAuthenticated(customFieldEndpoint(customFieldId), params),
    dataConverter: (data) => data,
  });
};
//#endregion

//#region [ObjectCustomFields]

const workflowCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const processGraphCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const processNodeCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const roleCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const actionCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const testCaseCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const assetCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

const processExecutionPlanCustomFieldMethods = objectCustomFieldValueMethods({
  baseEndpoint: ApiEndpoint.customFieldValues,
  objectParamKey: "parent_object_id",
});

//#endregion

//#region [ObjectFileAttachments]
const workflowFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const processGraphFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const processNodeFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const roleFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const actionFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const testCaseFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

const assetFileMethods = objectFileAttachmentMethods({
  baseEndpoint: ApiEndpoint.files,
  objectParamKey: "parent_object_id",
});

//#endregion

export {
  actionAddRole,
  actionCustomFieldMethods,
  actionFileMethods,
  actionMethods,
  actionRemoveRole,
  assetCustomFieldMethods,
  assetFileMethods,
  assetMethods,
  createCustomField,
  createOrganization,
  createPaymentIntention,
  getAssetTypes,
  getCustomField,
  getCustomFields,
  getOrganization,
  getOrganizations,
  getProcessNodes,
  getStripeKey,
  getUser,
  getUserByID,
  getUsers,
  processEdgeMethods,
  processExecutionPlanCustomFieldMethods,
  processExecutionPlanMethods,
  processGraphCustomFieldMethods,
  processGraphFileMethods,
  processGraphMethods,
  processNodeCustomFieldMethods,
  processNodeFileMethods,
  processNodeMethods,
  processNodeRelationshipsMethods,
  registerUser,
  registerUserInOrganization,
  roleAddUser,
  roleCustomFieldMethods,
  roleFileMethods,
  roleMethods,
  roleRemoveUser,
  testCaseCustomFieldMethods,
  testCaseFileMethods,
  testCaseMethods,
  updateCustomField,
  workflowCustomFieldMethods,
  workflowFileMethods,
  workflowMethods,
};
