import { Box, Typography, useTheme } from "@mui/material";
import { CustomFieldValue } from "../../models/types";
import { DisplayReadOnlyCustomField } from "../../organisms/DisplayReadOnlyCustomField";

type DisplayCustomFieldValuesProps = {
  customFields?: CustomFieldValue[];
};
export const DisplayCustomFieldValues = ({ customFields }: DisplayCustomFieldValuesProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      {customFields &&
        customFields?.map((customField, index) => (
          <Box width="100%" display="flex" flexDirection="column" gap="5px">
            <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
              {customField?.field_name}
            </Typography>
            <DisplayReadOnlyCustomField
              field_type={customField.field_type}
              value={customField.value}
              disabled={true}
            />
          </Box>
        ))}
    </Box>
  );
};
