import { IAMPolicy } from "../models/types";
import { JSONView } from "../molecules/JSONView";

type UserPermissionsJsonViewProps = {
  permissions: IAMPolicy[];
};

export const UserPermissionJsonView = ({ permissions }: UserPermissionsJsonViewProps) => {
  return <JSONView value={JSON.stringify(permissions)} />;
};
