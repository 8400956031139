import { NavigateFunction, useNavigate } from "react-router-dom";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";
import { useQuery } from "react-query";
import { fetchComplianceElements } from "../hooks/useComplianceElements";

const LIBRARY_NAVIGATOR_KEY = "library-navigator";

export const useLibraryPathState = (locationState: any, path: string) => {
  const elementId = locationState?.actionId;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    elementId: string;
  }>(
    [LIBRARY_NAVIGATOR_KEY, path],
    async () => {
      if (elementId != null) {
        return { elementId };
      }
      // the patter is /compliance/library/fairo/anything
      const isFairoPath = !!path.match(/\/oversight\/library\/fairo\/.*/g);

      const externalId = lastPath.replace("-", " ");

      const list = await fetchComplianceElements({
        external_id: [externalId],
        fairo_data: isFairoPath,
      });
      const obj = list.results.find((o) => o.external_id === externalId);
      if (!obj) {
        navigate("/oversight/library");
        return { elementId: null };
      }

      return { elementId: obj.id };
    },
    { suspense: true }
  );
};

const navigateToElement = (params: {
  navigate: NavigateFunction;
  elementId: string;
  external_id: string;
  fairoData?: boolean;
  returnLink?: boolean;
}) => {
  const { elementId, external_id, navigate, fairoData, returnLink = false } = params;
  if (returnLink) {
    return `/oversight/library/${fairoData ? "fairo/" : ""}${external_id}`;
  }
  navigate(`/oversight/library/${fairoData ? "fairo/" : ""}${external_id}`, {
    state: {
      elementId,
    },
  });
};

export { navigateToElement };
