import { StatusBadge } from "../../../../../atoms/StatusBadge";
import UserTag from "../../../../../components/UserTag";
import { Asset, BaseFilterOptions, BaseFilters, FilterOption } from "../../../../../models/types";
import { IColumnVisibility } from "../../../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../../../molecules/TableTextWithEllipsis";

export const elementDetailsDocumentsListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Asset", value: "asset_num" },
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const elementDetailsDocumentsColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "status",
    headerName: "Status",
    columnMinWidth: 170,
    columnMaxWidth: 170,
    visible: true,
    renderer: (asset) => <StatusBadge status={asset.test_status as any} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMinWidth: 80,
    columnMaxWidth: 80,
    renderer: (asset) =>
      asset.assignee_name ? <UserTag showNameInitials hideName name={asset.assignee_name} /> : "",
  },
];
