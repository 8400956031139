import {
  PotentialValueOption,
  RiskImpactOption,
  RiskLikelihoodOption,
  RiskRegistry,
} from "../models/types";

export const getRiskScore = (riskLikelihood: number, riskImpact: number) => {
  return riskLikelihood * riskImpact;
};

export const getGeneralRiskScore = (risk: RiskRegistry): number | null => {
  // return avg of scores
  if (
    !risk.inherent_risk_likelihood ||
    !risk.inherent_risk_impact ||
    !risk.residual_risk_likelihood ||
    !risk.residual_risk_impact
  )
    return null;
  const scores = [
    getRiskScore(risk.inherent_risk_likelihood, risk.inherent_risk_impact),
    getRiskScore(risk.residual_risk_likelihood, risk.residual_risk_impact),
  ];

  return Math.round(scores.reduce((a, b) => a + b, 0) / scores.length);
};

export const RiskLikelihoodOptions: RiskLikelihoodOption[] = [
  { score: 1, name: "Very Unlikely" },
  { score: 2, name: "Unlikely" },
  { score: 3, name: "Somewhat Likely" },
  { score: 4, name: "Likely" },
  { score: 5, name: "Very Likely" },
];

export const RiskImpactOptions: RiskImpactOption[] = [
  { score: 1, name: "Very Low" },
  { score: 2, name: "Low" },
  { score: 3, name: "Medium" },
  { score: 4, name: "High" },
  { score: 5, name: "Very High" },
];

export const PotentialValueOptions: PotentialValueOption[] = [
  { score: 1, name: "Minimal Value" },
  { score: 2, name: "Low Value" },
  { score: 3, name: "Modest Value" },
  { score: 4, name: "Fair Value" },
  { score: 5, name: "Moderate Value" },
  { score: 6, name: "Good Value" },
  { score: 7, name: "High Value" },
  { score: 8, name: "Very High Value" },
  { score: 9, name: "Exceptional Value" },
  { score: 10, name: "Maximum Value" },
];
