import { ComplianceElement, ComplianceElementFetchOptions, ObjectSummary } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const complianceElementsEndpoints = {
  complianceElements: () => `/compliance_elements`,
  complianceElementDetails: () => `/compliance_element_data`,
  complianceElement: (complianceElementId: string) => `/compliance_elements/${complianceElementId}`,
  complianceElementDetail: (detailId: string) => `/compliance_element_data/${detailId}`,
  assignOwner: () => `/compliance_elements/copy?fairo_data=true`,
  summary: (elementId: string) => `/compliance_elements/${elementId}/summary`,
};

export const getComplianceElements = (params: any) => {
  return httpGetAuthenticated<ListResponse<ComplianceElement>>(
    complianceElementsEndpoints.complianceElements(),
    {
      params,
    }
  );
};

export const getComplianceElement = (
  complianceElementId: string,
  params: { fairo_data: boolean }
) => {
  return httpGetAuthenticated<ComplianceElement>(
    complianceElementsEndpoints.complianceElement(complianceElementId),
    {
      params,
    }
  );
};

export const createComplianceElement = async (payload: {
  name: string;
  external_id: string;
  element_type: string;
  description?: string;
  framework?: string | null;
  assignee?: string | null;
  file_attachments?: string[];
  custom_fields?: string[];
}) => {
  const { data } = await httpPostAuthenticated<ComplianceElement>(
    complianceElementsEndpoints.complianceElements(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ComplianceElement] });
  return data;
};

export const copyFairoElement = (data: { element_id: string }) => {
  return httpPostAuthenticated<ComplianceElement>(complianceElementsEndpoints.assignOwner(), data);
};

export const editComplianceElement = async (
  elementId: string,
  payload: {
    assignee?: string | null;
    due_date?: string | null;
    name?: string;
    description?: string;
    element_type?: string | null;
    framework?: string | null;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    complianceElementsEndpoints.complianceElement(elementId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ComplianceElement, elementId] });
  return data;
};

export const getComplianceElementSummary = (
  elementId: string,
  params: ComplianceElementFetchOptions
) => {
  return httpGetAuthenticated<ObjectSummary>(complianceElementsEndpoints.summary(elementId), {
    params,
  });
};

export const deleteComplianceElement = async (elementId: string) => {
  const resp = await httpDeleteAuthenticated(
    complianceElementsEndpoints.complianceElement(elementId)
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ComplianceElement] });
  return resp;
};
