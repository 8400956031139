import { Box, Typography, Select, MenuItem, useTheme } from "@mui/material";
import NavbarArrow from "../atoms/navbar/Arrow";
import { getCursor } from "../utilities/UIHelper";

type TablePaginationProps = {
  /** The current page number. Starts from 1 */
  page: number;
  /** Number of rows to display per page */
  rowsPerPage: number;
  /** Total number of rows present */
  totalCount: number | undefined;
  /** Callback to change the number of rows per page */
  setRowsPerPage: (value: number) => void;
  /** Callback to change the page number */
  setPage: (newPageNumber: number) => void;
  /** Show the number of rows menu */
  showRowsMenu?: boolean;
  /** Next cursor */
  nextCursor?: string | null;
  /** Previous cursor */
  previousCursor?: string | null;
  /** Callback to change the cursor */
  setCursor?: (cursor: string) => void;
  /** Rows Title */
  rowsTitle?: string;
  /** Rows Menu */
  rowsMenu?: number[];
};

export const TablePagination = ({
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  totalCount,
  showRowsMenu = true,
  nextCursor,
  previousCursor,
  setCursor,
  rowsTitle = "Rows",
  rowsMenu = [5, 10, 20],
}: TablePaginationProps) => {
  const totalPages = Math.ceil((totalCount ?? 0) / rowsPerPage);
  const theme = useTheme();
  const changeRowsPerPage = (newRowsPerPage: number) => {
    const newTotalPages = Math.ceil((totalCount ?? 0) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    // Prevent the page number going out of bounds
    if (page > newTotalPages) {
      setPage(Math.max(1, newTotalPages));
    }
  };

  // hide self when there are no pages
  if (totalPages === 0 && typeof totalCount !== "undefined") {
    return null;
  }

  const totalCountLabel = (totalCount ?? 0) > 1 ? `/ ${totalCount} results` : ` / 1 result`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {totalCount !== undefined && (
        <>
          {showRowsMenu && (
            <Select
              value={rowsPerPage}
              sx={{ padding: 0, height: "35px", borderRadius: "3px" }}
              onChange={(event) => changeRowsPerPage(event.target.value as number)}
            >
              {rowsMenu.map((value) => (
                <MenuItem value={value}>{`${rowsTitle}: ${value}`}</MenuItem>
              ))}
            </Select>
          )}
          <Typography variant="body2" sx={{ paddingX: "10px" }} color={theme.palette.custom.gray}>
            {page} of {totalPages}
            {totalCountLabel}
          </Typography>
        </>
      )}
      <Box gap="10px" sx={{ display: "flex", flexDirection: "row" }}>
        <NavbarArrow
          disabled={previousCursor === null}
          direction="left"
          onClick={() => {
            if (totalCount === undefined) {
              setCursor?.(getCursor(previousCursor ?? "") ?? "");
              return;
            }
            setPage(page - 1);
          }}
        />
        <NavbarArrow
          disabled={nextCursor === null}
          direction="right"
          onClick={() => {
            if (totalCount === undefined) {
              setCursor?.(getCursor(nextCursor ?? "") ?? "");
              return;
            }
            setPage(page + 1);
          }}
        />
      </Box>
    </Box>
  );
};
