import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchRoles } from "../hooks/useRoles";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const ROLE_NAVIGATOR_KEY = "role-navigator";
export const useRolePathState = (locationState: any, path: string) => {
  const roleId = locationState?.roleId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    roleId: string;
    edit: boolean;
  }>(
    [ROLE_NAVIGATOR_KEY, path],
    async () => {
      if (roleId != null) {
        return { roleId, edit };
      }

      const list = await fetchRoles({ role_num: [number] });
      const obj = list.results.find((o) => o.role_num === Number(number));
      if (!obj) {
        navigate("/roles");
        return { roleId: null, edit };
      }

      return { roleId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToRole = (params: {
  navigate: NavigateFunction;
  roleId: string;
  roleNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { roleId, roleNumber, navigate, edit, returnLink = false } = params;
  if (returnLink) {
    return `/organization/roles/role-${roleNumber}`;
  }
  navigate(`/organization/roles/role-${roleNumber}`, {
    state: {
      roleId,
      edit,
    },
  });
};

export { navigateToRole };
