import { useNavigate } from "react-router-dom";
import { InternalMenuItemOption } from "../menu/InternalMenuItemOption";

export const SubMenuItemDisplay = ({
  label,
  route,
  onClose,
}: {
  label: string;
  route: string;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <InternalMenuItemOption
      typographyVariant="navbar"
      name={label}
      onClick={() => {
        onClose();
        navigate(route);
      }}
      hasSubsections={false}
      expanded={false}
      selected={false}
    />
  );
};
