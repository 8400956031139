import { Box, useTheme } from "@mui/material";
import React from "react";
import NavbarBox from "../atoms/navbar/Box";
import { ScreenSmallerThen } from "../utilities/UIHelper";

interface IExpandableBoxProps {
  children: React.ReactElement;
  expanded: boolean;
  onExpand: () => void;
}

const ExpandableBox = ({ children, expanded, onExpand }: IExpandableBoxProps) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        zIndex: 1300,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
        overflowX: "visible",
      }}
    >
      <NavbarBox expanded={expanded}>{children}</NavbarBox>
    </Box>
  );
};

export default ExpandableBox;
