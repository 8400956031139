import { Button } from "@mui/material";
import { APIFetchOptions } from "../../models/types";

type ClearAllFiltersProps<P extends APIFetchOptions> = {
  params: P;
  setParams: (params: P | {}) => void;
};

export const ClearAllFilters = <P extends APIFetchOptions>({
  params,
  setParams,
}: ClearAllFiltersProps<P>) => {
  const hasParams = params && Object.keys(params).length > 0;
  return hasParams ? (
    <Button onClick={() => setParams({})} variant="text">
      Clear
    </Button>
  ) : null;
};
