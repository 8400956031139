import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { DefaultBadge, getPotentialValueScoreColor, RiskScoreBadge } from "../../atoms/StatusBadge";
import { StringCustomField } from "../../components/CustomFieldForm";
import DeleteIconButton from "../../components/DeleteIconButton";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { Table } from "../../components/Table";
import { openSnackbar } from "../../context/SnackbarContext";
import {
  PotentialHarmCategory,
  PotentialHarmDisproportionalityOption,
  PotentialHarmImpactOption,
  PotentialHarmObj,
  PotentialHarmScopeOption,
  PotentialHarmSeverityOption,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { EmptyTable } from "../../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import {
  PotentialHarmCategoryOptions,
  PotentialHarmDisproportionalityOptions,
  PotentialHarmImpactOptions,
  PotentialHarmScopeOptions,
  PotentialHarmSeverityOptions,
} from "../../utilities/PotentialHarm";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import { PotentialHarmCustomFieldModal } from "../modals/custom-fields/PotentialHarmCustomFieldModal";

type PotentialHarmFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  readOnly?: boolean;
};

type PotentialHarmListFieldProps = {
  value: string[] | null;
  onChange: (newValue: string[] | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  showAddButton: boolean;
  modalTitle: string;
  tableDescription?: React.ReactNode;
};

export const PotentialHarmField = (props: PotentialHarmFieldProps) => {
  const { value, onChange, required, valueError, errorMessage, readOnly = false } = props;
  const theme = useTheme();

  const handleChangeName = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, name: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeDescription = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, description: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeCategory = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, category: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeSeverity = (newValue: number) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, severity: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeScope = (newValue: number) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, scope: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeDisproportionality = (newValue: number) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, disproportionality: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeImpact = (newValue: number) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, impact: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  useEffect(() => {
    const oldValue = JSON.parse(value || "{}");

    const severity = oldValue.severity || 0;
    const scope = oldValue.scope || 0;
    const disproportionality = oldValue.disproportionality || 0;
    const impact = oldValue.impact || 0;

    const score = (severity + scope + disproportionality) * impact;

    const newValueJson = { ...oldValue, score };
    onChange(JSON.stringify(newValueJson));
  }, [value]);

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} textAlign="start">
          Name
        </Typography>
        {readOnly ? (
          <TableTextWithEllipsis value={getValue("name")} />
        ) : (
          <StringCustomField
            required={required}
            value={getValue("name")}
            onChange={handleChangeName}
            error={valueError}
            errorMessage={errorMessage}
          />
        )}
      </Box>
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
        Description
      </Typography>
      {readOnly ? (
        <Typography variant="body2">{getValue("description")}</Typography>
      ) : (
        <StringCustomField
          rows={3}
          required={required}
          value={getValue("description")}
          onChange={handleChangeDescription}
          error={valueError}
          errorMessage={errorMessage}
        />
      )}
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
          Category
        </Typography>
        {readOnly ? (
          <DefaultBadge value={getValue("category")} />
        ) : (
          <SearchableDropdown<PotentialHarmCategory>
            value={getValue("category")}
            onChange={(option) => handleChangeCategory(option ?? "")}
            getOptions={(search: string) =>
              PotentialHarmCategoryOptions.filter((option) =>
                option.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(a, b) => a === b}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
          Severity
        </Typography>
        {readOnly ? (
          <RiskScoreBadge
            bgcolor={getPotentialValueScoreColor(getValue("severity"), theme)}
            size="small"
            score={getValue("severity")}
          />
        ) : (
          <SearchableDropdown<PotentialHarmSeverityOption>
            value={getValue("severity")}
            onChange={(option) => handleChangeSeverity(option ? option.score : 0)}
            getOptions={(search: string) =>
              PotentialHarmSeverityOptions.filter((option) =>
                option.name.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => option?.name ?? ""}
            isOptionEqualToValue={(a, b) => a === b}
            startAdornment={
              getValue("severity") ? (
                <Box display="flex" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(getValue("severity"), theme)}
                    size="small"
                    score={getValue("severity")}
                  />{" "}
                </Box>
              ) : null
            }
            renderOption={(props, option) => {
              return (
                <Box display="flex" width="100%" gap="5px" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(option.score, theme)}
                    size="small"
                    score={option.score}
                  />
                  <Typography variant="body2">{option.name}</Typography>
                </Box>
              );
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
          Scope
        </Typography>
        {readOnly ? (
          <RiskScoreBadge
            bgcolor={getPotentialValueScoreColor(getValue("scope"), theme)}
            size="small"
            score={getValue("scope")}
          />
        ) : (
          <SearchableDropdown<PotentialHarmScopeOption>
            value={getValue("scope")}
            onChange={(option) => handleChangeScope(option ? option.score : 0)}
            getOptions={(search: string) =>
              PotentialHarmScopeOptions.filter((option) =>
                option.name.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => option?.name ?? ""}
            isOptionEqualToValue={(a, b) => a === b}
            startAdornment={
              getValue("scope") ? (
                <Box display="flex" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(getValue("scope"), theme)}
                    size="small"
                    score={getValue("scope")}
                  />{" "}
                </Box>
              ) : null
            }
            renderOption={(props, option) => {
              return (
                <Box display="flex" width="100%" gap="5px" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(option.score, theme)}
                    size="small"
                    score={option.score}
                  />
                  <Typography variant="body2">{option.name}</Typography>
                </Box>
              );
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
          Disproportionality
        </Typography>
        {readOnly ? (
          <RiskScoreBadge
            bgcolor={getPotentialValueScoreColor(getValue("disproportionality"), theme)}
            size="small"
            score={getValue("disproportionality")}
          />
        ) : (
          <SearchableDropdown<PotentialHarmDisproportionalityOption>
            value={getValue("disproportionality")}
            onChange={(option) => handleChangeDisproportionality(option ? option.score : 0)}
            getOptions={(search: string) =>
              PotentialHarmDisproportionalityOptions.filter((option) =>
                option.name.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => option?.name ?? ""}
            isOptionEqualToValue={(a, b) => a === b}
            startAdornment={
              getValue("disproportionality") ? (
                <Box display="flex" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(getValue("disproportionality"), theme)}
                    size="small"
                    score={getValue("disproportionality")}
                  />{" "}
                </Box>
              ) : null
            }
            renderOption={(props, option) => {
              return (
                <Box display="flex" width="100%" gap="5px" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(option.score, theme)}
                    size="small"
                    score={option.score}
                  />
                  <Typography variant="body2">{option.name}</Typography>
                </Box>
              );
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={readOnly ? "row" : "column"}
        gap="10px"
        alignItems={readOnly ? "center" : "start"}
      >
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography} minWidth="122px">
          Impact
        </Typography>
        {readOnly ? (
          <RiskScoreBadge
            bgcolor={getPotentialValueScoreColor(getValue("impact"), theme)}
            size="small"
            score={getValue("impact")}
          />
        ) : (
          <SearchableDropdown<PotentialHarmImpactOption>
            value={getValue("impact")}
            onChange={(option) => handleChangeImpact(option ? option.score : 0)}
            getOptions={(search: string) =>
              PotentialHarmImpactOptions.filter((option) =>
                option.name.toLowerCase().includes(search.toLowerCase())
              )
            }
            getOptionLabel={(option) => option?.name ?? ""}
            isOptionEqualToValue={(a, b) => a === b}
            startAdornment={
              getValue("impact") ? (
                <Box display="flex" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(getValue("impact"), theme)}
                    size="small"
                    score={getValue("impact")}
                  />{" "}
                </Box>
              ) : null
            }
            renderOption={(props, option) => {
              return (
                <Box display="flex" width="100%" gap="5px" alignItems="center">
                  <RiskScoreBadge
                    bgcolor={getPotentialValueScoreColor(option.score, theme)}
                    size="small"
                    score={option.score}
                  />
                  <Typography variant="body2">{option.name}</Typography>
                </Box>
              );
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export const PotentialHarmListField = (props: PotentialHarmListFieldProps) => {
  const {
    value,
    onChange,
    required,
    valueError,
    errorMessage,
    showAddButton,
    modalTitle,
    tableDescription,
  } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] = useState<PotentialHarmObj | null>(null);
  const theme = useTheme();
  const columnsVisibility: IColumnVisibility<PotentialHarmObj>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      renderer: (harm) => (
        <TableTextWithEllipsis
          value={harm.name}
          onClick={() => {
            setSelectedForEdit(harm);
            setModalOpen(true);
          }}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      visible: true,
      renderer: (harm) => <DefaultBadge value={harm.category} />,
    },
    {
      field: "score",
      headerName: "Score",
      visible: true,
      renderer: (harm) => <DefaultBadge value={harm.score.toString()} />,
    },
    {
      field: "last_updated_on",
      headerName: "Last Updated",
      visible: true,
      renderer: (harm) => defaultDateDisplay(harm.last_updated_on),
    },
    {
      field: "id",
      headerName: "",
      visible: true,
      renderer: (harm) => (
        <Box display="flex" justifyContent="center">
          <DeleteIconButton
            deleteConfirmationDialogTitle="Remove Potential Harm"
            deleteConfirmationButtonDescription="Remove"
            deleteConfirmationDialogDescription={`Are you sure you want to remove ${harm.name}?`}
            onDeleteConfirm={() => {
              const newValueJson = [...(value || [])];
              newValueJson.splice(parseInt(harm.id), 1);
              onChange(newValueJson);
            }}
            filledButton={false}
          />
        </Box>
      ),
    },
  ];

  const onAdd = (newValue: string) => {
    try {
      const isUpdate = selectedForEdit !== null;
      if (isUpdate) {
        const newValueJson = [...(value || [])];
        newValueJson[parseInt(selectedForEdit.id)] = newValue;
        onChange(newValueJson);
      } else {
        const newValueJson = [...(value || []), newValue];
        onChange(newValueJson);
      }
    } catch (error) {
      openSnackbar("Failed to set potential harms", "error");
    } finally {
      setSelectedForEdit(null);
    }
  };

  const rows = value?.map((harm, index) => {
    const parsedItem = JSON.parse(harm);
    return {
      id: index.toString(),
      name: parsedItem?.name,
      description: parsedItem?.description,
      category: parsedItem?.category,
      severity: parsedItem?.severity,
      scope: parsedItem?.scope,
      disproportionality: parsedItem?.disproportionality,
      impact: parsedItem?.impact,
      score: parsedItem?.score,
      last_updated_on: parsedItem?.last_updated_on,
    };
  });

  return (
    <Box display="flex" gap="10px" width="100%" maxWidth="100%" flexDirection="column">
      {showAddButton && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {tableDescription}
          <Button onClick={() => setModalOpen(true)} variant="contained">
            Add
          </Button>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="100%"
        borderRadius="6px"
        border="1px solid"
        borderTop={0}
        overflow="auto"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        <Table
          borderTopRadius="6px"
          smallEmptyHeight={true}
          columnsVisibility={columnsVisibility}
          isLoading={false}
          rows={rows || []}
          emptyTableComponent={
            <EmptyTable
              label="No options added yet"
              description="Add an option"
              action={() => {
                setModalOpen(true);
              }}
            />
          }
        />
      </Box>
      <PotentialHarmCustomFieldModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedForEdit(null);
        }}
        onAdd={onAdd}
        selectedForEdit={selectedForEdit}
        title={modalTitle}
      />
    </Box>
  );
};

export const PotentialHarmListFieldReadOnly = (props: {
  value: string[];
  disabled: boolean;
  showLastUpdatedOn?: boolean;
}) => {
  const { value, disabled, showLastUpdatedOn = true } = props;
  const theme = useTheme();
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] = useState<PotentialHarmObj | null>(null);

  const columnsVisibility: IColumnVisibility<PotentialHarmObj>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      renderer: (harm) => <TableTextWithEllipsis value={harm.name} />,
    },
    {
      field: "category",
      headerName: "Category",
      visible: true,
      renderer: (harm) => <DefaultBadge value={harm.category} />,
    },
    {
      field: "score",
      headerName: "Score",
      visible: true,
      renderer: (harm) => <DefaultBadge value={harm.score.toString()} />,
    },
    ...(showLastUpdatedOn
      ? [
          {
            field: "last_updated_on",
            headerName: "Last Updated",
            visible: true,
            renderer: (harm) => defaultDateDisplay(harm.last_updated_on),
          } as IColumnVisibility<PotentialHarmObj>,
        ]
      : []),
  ];

  const rows = value
    ? value?.map((harm, index) => {
        const parsedItem = JSON.parse(harm);
        return {
          id: index.toString(),
          name: parsedItem?.name,
          description: parsedItem?.description,
          category: parsedItem?.category,
          severity: parsedItem?.severity,
          scope: parsedItem?.scope,
          disproportionality: parsedItem?.disproportionality,
          impact: parsedItem?.impact,
          score: parsedItem?.score,
          last_updated_on: parsedItem?.last_updated_on,
        };
      })
    : [];

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="100%"
      borderRadius="6px"
      border="1px solid"
      borderTop={0}
      overflow="auto"
      borderColor={theme.palette.custom.secondaryBorder}
      sx={{
        ...(disabled && disabledTypographyEffect),
      }}
    >
      <Table
        borderTopRadius="6px"
        smallEmptyHeight={true}
        columnsVisibility={columnsVisibility}
        isLoading={false}
        rows={rows || []}
        emptyTableComponent={<EmptyTable label="No options added" />}
        rowOnClick={(row) => {
          setSelectedForEdit(row);
          setModalOpen(true);
        }}
      />
      {selectedForEdit && (
        <PotentialHarmCustomFieldModal
          open={modalOpen}
          onClose={() => {
            setSelectedForEdit(null);
            setModalOpen(false);
          }}
          onAdd={() => {}}
          selectedForEdit={selectedForEdit}
          title="Potential Harm"
          readOnly={true}
        />
      )}
    </Box>
  );
};
