import { Stack } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { Organization } from "../../../models/types";
import { defaultDateDisplay } from "../../../utilities/UIHelper";
import { LoadingButton } from "@mui/lab";
import { APITable } from "../../APITable";
import queryClient, { useOrganizationsGet } from "../../../state/QueryStore";
import UserTag from "../../../components/UserTag";
import { setCookie } from "../../../context/CookiesContext";
import { refreshAccessToken } from "../../../services/AuthService";

type SeeAllOrganizationsProps = {
  open: boolean;
  onClose: () => void;
};

export const SeeAllOrganizations = (props: SeeAllOrganizationsProps) => {
  const { open, onClose } = props;

  const onSwitchOrganization = async (orgId: string) => {
    setCookie("organization_id", orgId);
    const tokens = await refreshAccessToken();
    queryClient.clear();
    // window.location.href = "/organization";
    if (tokens) {
      window.location.href = "/organization";
    }
  };

  const columnsVisibility: IColumnVisibility<Organization>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "admins",
      headerName: "Admins",
      visible: true,
      renderer: (org) =>
        org.admins?.map((admin) => <UserTag showNameInitials hideName name={admin.name} />),
    },
    {
      field: "members_count",
      headerName: "Members",
      visible: true,
    },
    {
      field: "last_activity",
      headerName: "Last Activity",
      visible: true,
      renderer: (org) => defaultDateDisplay(org.last_activity ?? ""),
    },
    {
      field: "id",
      headerName: "Action",
      visible: true,
      renderer: (org) => (
        <LoadingButton variant="contained" onClick={() => onSwitchOrganization(org.id)}>
          View
        </LoadingButton>
      ),
    },
  ];

  return (
    <SmallModal open={open} onClose={onClose} title="Organizations" size="large">
      <Stack gap="10px">
        <APITable
          useGetData={useOrganizationsGet}
          columnsVisibility={columnsVisibility}
          hideColumnVisibility
          queryParams={{}}
          tableSize="small"
        />
      </Stack>
    </SmallModal>
  );
};
