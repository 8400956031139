import { Button, Typography } from "@mui/material";
import { useJiraIntegration } from "../hooks/useJiraIntegration";
import { LinkedItem } from "../models/IWorkflow";
import { APIFetchOptions, APIGetListHook, ExecutionTask } from "../models/types";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import { stripHTMLTags } from "../utilities/common";
import { APITable } from "./APITable";
import { BaseTaskModal } from "./modals/tasks/BaseTaskModal";

export const JiraTaskSummary = (props: {
  task: ExecutionTask;
  isUpdate: boolean;
  setIsUpdate: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const { task, isUpdate, setIsUpdate, disabled } = props;
  const { data: jiraIntegration } = useJiraIntegration();
  const columnsVisibility: IColumnVisibility<LinkedItem>[] = [
    {
      field: "external_id",
      headerName: "ID",
      visible: true,
      renderer: (ji) =>
        !!jiraIntegration ? (
          <Button
            variant="text"
            onClick={() => {
              window.open(`${jiraIntegration.site_url}/browse/${ji.custom_fields?.key}`, "_blank");
            }}
            disabled={disabled}
          >
            {ji.custom_fields?.key}
          </Button>
        ) : (
          <Typography>{ji.custom_fields?.key}</Typography>
        ),
    },
    {
      field: "custom_fields",
      headerName: "Summary",
      visible: true,
      renderer: (ji) => stripHTMLTags(ji.custom_fields?.summary),
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
  ];
  const useJiraItems: APIGetListHook<LinkedItem, APIFetchOptions> = (params) => {
    const { page = 1, page_size: _pageSize = 5 } = params;
    const items = task.linked_items;
    const pageData = items.slice((page - 1) * _pageSize, page * _pageSize);
    return {
      isLoading: false,
      data: {
        count: items.length,
        next: null,
        previous: null,
        results: pageData,
      },
    };
  };

  return (
    <>
      <BaseTaskModal
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        onClose={() => {}}
        taskId={task.id}
      />
      {!isUpdate && (
        <APITable
          useGetData={useJiraItems}
          queryParams={{}}
          columnsVisibility={columnsVisibility}
          tableSize="small"
          smallEmptyHeight={true}
          emptyTableComponent={
            <EmptyTable
              variant="empty-box"
              label="No External Tasks"
              description="Currently there are any external tasks connected"
            />
          }
        />
      )}
    </>
  );
};
