import { Box, Typography, useTheme } from "@mui/material";
import { useVendor } from "../../../hooks/useVendors";
import { RectangularLoader } from "../../../molecules/skeleton-loader/RectangularLoader";
import { ShowMore } from "../../../molecules/ShowMore";
import { Vendor } from "../../../models/types";

type VendorReadOnlyProps = {
  vendor: Vendor;
};

export const VendorReadOnly = (props: VendorReadOnlyProps) => {
  const { vendor } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Vendor General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Showing vendor name, vendor description and main contact name (View only mode).
      </Typography>
      <Box display="flex" gap="10px" alignItems="center">
        <Typography variant="h3" color={textColor}>
          Vendor Name
        </Typography>
        <Typography variant="body2">{vendor?.name}</Typography>
      </Box>
      <Box display="flex" gap="10px" alignItems="center">
        <Typography variant="h3" color={textColor}>
          Main Contact Name
        </Typography>
        <Typography variant="body2">{vendor?.main_contact_name}</Typography>
      </Box>
      {vendor?.description && (
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="h3" color={textColor}>
            Description
          </Typography>
          <ShowMore description={vendor?.description ?? ""} maxChar={250} richText={true} />
        </Box>
      )}
    </Box>
  );
};
