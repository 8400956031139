import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { SmallModal } from "../../../components/SmallModal";
import { PotentialHarmField } from "../../custom-fields/PotentialHarms";

type PotentialHarmCustomFieldModalProps = {
  open: boolean;
  onClose: () => void;
  onAdd: (value: string) => void;
  selectedForEdit: {
    id: string;
    name: string;
    description: string;
    category: string;
    severity: number;
    scope: number;
    disproportionality: number;
    impact: number;
    score: number;
    last_updated_on: string;
  } | null;
  title: string;
  readOnly?: boolean;
};

export const PotentialHarmCustomFieldModal = (props: PotentialHarmCustomFieldModalProps) => {
  const { open, onClose, onAdd, selectedForEdit, title, readOnly = false } = props;
  const [value, setValue] = useState<string | null>(
    selectedForEdit ? JSON.stringify(selectedForEdit) : null
  );

  useEffect(() => {
    if (selectedForEdit) {
      setValue(JSON.stringify(selectedForEdit));
    }
  }, [selectedForEdit]);

  const handleChangeUpdatedOn = () => {
    const oldValue = JSON.parse(value || "{}");
    const newValue = new Date().toISOString();
    const newValueJson = { ...oldValue, last_updated_on: newValue };
    setValue(JSON.stringify(newValueJson));
    onAdd(JSON.stringify(newValueJson) || "");
  };

  const handleClose = () => {
    setValue(null);
    onClose();
  };

  return (
    <SmallModal
      open={open}
      onClose={handleClose}
      title={`${selectedForEdit ? "Edit" : "Add"} ${title}`}
      size="medium"
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <PotentialHarmField
          value={value}
          onChange={setValue}
          required
          valueError={false}
          errorMessage=""
          readOnly={readOnly}
        />
        {!readOnly && (
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleChangeUpdatedOn();
                handleClose();
              }}
            >
              {selectedForEdit ? "Update" : "Add"}
            </Button>
          </Box>
        )}
      </Box>
    </SmallModal>
  );
};
