import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

type TableTextWithEllipsisProps = {
  value: string;
  onClick?: () => void;
  expanded?: boolean;
  hideTooltip?: boolean;
  tootipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  fullWidth?: boolean;
  color?: string | null;
  variant?: TypographyProps["variant"];
  icon?: React.ReactNode;
  maxChars?: number;
  fontWeight?: number;
};

const TableTextWithEllipsis = (props: TableTextWithEllipsisProps) => {
  const {
    value,
    onClick,
    expanded = false,
    hideTooltip,
    tootipPlacement = "bottom",
    color,
    fullWidth = true,
    variant,
    icon,
    fontWeight,
  } = props;
  const hasLink = !!onClick;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const returnValue = () => (
    <Typography
      color={color ? color : theme.palette.custom.secondaryTypography}
      textOverflow="ellipsis"
      display={expanded ? "inline-block" : "flex"}
      gap="5px"
      alignItems="center"
      whiteSpace={expanded ? "pre-line" : "nowrap"}
      overflow="hidden"
      variant={variant ? variant : "body2"}
      sx={{
        overflowWrap: expanded ? "break-word" : "nowrap",
        ...(expanded && {
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",
        }),
        ...(fontWeight && { fontWeight }),
        "&:hover": {
          textDecoration: hasLink ? "underline" : "none",
          cursor: hasLink ? "pointer" : "unset",
        },
      }}
    >
      {icon}
      {value}
    </Typography>
  );

  return (
    <Box
      width={fullWidth ? "100%" : "unset"}
      maxWidth={fullWidth ? "100%" : "unset"}
      overflow="hidden"
      textOverflow="ellipsis"
      onClick={onClick}
      sx={{
        cursor: hasLink ? "pointer" : "unset",
        color: color ? color : theme.palette.custom.secondaryTypography,
        overflowWrap: expanded ? "break-word" : "nowrap",
      }}
    >
      {!hideTooltip ? (
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={value}
          arrow
          placement={tootipPlacement}
          sx={{
            cursor: "pointer",
          }}
        >
          {returnValue()}
        </Tooltip>
      ) : (
        returnValue()
      )}
    </Box>
  );
};

export { TableTextWithEllipsis };
