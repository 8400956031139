import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import { ReactNode, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { ButtonIcon } from "../molecules/ButtonIcon";
import { Awaitable } from "../utilities/common";
import { hasCreatedBy, isCreatedByFairo } from "../utilities/UIHelper";

export type SearchableDropdownProps<T> = {
  /** Text to display in the search box */
  label?: string;
  /** Function to fetch the list of options based on string input */
  getOptions: (searchText: string) => Awaitable<T[]>;
  /** Determine whether an options is equal to a value */
  isOptionEqualToValue: (option: T, value: T) => boolean;
  /** Custom label getter. Uses `.label` property of the option by default */
  getOptionLabel?: (option: T) => string;
  /** Custom renderer. Displays the label by default */
  renderOption?: (itemProps: object, option: T) => ReactNode;
  /** Time to wait after user types before calling `getOptionLabel`. Defaults to 500ms */
  debounceDurationMs?: number;
  /** Custom getter to show the option as a summary and description pair */
  getSummaryDescription?: (option: T) => [string, string | React.ReactNode];
  /** On edit option */
  onEdit?: (option: T) => void;
  /** On delete option */
  onDelete?: (option: T) => void;
  /** If specified, shows a button at the end of the list with the given text and callback */
  actionButton?: [string, () => void];
  /** Whether to show error state for input */
  error?: boolean;
  /** Helper Text for input */
  helperText?: string;
  /** Whether the value is required */
  required?: boolean;
  predicate?: (value: T, index: number, array: T[]) => boolean;
  onTagClick?: (option: T) => void;
  disabled?: boolean;
  /** Fullwidth applied to the input */
  fullWidth?: boolean;
  /** Display custom component on the start of the selected input */
  startAdornment?: ReactNode;
  onBlur?: () => void;
  autoFocus?: boolean;
  /** Custom empty message when user didn't typed anything */
  emptyMessage?: string;
  /** Control if requests are dispatched only when user start typing */
  allowEmptySearch?: boolean;
} & (
  | {
      /** Whether multiple items can be selected */
      multiple?: false;
      /** Value of the selected item */
      value: T | null;
      /** Callback to change the selected item */
      onChange: (newVal: T | null) => void;
    }
  | {
      /** Whether multiple items can be selected */
      multiple: true;
      /** Value of the selected item */
      value: T[];
      /** Callback to change the selected item */
      onChange: (newVal: T[]) => void;
    }
);

const SummaryDescription = (props: {
  summary: string;
  description: string | React.ReactNode;
  onEdit: (() => void) | null;
  onDelete: (() => void) | null;
  deleteTitle?: string;
}) => {
  const { summary, description, onDelete, onEdit, deleteTitle = summary } = props;
  const theme = useTheme();
  const newDescription =
    description && typeof description === "string" ? description.replace(/<[^>]*>?/gm, "") : "";
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={!!onDelete || !!onEdit ? "70%" : "100%"}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        alignItems="flex-start"
      >
        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          variant="body2"
          color="text.primary"
          gap="5px"
        >
          {summary}
        </Typography>
        {typeof description === "string" ? (
          <Typography variant="body2" fontSize={12}>
            {newDescription}
          </Typography>
        ) : (
          description
        )}
      </Box>
      {(!!onDelete || !!onEdit) && (
        <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
          {onEdit && (
            <ButtonIcon
              iconSize="17px"
              emptyBackground={true}
              iconColor={theme.palette.custom.blue}
              action={() => onEdit()}
              label=""
              variant="edit-icon"
            />
          )}
          {onDelete && (
            <ButtonIcon
              iconSize="17px"
              emptyBackground={true}
              iconColor={theme.palette.custom.redTypography}
              action={() => onDelete()}
              label=""
              variant="trash-can"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export const SearchableDropdown = <T,>(props: SearchableDropdownProps<T>) => {
  const {
    value,
    onChange,
    getOptions,
    label,
    getOptionLabel,
    renderOption,
    debounceDurationMs,
    getSummaryDescription,
    onDelete,
    onEdit,
    isOptionEqualToValue,
    multiple,
    actionButton,
    error,
    helperText,
    required,
    predicate,
    onTagClick,
    disabled,
    fullWidth = true,
    startAdornment,
    onBlur,
    autoFocus,
    allowEmptySearch = true,
    emptyMessage,
  } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [options, setOptions] = useState<T[]>([]);
  const currentSearchId = useRef(0);
  // Store `getOptions` inside a ref so that the debounce always has reference to the latest prop
  const searchCallback = useRef(getOptions);
  searchCallback.current = getOptions;
  const theme = useTheme();

  const searchOptions = useCallback(
    debounce(async (str: string) => {
      const searchId = currentSearchId.current + 1;
      currentSearchId.current = searchId;
      // check for allowEmptySearch to avoid making requests when user didn't typed anything
      if ((!str || (str && str === "")) && !allowEmptySearch) {
        setOptions([]);
        setIsSearching(false);
      } else {
        const results = await searchCallback.current(str);
        // only consider the results of the latest search
        if (currentSearchId.current === searchId) {
          setOptions(results);
          setIsSearching(false);
        }
      }
    }, debounceDurationMs ?? 500),
    []
  );

  useEffect(() => {
    searchOptions("");
  }, []);

  return (
    <Autocomplete<T, typeof multiple>
      fullWidth={fullWidth}
      multiple={multiple}
      disabled={disabled}
      sx={{
        "& .MuiInputBase-root": {
          height: multiple ? "100%" : "35px",
          minWidth: fullWidth ? "unset" : "220px",
        },
      }}
      filterOptions={(options) => options}
      autoComplete
      loading={isSearching}
      onBlur={() => onBlur && onBlur()}
      loadingText={
        <Stack gap="10px" alignItems="center">
          <CircularProgress color="inherit" size={20} />
        </Stack>
      }
      ListboxProps={{ style: { maxHeight: "256px", overflow: "auto" } }}
      ListboxComponent={
        actionButton
          ? forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(
              function ListboxComponent(props, ref) {
                return (
                  <>
                    <ul ref={ref} {...props} />
                    <Box margin="4px">
                      <Button
                        fullWidth
                        onMouseDown={(e) => {
                          actionButton[1]();
                          e.preventDefault();
                        }}
                      >
                        {actionButton[0]}
                      </Button>
                    </Box>
                  </>
                );
              }
            )
          : undefined
      }
      onFocus={() => {
        setIsSearching(true);
        setSearchText("");
        searchOptions("");
      }}
      includeInputInList
      popupIcon={<KeyboardArrowDown />}
      value={value}
      // @ts-ignore: Typescipt unable to link the values of `multiple` and `onChange`
      onChange={(_, newValue) => onChange(newValue)}
      options={predicate ? options.filter(predicate) : options}
      noOptionsText={
        <Stack gap="10px" alignItems="center">
          {(searchText !== "" || allowEmptySearch) && (
            <>
              <Typography variant="body2" color={theme.palette.custom.gray}>
                Oops!
              </Typography>
              <Typography variant="body2" width="90%" color={theme.palette.custom.gray}>
                It appears that we couldn't find what you were looking for. Don't worry, try again
                or search for something different.
              </Typography>
            </>
          )}
          {searchText === "" && !allowEmptySearch && (
            <Typography variant="body2" width="90%" color={theme.palette.custom.gray}>
              {emptyMessage}
            </Typography>
          )}
          {actionButton && (
            <Button fullWidth onClick={actionButton[1]}>
              {actionButton[0]}
            </Button>
          )}
        </Stack>
      }
      inputValue={searchText}
      onInputChange={(_, newText) => {
        setIsSearching(true);
        setSearchText(newText);
        searchOptions(newText);
      }}
      renderTags={(value: T[], getTagProps) => {
        return value?.map((option: T, index: number) => (
          <Tooltip
            leaveDelay={200}
            enterNextDelay={500}
            enterDelay={500}
            title={getOptionLabel ? getOptionLabel(option) : ""}
            arrow
            placement="bottom"
            sx={{
              cursor: "pointer",
            }}
          >
            <Chip
              variant="outlined"
              label={getOptionLabel ? getOptionLabel(option) : ""}
              {...getTagProps({ index })}
              onClick={() => onTagClick && onTagClick(option)}
            />
          </Tooltip>
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={error && helperText}
          label={label ?? "Select or search *"}
          required={required}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
          InputProps={{
            ...params.InputProps,
            ...(startAdornment && {
              startAdornment: startAdornment,
            }),
            endAdornment: (
              <>
                {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={
        getSummaryDescription ? (option) => getSummaryDescription(option)[0] : getOptionLabel
      }
      renderOption={
        getSummaryDescription
          ? (props, option) => {
              const [summary, description] = getSummaryDescription(option);
              return (
                <Box component="li" {...props} width="100%">
                  <SummaryDescription
                    summary={summary}
                    description={description}
                    onEdit={
                      onEdit && hasCreatedBy(option) && !isCreatedByFairo(option.created_by)
                        ? () => onEdit(option)
                        : null
                    }
                    onDelete={
                      onDelete && hasCreatedBy(option) && !isCreatedByFairo(option.created_by)
                        ? () => onDelete(option)
                        : null
                    }
                  />
                </Box>
              );
            }
          : renderOption
          ? (props, option) => (
              <Box component="li" {...props}>
                {renderOption(props, option)}
              </Box>
            )
          : renderOption
      }
      limitTags={2}
    />
  );
};
