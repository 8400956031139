import { Box, Typography, useTheme } from "@mui/material";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { TableTextWithEllipsis } from "../TableTextWithEllipsis";

export const LabelAndValue = ({
  label,
  value,
  onClick,
  color,
  badge,
  customRender,
  direction = "row",
}: {
  label: string;
  value: string;
  onClick?: () => void;
  color?: string;
  badge?: boolean;
  customRender?: React.ReactNode;
  direction?: "row" | "column";
}) => {
  const theme = useTheme();
  const valueColor = color
    ? color
    : onClick
    ? theme.palette.custom.hyperlink
    : theme.palette.custom.secondaryTypography;
  return (
    <Box
      display="flex"
      flexDirection={direction}
      width="100%"
      gap="10px"
      alignItems={direction === "row" ? "center" : "flex-start"}
    >
      <Typography minWidth="115px" variant="h3" color={theme.palette.custom.secondaryTypography}>
        {label}
      </Typography>
      {customRender}
      {!customRender && badge && <DefaultBadge value={value} />}
      {!customRender && !badge && (
        <TableTextWithEllipsis value={value} variant="body2" onClick={onClick} color={valueColor} />
      )}
    </Box>
  );
};
