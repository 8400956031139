import { useEffect, useState } from "react";
import { SmallModal } from "./SmallModal";
import { Stack, TextField, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useInputState } from "../hooks/useInputTextState";
import { openSnackbar } from "../context/SnackbarContext";
import { fetchDepartments, useDepartment } from "../hooks/useDepartments";
import { addDepartment, editDepartment } from "../services/DepartmentService";
import { ColorPalette } from "../organisms/modals/tag/ColorPalette";

type CreateDepartmentModalProps = {
  open: boolean;
  onClose: () => void;
  departmentId?: string;
};

export const CreateDepartmentModal = (props: CreateDepartmentModalProps) => {
  const { open, onClose, departmentId } = props;

  const { data: department, isLoading: isLoadingDepartment } = useDepartment(departmentId ?? "");

  const [name, setName, nameError, setNameError] = useInputState("");
  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState<string>("#F09429");

  const theme = useTheme();
  const subtitleColor = theme.palette.custom.secondaryTypography;

  const onCreateDepartment = async (name: string, color: string) => {
    const department = await addDepartment({
      name: name,
      color: color,
    });
    return department;
  };

  const onEditDepartment = async (name: string, color: string) => {
    const department = await editDepartment(departmentId ?? "", {
      name: name,
      color: color,
    });
    return department;
  };

  const onCreate = async () => {
    if (!name || color == "#") {
      openSnackbar("Fill in correct details", "error");
      return;
    }
    try {
      const data = await fetchDepartments({
        name: [name],
      });
      if (data.results.length > 0) {
        setNameError(true);
        return;
      }
    } catch (e) {}
    try {
      setIsLoading(true);
      if (departmentId) {
        await onEditDepartment(name, color);
      } else {
        await onCreateDepartment(name, color);
      }
      openSnackbar("Department created successfully", "success");
    } catch (e) {
      openSnackbar("Department creation failed", "error");
    } finally {
      setIsLoading(false);
      setName("");
      setNameError(false);
      onClose();
    }
  };

  useEffect(() => {
    if (department) {
      setName(department.name);
      setColor(department.color);
    }
  }, [department]);

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      title={`${departmentId ? "Edit" : "Create"} Department`}
      isLoading={isLoadingDepartment}
    >
      <Stack gap="10px">
        <Typography variant="h3" color={subtitleColor}>
          Name
        </Typography>
        <TextField
          required
          value={name}
          error={nameError}
          helperText={nameError ? "This department already exists, please try again." : null}
          onChange={(e) => setName(e.target.value)}
          placeholder="Department name"
        />
        <ColorPalette
          color={color}
          onChange={setColor}
          colors={[
            "#F09429",
            "#E0D92B",
            "#5BC077",
            "#5BC0A2",
            "#5BA3C0",
            "#5B77C0",
            "#695BC0",
            "#B25BC0",
            "#C05B9E",
            "#C05B67",
          ]}
        />
        <LoadingButton onClick={onCreate} loading={isLoading} variant="contained">
          {departmentId ? "Save" : "Create"}
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
