import { Box, Skeleton, useTheme } from "@mui/material";

type CardTableItemLoaderProps = {
  width: string;
  height: string;
};

export const CardTableItemLoader = ({ width, height }: CardTableItemLoaderProps) => {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rounded"
      width={width}
      height={height}
      sx={{
        borderRadius: "3px",
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
        flex: "1 1 40%",
        "@media(max-width: 850px)": {
          flexBasis: "100%",
        },
      }}
    />
  );
};

export const CardTableLoader = () => {
  return (
    <>
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
      <CardTableItemLoader width="100%" height="119px" />
    </>
  );
};
