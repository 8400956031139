import { Box, useTheme } from "@mui/material";
import { useQuestionnaireAnswers } from "../../hooks/useAnswers";
import { Question, QuestionnaireAnswer } from "../../models/types";
import { DisplayAnswer } from "../../organisms/impact-assessment/DisplayAnswer";

type DisplayFinalAnswerProps = {
  question: Question;
  questionnaireId: string;
  isFinalized: boolean;
};
export const DisplayFinalAnswer = ({
  question,
  questionnaireId,
  isFinalized,
}: DisplayFinalAnswerProps) => {
  const theme = useTheme();
  const { data: answers } = useQuestionnaireAnswers({
    question: question.ref,
    questionnaire: questionnaireId,
    ...(isFinalized && { status: "FINALIZED" }),
  });
  return (
    <>
      {answers?.results?.map((answer: QuestionnaireAnswer, index) => {
        const isLastResult = index === answers.results.length - 1;
        return (
          <Box
            display="flex"
            padding="10px 0px"
            borderBottom={
              isLastResult ? "0px" : `1px solid ${theme.palette.custom.secondaryBorder}`
            }
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="space-between"
            gap="10px"
          >
            <DisplayAnswer
              question={question}
              disabled={answer.status === "ARCHIVE"}
              answer_number={index + 1}
              answer={answer}
              conflicts={false}
              selected={false}
              hasSelectedAnswer={false}
              hasMoreThanOneAnswer={false}
              onSelect={() => {}}
            />
          </Box>
        );
      })}
    </>
  );
};
