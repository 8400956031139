import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { ReactComponent as DeleteIcon } from "../assets/general/delete.svg";
import SecondaryBox from "../atoms/box/SecondaryBox";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { openSnackbar } from "../context/SnackbarContext";
import { useFiles } from "../hooks/useFiles";
import { FileAttachment } from "../models/types";
import { ChildrenBoxLoader } from "../organisms/skeleton-loaders/ChildrenBoxLoader";
import { Awaitable } from "../utilities/common";
import { ConfirmationModal } from "./ConfirmationModal";

type FileAttachmentFormProps = {
  /** Display loading effect while fetching data */
  isFetching?: boolean;
  /** List of file attachments */
  fileAttachments: FileAttachment[];
  /** Parent Object ID */
  parentObjectId?: string;
  /** Callback to attach new file */
  addAttachment: (attachment: File) => Awaitable<void>;
  /** Callback to remove an attached file */
  removeAttachment: (attachmentId: string) => Awaitable<void>;
  disabled?: boolean;
  editing?: boolean;
};

export const MAX_FILE_SIZE_MB = 10;

export const FileAttachmentForm = (props: FileAttachmentFormProps) => {
  const {
    fileAttachments: _fileAttachments,
    addAttachment,
    removeAttachment,
    isFetching,
    disabled,
    parentObjectId,
    editing,
  } = props;

  const fileAttachmentsHookResult = useFiles(parentObjectId ?? "");
  const { data: fileAttachments, isLoading } = !disabled
    ? fileAttachmentsHookResult
    : { data: _fileAttachments, isLoading: false };

  const hasFileAttachments = fileAttachments && fileAttachments.length > 0;
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const fileCount = fileAttachments?.length ?? 0;

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState<FileAttachment | null>(null);

  return (
    <>
      <SecondaryBox>
        {isLoading || !!!fileAttachments ? (
          <Box display="flex" width="100%" flexDirection="column" gap="10px">
            <ChildrenBoxLoader title="File Attachments" />
          </Box>
        ) : (
          <Dropzone
            noClick
            multiple={false}
            onDrop={async (acceptedFiles, fileRejections) => {
              if (fileRejections.length) {
                const errorMessage = fileRejections[0].errors[0].message;
                if (/File is larger than/.test(errorMessage)) {
                  // error doesn't display the size in human readable format. Show custom message
                  openSnackbar(`File size limit is ${MAX_FILE_SIZE_MB}MB`, "error");
                } else {
                  openSnackbar(errorMessage, "error");
                }
              } else if (acceptedFiles.length) {
                const fileToUpload = acceptedFiles[0];
                setLoading(true);
                try {
                  await addAttachment(fileToUpload);
                } catch {
                  openSnackbar("Failed to upload file", "error");
                } finally {
                  setLoading(false);
                }
              }
            }}
            maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
          >
            {({ getRootProps, getInputProps, isDragAccept, open: openFilePicker }) => (
              <Box {...getRootProps()} position="relative">
                <input {...getInputProps()} />
                {isDragAccept && <DropZoneIndicator />}
                <Stack gap="10px">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3" fontWeight={600}>
                      File Attachments {fileCount > 0 ? `(${fileCount})` : null}
                    </Typography>
                    {editing && (
                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={openFilePicker}
                        disabled={disabled || !editing}
                        sx={{
                          width: "100px",
                          height: "30px",
                        }}
                      >
                        Upload
                      </LoadingButton>
                    )}
                  </Box>
                  {hasFileAttachments &&
                    fileAttachments.map((attachment) => {
                      return (
                        <Box
                          key={attachment.id}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="body2" width="100%">
                            {attachment.filename}{" "}
                          </Typography>
                          <a href={attachment.file_object} download>
                            <IconButton
                              sx={{ width: "35px", height: "35px" }}
                              disabled={disabled || !editing}
                            >
                              <NavbarIcon
                                variant={
                                  theme.palette.mode === "light"
                                    ? "download-logo"
                                    : "download-dark-logo"
                                }
                                color={disabled ? theme.palette.custom.grayTypography : "none"}
                                sx={{ width: "10.29px", height: "15.44px" }}
                              />
                            </IconButton>
                          </a>
                          {editing && (
                            <IconButton
                              onClick={() => {
                                setSelectedForDelete(attachment);
                                setDeleteConfirmationOpen(true);
                              }}
                              sx={{ width: "35px", height: "35px" }}
                              disabled={disabled || !editing}
                            >
                              <DeleteIcon
                                width="16px"
                                height="17px"
                                color={
                                  disabled || !editing
                                    ? theme.palette.custom.grayTypography
                                    : theme.palette.custom.redTypography
                                }
                              />
                            </IconButton>
                          )}
                        </Box>
                      );
                    })}
                </Stack>
              </Box>
            )}
          </Dropzone>
        )}
      </SecondaryBox>
      {selectedForDelete && (
        <ConfirmationModal
          open={deleteConfirmationOpen}
          title={"Delete File Attachment"}
          description={`Are you sure you want to delete File Attachment ${selectedForDelete?.filename}? Once it’s deleted it’s gone for good`}
          onAccept={async () => removeAttachment(selectedForDelete.id)}
          onClose={() => {
            setDeleteConfirmationOpen(false);
            setSelectedForDelete(null);
          }}
          acceptText={"Delete"}
          rejectText="Cancel"
        />
      )}
    </>
  );
};

export const DropZoneIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      bgcolor={`${theme.palette.custom.grayHover}88`}
      borderRadius="6px"
      border="1px dashed"
      borderColor={theme.palette.custom.tableBorder}
      zIndex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography>Drop to Upload</Typography>
    </Box>
  );
};
