import { StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { Asset, BaseFilterOptions, BaseFilters, FilterOption } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { DefaultBadgeList } from "../../molecules/DefaultBadgeList";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { getFrameworkIcon } from "../FrameworkIconHelper";

export const assetListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Asset", value: "asset_num" },
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const assetListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Asset",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "type_name",
    headerName: "Type",
    visible: true,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset.assignee_name ? <UserTag name={asset.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) => getFrameworkIcon(framework, "small")}
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => <StatusBadge status={asset.status as any} />,
  },
];

export const policyListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Policy",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset?.assignee_name ? <UserTag name={asset.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    columnMinWidth: 170,
    columnMaxWidth: 170,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) => getFrameworkIcon(framework, "small")}
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => <StatusBadge status={asset.test_status as any} />,
  },
];

export const documentationListColumnsVisibility: IColumnVisibility<Asset>[] = [
  {
    field: "asset_num",
    headerName: "Documentation",
    visible: true,
    columnMinWidth: 400,
    renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    renderer: (asset) => (asset?.assignee_name ? <UserTag name={asset?.assignee_name} /> : ""),
  },
  {
    field: "type",
    headerName: "Frameworks",
    visible: true,
    renderer: (asset) => (
      <DefaultBadgeList
        renderIcon={(framework) => getFrameworkIcon(framework, "small")}
        badges={asset.frameworks.map((framework) => framework.name)}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    renderer: (asset) => <StatusBadge status={asset.test_status as any} />,
  },
];
