import { Checkbox, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import ShowAll from "./ShowAll";

export interface IColumnVisibility<T> {
  field: keyof T & string;
  columnMaxWidth?: number;
  columnMinWidth?: number;
  headerName: string;
  visible: boolean;
  fullRowWidth?: (row: T) => boolean;
  columnOnClick?: (row: T) => void;
  renderer?: (row: T) => ReactNode;
}

const ColumnVisibility = <T,>({
  columns,
  changeVisibility,
}: {
  columns: IColumnVisibility<T>[];
  changeVisibility: (data: IColumnVisibility<T>[]) => void;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [currentDescription, setCurrentDescription] = useState("(All)");

  const handleChange = (field: string, value: boolean) => {
    let newArray = columns.map((col) => (col.field === field ? { ...col, visible: value } : col));
    changeVisibility(newArray);
    setCurrentDescription(getSelectedColumnsDescription());
  };

  const description = (
    <Typography
      variant="h4"
      color={
        currentDescription === "No columns selected"
          ? theme.palette.custom.grayTypography
          : theme.palette.custom.secondaryTypography
      }
    >
      &nbsp;{currentDescription}
    </Typography>
  );

  const handleChangeAll = async (value: boolean) => {
    const newArray = await Promise.all(columns.map((c) => ({ ...c, visible: value })));
    changeVisibility(newArray);
    setCurrentDescription(getSelectedColumnsDescription());
  };

  const isShowAllColumns: boolean = columns?.every((c) => c.visible);
  const isEmptyVisibleColumns: boolean = columns?.every((c) => c.visible === false);

  const getSelectedColumnsDescription = () => {
    if (columns?.every((c) => c.visible)) {
      return "(All)";
    }
    const count = columns.filter((c: { visible: any }) => c.visible).length;
    if (count === 0) return "No columns selected";
    return `(${count})`;
  };
  useEffect(() => {
    setCurrentDescription(getSelectedColumnsDescription());
  }, [columns]);

  return (
    <Select
      sx={{
        height: "35px",
        width: 200,
        borderRadius: "3px",
        borderColor: theme.palette.custom.gray,
        "&.MuiInputBase-root .MuiSelect-select": {
          padding: "5px",
        },
      }}
      inputProps={{ style: { width: 100 } }}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: "4px",
            "& .MuiMenuItem-root": {
              padding: "1px 4px",
              gap: "5px",
              "&:hover": {
                backgroundColor: theme.palette.custom.planBackground,
              },
            },
          },
        },
      }}
      value={1}
      renderValue={() => (
        <ShowAll
          justifyContent="flex-start"
          showIcon={true}
          isEmpty={isEmptyVisibleColumns}
          rightComponent={description}
        />
      )}
    >
      <MenuItem
        value={1}
        sx={{
          padding: 0,
          ".MuiCheckbox-root": {
            color: `${theme.palette.custom.blueTypography}!important`,
          },
        }}
      >
        <ShowAll
          isEmpty={false}
          justifyContent="space-between"
          showIcon={false}
          rightComponent={
            <Checkbox
              size="small"
              checked={isShowAllColumns}
              onChange={(e) => handleChangeAll(e.target.checked)}
              sx={{
                padding: "0px",
              }}
            />
          }
        />
      </MenuItem>
      {columns &&
        columns?.map((column, i) => (
          <MenuItem
            key={i}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: 30,
              ".MuiCheckbox-root": {
                color: `${theme.palette.custom.blueTypography}!important`,
              },
            }}
            onClick={() => handleChange(column.field, !column.visible)}
          >
            <Typography variant="body2">{column.headerName}</Typography>
            <Checkbox
              value={column.field}
              size="small"
              checked={column.visible}
              onChange={(e) => handleChange(column.field, e.target.checked)}
              sx={{
                padding: "0px",
              }}
            />
          </MenuItem>
        ))}
    </Select>
  );
};

export default ColumnVisibility;
