import { Box, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "./navbar/Icon";

type IconButtonProps = {
  variant: INavbarIconVariant;
  color?: string;
  onClick: (e: any) => void;
  disabled?: boolean;
  width: string;
  selected?: boolean;
  height: string;
  padding?: string;
};

export const IconButton = ({
  width,
  height,
  variant,
  color,
  selected,
  onClick,
  disabled,
  padding = "2px 5px",
}: IconButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      padding={padding ?? "2px 5px"}
      onClick={(e) => !disabled && onClick(e)}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        cursor: "pointer",
        ...(!disabled && {
          "&:hover": {
            backgroundColor: `${theme.palette.custom.planBackground}50`,
            borderRadius: "3px",
          },
        }),
      }}
    >
      <NavbarIcon
        color={color}
        selected={selected}
        sx={{
          width: width,
          height: height,
          minHeight: height,
          minWidth: width,
        }}
        variant={variant}
      />
    </Box>
  );
};
