import { Vendor, VendorFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const vendorEndpoints = {
  vendors: () => "/vendors",
  vendor: (id: string) => `/vendors/${id}`,
};

export const getVendor = (id: string) => {
  return httpGetAuthenticated<Vendor>(vendorEndpoints.vendor(id));
};

export const getVendors = (params: VendorFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Vendor>>(vendorEndpoints.vendors(), {
    params,
  });
};

export const addVendor = async (payload: {
  name: string;
  website: string;
  address: string;
  description?: string;
  main_contact_name: string;
  main_contact_email: string;
  main_contact_phone: string;
  business_owner: string | null;
  compliance_owner: string | null;
}) => {
  const { data } = await httpPostAuthenticated<Vendor>(vendorEndpoints.vendors(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Vendor] });
  return data;
};

export const editVendor = async (
  vendorId: string,
  payload: {
    name?: string;
    website?: string;
    address?: string;
    description?: string;
    main_contact_name?: string;
    main_contact_email?: string;
    main_contact_phone?: string;
    business_owner?: string;
    compliance_owner?: string;
    status?: string;
    potential_value?: string | null;
    metadata?: any;
  }
) => {
  const resp = await httpPatchAuthenticated(vendorEndpoints.vendor(vendorId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Vendor] });
  return resp;
};

export const deleteVendor = async (id: string) => {
  const resp = await httpDeleteAuthenticated(vendorEndpoints.vendor(id));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Vendor] });
  return resp;
};
