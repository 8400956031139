import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchRegistries } from "../hooks/useRegistry";
import { getRegistry } from "../services/RegistryService";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const REGISTRY_NAVIGATOR_KEY = "registry-navigator";

export const useRegistryPathState = (locationState: any, path: string) => {
  const registryId = locationState?.registryId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    registryId: string;
    edit: boolean;
  }>(
    [REGISTRY_NAVIGATOR_KEY, path],
    async () => {
      if (registryId != null) {
        return { registryId, edit };
      }

      try {
        const registries = await fetchRegistries({ registry_num: [number] });
        const registry = registries.results.find((t) => t.registry_num === Number(number));
        if (!registry) {
          navigate("/oversight/registry");
          return { registryId: null, edit };
        }

        return { registryId: registry.id, edit };
      } catch {
        navigate("/oversight/registry");
        return { registryId: null, edit };
      }
    },
    { suspense: true }
  );
};

const navigateToRegistry = async (params: {
  navigate: NavigateFunction;
  registryId: string;
  registryNumber?: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { registryId, registryNumber, edit, navigate, returnLink = false } = params;
  let registryNum = registryNumber;
  if (!registryNumber) {
    const { data: registry } = await getRegistry(registryId, { fairo_data: true });
    registryNum = registry.registry_num;
  }
  if (returnLink) {
    return `/oversight/registry/registry-${registryNum}`;
  }
  navigate(`/oversight/registry/registry-${registryNum}`, {
    state: {
      registryId,
      edit,
    },
  });
};

export { navigateToRegistry };
