import { Box, Typography, useTheme } from "@mui/material";
import UserTag from "../../../components/UserTag";
import { useUseCase } from "../../../hooks/useUseCases";
import { ShowMore } from "../../../molecules/ShowMore";
import { RectangularLoader } from "../../../molecules/skeleton-loader/RectangularLoader";

type UseCaseReadOnlyProps = {
  useCaseId: string;
};

export const UseCaseReadOnly = (props: UseCaseReadOnlyProps) => {
  const { useCaseId } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  const { data: useCaseData, isLoading } = useUseCase(useCaseId);

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Use Case General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Showing use case name, use case owner and description (View only mode).
      </Typography>
      {isLoading ? (
        <>
          {[1, 2, 3].map((_, index) => (
            <RectangularLoader />
          ))}
        </>
      ) : (
        <>
          <Box display="flex" gap="10px" alignItems="center">
            <Typography variant="h3" color={textColor}>
              Use Case Name
            </Typography>
            <Typography variant="body2">{useCaseData?.name}</Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <Typography variant="h3" color={textColor}>
              Use Case Owner
            </Typography>
            <UserTag name={useCaseData?.assignee_name} />
          </Box>
          {useCaseData?.description && (
            <Box display="flex" flexDirection="column" gap="5px">
              <Typography variant="h3" color={textColor}>
                Description
              </Typography>
              <ShowMore
                description={useCaseData?.description ?? ""}
                maxChar={250}
                richText={true}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
