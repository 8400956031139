import { Box, useTheme } from "@mui/material";
import React from "react";

interface IHeaderProps {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  sx?: {
    padding: string;
  };
}

const Header = ({ leftComponent, rightComponent, sx }: IHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...sx,
      }}
    >
      {leftComponent}
      {rightComponent}
    </Box>
  );
};

export default Header;
