import { Department, DepartmentFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const departmentEndpoints = {
  departments: () => "/departments",
  department: (id: string) => `/departments/${id}`,
};

export const getDepartments = (params: DepartmentFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Department>>(departmentEndpoints.departments(), {
    params,
  });
};

export const getDepartment = (departmentId: string) => {
  return httpGetAuthenticated<Department>(departmentEndpoints.department(departmentId));
};

export const addDepartment = async (payload: { name: string; color: string }) => {
  const resp = await httpPostAuthenticated(departmentEndpoints.departments(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Department] });
  return resp;
};

export const editDepartment = async (
  departmentId: string,
  payload: { name: string; color: string }
) => {
  const resp = await httpPatchAuthenticated(departmentEndpoints.department(departmentId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Department] });
  return resp;
};

export const deleteDepartment = async (departmentId: string) => {
  const resp = await httpDeleteAuthenticated(departmentEndpoints.department(departmentId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Department] });
  return resp;
};
