import { Box, Tooltip, Typography, useTheme } from "@mui/material";

type NameIconProps = {
  name: string;
  size?: "xs" | "small" | "medium";
  twoLetters?: boolean;
};

export const NameIcon = ({ name, size = "medium", twoLetters = true }: NameIconProps) => {
  const theme = useTheme();
  const initials = () => {
    try {
      // get first two letters
      const [first, second] = name.split(" ");
      if (second && twoLetters) {
        return first[0] + second[0];
      } else {
        return first[0];
      }
    } catch (err) {
      return "";
    }
  };
  const sizes = {
    xs: "20px",
    small: "24px",
    medium: "60px",
  };
  return (
    <Tooltip leaveDelay={200} enterNextDelay={500} enterDelay={500} title={name} placement="top">
      <Box
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={sizes[size]}
        minHeight={sizes[size]}
        minWidth={sizes[size]}
        width={sizes[size]}
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        bgcolor={theme.palette.custom.planBackground}
      >
        <Typography color={theme.palette.custom.primaryTypography} textAlign="center" variant="h5">
          {initials()}
        </Typography>
      </Box>
    </Tooltip>
  );
};

type NameIconListProps = {
  options: string[];
  size?: "small" | "medium";
  twoLetters?: boolean;
  maxDisplay?: number;
};

export const NameIconList = ({
  options,
  size = "medium",
  twoLetters = true,
  maxDisplay = 3,
}: NameIconListProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="5px" width="100%">
      {options.slice(0, maxDisplay).map((option) => (
        <NameIcon size={size} twoLetters={twoLetters} name={option} key={option} />
      ))}
      {options.length > maxDisplay && (
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={options.slice(maxDisplay).join(", ")}
          placement="top"
        >
          <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
            +{options.length - maxDisplay}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};
