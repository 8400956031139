import UserTag from "../../components/UserTag";
import { BaseFilterOptions, BaseFilters, FilterOption, Workflow } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../UIHelper";

export const workflowListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Workflow", value: "workflow_num" },
  { label: "Summary", value: "summary" },
  { label: "Description", value: "description" },
  ...BaseFilterOptions,
];

export const workflowListcolumnsVisibility: IColumnVisibility<Workflow>[] = [
  {
    field: "workflow_num",
    headerName: "Workflow",
    visible: true,
    columnMaxWidth: 100,
    columnMinWidth: 100,
    renderer: (workflow) => (
      <TableTextWithEllipsis fontWeight={600} value={`Workflow-${workflow.workflow_num}`} />
    ),
  },
  {
    field: "summary",
    headerName: "Summary",
    visible: true,
    columnMinWidth: 400,
    renderer: (workflow) => <TableTextWithEllipsis value={workflow.summary} />,
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    visible: true,
    columnMaxWidth: 250,
    columnMinWidth: 200,
    renderer: (workflow) => <UserTag name={workflow.updated_by.name} />,
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (workflow) => defaultDateDisplay(workflow.updated_on),
  },
];
