import ELK from "elkjs/lib/elk.bundled.js";
import { ReactElement, useCallback, useEffect, useLayoutEffect, useState } from "react";
import ReactFlow, { useNodesState, useEdgesState, addEdge, Controls } from "react-flow-renderer";
import "./GraphFlow.css";

type OrgChartFlowProps = {
  nodes: any[];
  edges: any[];
  renderNode: (node: any) => ReactElement;
  onNodeClick: (node: any) => void;
};

const elk = new ELK();

const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": "60",
  "elk.spacing.nodeNode": "100",
  "elk.direction": "DOWN",
};

const getLayoutedElements = async (
  nodes: any[],
  edges: any,
  renderNode: (node: any) => ReactElement
) => {
  const graph = {
    id: "root",
    layoutOptions: elkOptions,
    children: nodes.map((node: any) => ({
      ...node,
      targetPosition: "top",
      sourcePosition: "bottom",
      data: { label: renderNode(node) },
      className: "org-chart-card-base",
      width: 226,
      height: 129,
    })),
    edges: edges,
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children?.map((node) => {
        return {
          ...node,
          position: { x: node.x, y: node.y },
        };
      }),

      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
};

export const OrgChartFlow = (props: OrgChartFlowProps) => {
  const { nodes: _nodes, edges: _edges, renderNode, onNodeClick } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState(_nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(_edges);
  const [averageX, setAverageX] = useState<number>(
    nodes.reduce((acc, node) => acc + node.position.x, 0) / (nodes.length || 1)
  );
  const [averageY, setAverageY] = useState<number>(
    nodes.reduce((acc, node) => acc + node.position.y, 0) / (nodes.length || 1)
  );
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

  const onConnect = useCallback((params: any) => setEdges((eds: any) => addEdge(params, eds)), []);
  const onLayout = useCallback(() => {
    const ns = nodes;
    const es = edges;

    getLayoutedElements(ns, es, renderNode).then((result) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = result as {
        nodes: any[];
        edges: any[];
      };
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      setAverageX(
        layoutedNodes.reduce((acc, node) => acc + node.position.x, 0) / (layoutedNodes.length || 1)
      );
      setAverageY(
        layoutedNodes.reduce((acc, node) => acc + node.position.y, 0) / (layoutedNodes.length || 1)
      );
    });
  }, [nodes, edges]);

  useLayoutEffect(() => {
    onLayout();
    setAverageX(nodes.reduce((acc, node) => acc + node.position.x, 0) / (nodes.length || 1));
    setAverageY(nodes.reduce((acc, node) => acc + node.position.y, 0) / (nodes.length || 1));
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView({
        nodes: nodes,
      });
    }
  }, [averageX, averageY]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onInit={(instance) => setReactFlowInstance(instance)}
      onConnect={onConnect}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      attributionPosition="bottom-left"
      minZoom={0.1}
      nodesDraggable={false}
      onNodeClick={(_, node) => {
        onNodeClick(node);
      }}
    >
      <Controls showInteractive={false} />
    </ReactFlow>
  );
};
