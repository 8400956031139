export const getProgressFromControl = (progress: { completed: number; total: number }) => {
  return progress.completed > 0
    ? Number(((progress.completed / progress.total) * 100).toFixed(2))
    : 0;
};

export const getCompletedPercentage = (completed: number, total: number) => {
  const percentage = (completed / total) * 100;
  // check if percentage is a valid number
  if (isNaN(percentage)) {
    return 0;
  }
  return Math.ceil(percentage);
};
