import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  breakDescriptionIn250Chars,
  breakRichTextDescriptionIn250Chars,
} from "../utilities/UIHelper";

type ShowMoreProps = {
  description: string;
  maxChar: number;
  richText?: boolean;
};

export const ShowMore = (props: ShowMoreProps) => {
  const { description, maxChar, richText = false } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const showButton = description.length > maxChar;
  useEffect(() => {
    setIsOverflow(description.length > maxChar);
  }, [description]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          textOverflow: "ellipsis",
          transition: "max-height 0.5s ease",
        }}
      >
        {!richText ? (
          <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
            {isExpanded ? description : `${breakDescriptionIn250Chars(description, maxChar)}`}
          </Typography>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: isExpanded
                ? description
                : `${breakRichTextDescriptionIn250Chars(description, maxChar)}`,
            }}
          />
        )}
      </Box>
      {(showButton || isOverflow) && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <LoadingButton
            onClick={toggleExpanded}
            sx={{
              padding: "0px",
            }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
