import { IPlan } from "../context/SubscriptionContext";
import { ApiKey } from "../models/types";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";
import { searchGithubIssues, searchJiraIssuesApi } from "./PluginService";

const organizationEndpoints = {
  updateBillingInfo: (organizationId: string) =>
    `/organizations/${organizationId}/update_billing_info`,
  getCard: (organizationId: string) => `/organizations/${organizationId}/get_stripe_default_card`,
  updateCard: (organizationId: string) =>
    `/organizations/${organizationId}/get_stripe_card_session`,
  startTrial: (organizationId: string) => `/organizations/${organizationId}/start_trial`,
  subscriptionPlans: (organizationId: string) => `/organizations/${organizationId}/plans`,
  subscriptions: (organizationId: string) => `/organizations/${organizationId}/subscriptions`,
  cancelSubscription: (organizationId: string) =>
    `/organizations/${organizationId}/cancel_subscription`,
  startSubscription: (organizationId: string) =>
    `/organizations/${organizationId}/start_subscription`,
  changeSubscription: (organizationId: string) => `/organizations/${organizationId}/change_plan`,
  invoices: (organizationId: string) => `/organizations/${organizationId}/invoices`,
  upcomingInvoice: (organizationId: string) => `/organizations/${organizationId}/upcoming_invoice`,
  apiKeys: (organizationId: string) => `/organizations/${organizationId}/organization_api_key`,
  updateOrganization: (organizationId: string) => `/organizations/${organizationId}`,
};

type OrgBillingInfo = {
  name: string;
  billing_name: string;
  billing_contact_name2: string;
  billing_address1: string;
  billing_address2: string;
  billing_state: string;
  billing_city: string;
  billing_email1: string;
  billing_email2: string;
};

export const updateBillingInfo = (orgId: string, billingInfo: OrgBillingInfo) => {
  return httpPostAuthenticated(organizationEndpoints.updateBillingInfo(orgId), billingInfo);
};

export const getCard = (orgId: string) => {
  return httpGetAuthenticated(organizationEndpoints.getCard(orgId));
};

export const updateCard = (orgId: string) => {
  return httpGetAuthenticated(organizationEndpoints.updateCard(orgId));
};

export const startTrial = (orgId: string) => {
  return httpPostAuthenticated(organizationEndpoints.startTrial(orgId));
};

export const getSubscriptionPlans = (orgId: string) => {
  return httpGetAuthenticated<IPlan[]>(organizationEndpoints.subscriptionPlans(orgId));
};

export const getSubscriptions = (orgId: string) => {
  return httpGetAuthenticated(organizationEndpoints.subscriptions(orgId));
};

export const cancelSubscription = (orgId: string) => {
  return httpPostAuthenticated(organizationEndpoints.cancelSubscription(orgId));
};

export const startSubscription = (orgId: string, productId: string) => {
  return httpPostAuthenticated(organizationEndpoints.startSubscription(orgId), {
    product_id: productId,
  });
};

export const changeSubscription = (orgId: string, productId: string) => {
  return httpPostAuthenticated(organizationEndpoints.changeSubscription(orgId), {
    plan_id: productId,
  });
};

export type JiraIssue = {
  id: number;
  key: string;
  keyHtml: string;
  summary: string;
  summaryText: string;
};

export const searchJiraIssues = (searchText: string) => {
  return searchJiraIssuesApi(searchText);
};

export type GithubItem = {
  id: number;
  number: number;
  title: string;
  html_url: string;
  state: string;
  body: string;
  url: string;
  pull_request?: {
    url: string;
  };
};

export const searchGithubItems = (orgId: string, searchText: string) => {
  return searchGithubIssues(searchText);
};

export type Invoice = {
  id: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  created: number;
  currency: string;
  hosted_invoice_url: string;
  invoice_pdf: string;
  paid: boolean;
  period_end: number;
  period_start: number;
  status: "paid" | "pending";
  total: number;
};

export const invoices = (organizationId: string, limit: number = 10, startingAfter?: string) => {
  return httpGetAuthenticated<{
    data: Invoice[];
    has_more: boolean;
  }>(organizationEndpoints.invoices(organizationId), {
    params: {
      limit,
      starting_after: startingAfter,
    },
  });
};

export type UpcomingInvoice = {
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  created: number;
  currency: string;
  next_payment_attempt: number;
  period_end: number;
  period_start: number;
};

export const upcomingInvoice = (organizationId: string) => {
  return httpGetAuthenticated<UpcomingInvoice>(
    organizationEndpoints.upcomingInvoice(organizationId)
  );
};

export const getApiKeys = (organizationId: string) => {
  return httpGetAuthenticated<ApiKey[]>(organizationEndpoints.apiKeys(organizationId));
};

export const createApiKey = (organizationId: string, description: string) => {
  return httpPostAuthenticated(organizationEndpoints.apiKeys(organizationId), { description });
};

export const deleteApiKey = (organizationId: string, keyId: string) => {
  return httpDeleteAuthenticated(organizationEndpoints.apiKeys(organizationId), {
    data: { api_key_id: keyId },
  });
};

export const updateOrganizationDescription = (
  organizationId: string,
  payload: { name: string; description: string }
) => {
  return httpPatchAuthenticated(organizationEndpoints.updateOrganization(organizationId), payload);
};
