import UserTag from "../../components/UserTag";
import { Action, BaseFilterOptions, BaseFilters, FilterOption } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../UIHelper";

export const actionListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Action", value: "action_num" },
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

export const actionListColumnsVisibility: IColumnVisibility<Action>[] = [
  {
    field: "action_num",
    headerName: "Action",
    visible: true,
    columnMaxWidth: 100,
    columnMinWidth: 100,
    renderer: (action) => (
      <TableTextWithEllipsis fontWeight={600} value={`Action-${action.action_num}`} />
    ),
  },
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 400,
    renderer: (action) => <TableTextWithEllipsis value={action.name} />,
  },
  {
    field: "updated_by",
    headerName: "Updated By",
    visible: true,
    columnMaxWidth: 250,
    columnMinWidth: 200,
    renderer: (action) => <UserTag name={action.updated_by.name} />,
  },
  {
    field: "updated_on",
    headerName: "Updated On",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (action) => defaultDateDisplay(action.updated_on),
  },
];
