import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { Vendor } from "../../../models/types";
import { YesOrNoSelectField } from "../../custom-fields/BooleanSelect";
import { fetchVendor, searchVendor, useVendor } from "../../../hooks/useVendors";
import { LoadingButton } from "@mui/lab";
import { useInputState } from "../../../hooks/useInputTextState";
import { VendorReadOnly } from "./VendorReadOnly";
import { CreateVendorForm } from "./CreateVendorForm";
import { openSnackbar } from "../../../context/SnackbarContext";

type DefineVendorProps = {
  onVendorDefine: (data: {
    vendor?: string;
    vendorName?: string;
    vendorDescription?: string;
    vendorMainContactName?: string;
  }) => void;
  onBack: () => void;
  onContinue: () => void;
  useCaseDefined: boolean;
  selectedVendor?: Vendor;
};

export const DefineVendor = (props: DefineVendorProps) => {
  const { onVendorDefine, onBack, onContinue, useCaseDefined, selectedVendor } = props;
  const theme = useTheme();

  const [selectedYes, setSelectedYes] = useState(false);
  const [selectedNo, setSelectedNo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vendorReviewed, setVendorReviewed] = useState(false);

  const [showBooleanForm, setShowBooleanForm] = useState(true);
  const [showVendorDetails, setShowVendorDetails] = useState(false);
  const [showVendorForm, setShowVendorForm] = useState(false);

  const [vendor, setVendor] = useState<Vendor | null>(null);

  const [vendorName, setVendorName, vendorNameError, setVendorNameError] = useInputState("");
  const [vendorDescription, setVendorDescription] = useState<string>("");
  const [vendorMainContactName, setVendorMainContactName] = useState<string>("");

  const handleNext = () => {
    setShowBooleanForm(false);
    if (selectedYes && vendor) {
      setShowVendorDetails(true);
      if (vendor && vendorReviewed) {
        onVendorDefine({
          vendor: vendor.id,
        });
      }
      setVendorReviewed(true);
      return;
    }
    if (selectedNo) {
      if (vendorName) {
        onVendorDefine({
          vendorName: vendorName,
          vendorDescription: vendorDescription,
          vendorMainContactName: vendorMainContactName,
        });
      }
      setShowVendorForm(true);
      return;
    }
    if (!selectedYes && !selectedNo) {
      if (!useCaseDefined) {
        setShowBooleanForm(true);
        openSnackbar("Please select an use case or vendor", "error");
        return;
      }
      onContinue();
    }
  };

  const handleBack = () => {
    if (showVendorDetails || showVendorForm) {
      setShowVendorDetails(false);
      setShowVendorForm(false);
      setVendorReviewed(false);
      setShowBooleanForm(true);
      return;
    }
    onBack();
  };

  useEffect(() => {
    if (selectedYes && vendor) {
      setDisabled(false);
    }
    if (selectedNo) {
      if (showVendorForm) {
        if (vendorName) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
        return;
      }
      setDisabled(false);
    }
  }, [selectedYes, vendor, selectedNo, showVendorForm, vendorName]);

  useEffect(() => {
    if (selectedVendor) {
      setSelectedYes(true);
      setSelectedNo(false);
      setVendor(selectedVendor);
    }
  }, [selectedVendor]);

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="space-between">
      {showBooleanForm && (
        <Box display="flex" flexDirection="column" gap="10px" width="100%" alignItems="center">
          <Typography variant="h1">Select or Create Vendor</Typography>
          <Typography variant="body2">
            We notice that you have vendors already created, do you want to select an existing one
            or create a new one?
          </Typography>
          <YesOrNoSelectField
            value={selectedYes ? "Yes" : selectedNo ? "No" : ""}
            onChange={(value) => {
              if (value === "Yes") {
                setSelectedYes(true);
                setSelectedNo(false);
              } else {
                setSelectedNo(true);
                setSelectedYes(false);
              }
            }}
            required={true}
            yesLabel="Yes, select existing vendor"
            noLabel="No, create a new one"
          />
          {selectedYes && (
            <Box display="flex" flexDirection="column" gap="10px" width="100%">
              <Typography variant="body2" color={theme.palette.custom.grayTypography}>
                Select the vendor that you want to create the impact assessment for.
              </Typography>
              <SearchableDropdown<Vendor>
                label="Select or Search Vendor"
                required={true}
                value={vendor}
                onChange={(value) => setVendor(value)}
                getOptions={searchVendor}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(obj) => obj.name ?? "-"}
              />
            </Box>
          )}
        </Box>
      )}
      {showVendorDetails && vendor && <VendorReadOnly vendor={vendor} />}
      {showVendorForm && (
        <CreateVendorForm
          vendorName={vendorName}
          setVendorName={setVendorName}
          vendorNameError={vendorNameError}
          mainContactName={vendorMainContactName}
          setMainContactName={setVendorMainContactName}
          description={vendorDescription}
          setDescription={setVendorDescription}
        />
      )}
      <Box width="100%" display="flex" gap="10px" justifyContent="space-between">
        <LoadingButton onClick={() => handleBack()}>Go back</LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => handleNext()}
          loading={loading}
          // disabled={disabled}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
};
