import { Avatar, Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { NameIcon } from "../atoms/NameIcon";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { useUsers } from "../services/UserSettingsService";

type Props = {
  /** user's name ex: john snow */
  userId?: string;
  name?: string | null;
  createdByFairo?: boolean;
  onClick?: () => void;
  orientation?: "horizontal" | "vertical";
  hideName?: boolean;
  showNameInitials?: boolean;
  pointer?: boolean;
  nameTypo?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "subtitle1"
    | "body1"
    | "button"
    | "body2"
    | "small"
    | "navbar"
    | "navbarSelected"
    | "logo"
    | "code";
};

const UserTag = ({
  userId = "",
  name,
  createdByFairo = false,
  nameTypo = "body2",
  onClick,
  orientation = "horizontal",
  hideName,
  showNameInitials,
  pointer = false,
}: Props) => {
  const { data: user, isLoading } = useUsers(userId);
  const theme = useTheme();
  const isHorizontal = orientation === "horizontal";
  return isLoading ? (
    <CircularProgress size={15} />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isHorizontal ? "row" : "column"}
      gap="8px"
      sx={{ cursor: onClick || pointer ? "pointer" : "auto" }}
      onClick={onClick}
    >
      {createdByFairo && (
        <NavbarIcon
          variant="created-by-fairo"
          color="none"
          sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
        />
      )}
      {!createdByFairo &&
        (!showNameInitials || (showNameInitials && (!name || (name && name === "")))) && (
          <NavbarIcon
            variant="user"
            color="none"
            sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
          />
        )}
      {!createdByFairo && showNameInitials && name && name !== "" && (
        <NameIcon size="small" name={user ? user.name : name} />
      )}
      {/* {!createdByFairo && <UserTagAvatar name={name} />} */}
      {!hideName && (
        <Typography
          variant={nameTypo}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: onClick
              ? theme.palette.custom.blueTypography
              : theme.palette.custom.secondaryTypography,
            textDecoration: onClick ? "underline" : "none",
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {user ? user.name : name ? name : "Unassigned"}
        </Typography>
      )}
    </Box>
  );
};

/** @TODO when adding user profile image functionality update this function */
const UserTagAvatar = ({ name }: { name: string }) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        height: 20,
        width: 20,
      }}
      alt={name}
      src={
        theme.palette.mode === "dark"
          ? "/assets/images/user-avatar-dark.svg"
          : "/assets/images/user-avatar.svg"
      }
    ></Avatar>
  );
};

export default UserTag;
export { UserTagAvatar };
