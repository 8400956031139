import { SmallModal } from "../../../components/SmallModal";
import UserTag from "../../../components/UserTag";
import { useReports } from "../../../hooks/useReports";
import { BaseFilters, FilterOption, Report } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { EmptyTable } from "../../../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../../../utilities/UIHelper";
import { APITable } from "../../APITable";

const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [
  { label: "ID", value: "id" },
  { label: "Report", value: "report_num" },
];

export const ReportsModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const columnsVisibility: IColumnVisibility<Report>[] = [
    {
      field: "report_num",
      headerName: "ID",
      visible: true,
      renderer: (report) => (
        <TableTextWithEllipsis fontWeight={600} value={`Report-${report.report_num}`} />
      ),
      columnMinWidth: 100,
      columnMaxWidth: 120,
    },
    {
      field: "report_type",
      headerName: "Report Type",
      visible: true,
      renderer: (report) => <TableTextWithEllipsis value={report.report_type} />,
      columnMinWidth: 150,
    },
    {
      field: "created_on",
      headerName: "Generated On",
      visible: true,
      renderer: (report) => (
        <TableTextWithEllipsis value={defaultDateDisplay(report.created_on) ?? ""} />
      ),
      columnMaxWidth: 140,
      columnMinWidth: 110,
    },
    {
      field: "created_by",
      headerName: "Generated By",
      visible: true,
      renderer: (report) => <UserTag name={report.created_by.name} />,
      columnMaxWidth: 200,
      columnMinWidth: 150,
    },
  ];

  return (
    <SmallModal size={"medium"} open={open} onClose={onClose} title="Reports">
      <APITable
        useGetData={useReports}
        columnsVisibility={columnsVisibility}
        queryParams={{}}
        smallEmptyHeight={true}
        filterOptions={FILTER_OPTIONS}
        rowOnClick={(report) => window.open(report.url, "_blank")}
        emptyTableComponent={
          <EmptyTable
            variant="empty-box"
            label="No reports"
            description={`New reports will appear here.`}
          />
        }
        tableSize="small"
      />
    </SmallModal>
  );
};
