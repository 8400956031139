import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ReactComponent as CancelIcon } from "../assets/general/cancel.svg";
import { ReactComponent as ChevronDown } from "../assets/general/chevron-down.svg";

export const DarkTheme = createTheme({
  palette: {
    custom: {
      // new settings for colors, use this new props

      //background
      planBackground: "#353535",
      secondaryBackground: "#2F2F2F",
      cardBackground: "#323232",
      // typography
      primaryTypography: "#F1F1F1",
      secondaryTypography: "#E0E0E0",
      grayTypography: "#929292",
      redTypography: "#EB635F",
      yellowTypography: "#E9C546",
      greenTypography: "#92D36A",
      whiteTypography: "#FFFFFF",
      blueTypography: "#0191FD",
      hyperlink: "#FFFFFF",
      // button
      hoverButton: "#3A3A3A",
      selectedButton: "#252525",
      withoutFillButton: "#FFFFFF",
      mainButton: "#2C67F1",
      disabledButton: "#929292",
      deleteButton: "#EB635F",

      // overlay
      overlay: "rgba(6, 6, 6, 0.65)",

      // borders
      primaryBorder: "#E0E0E0",
      secondaryBorder: "#4D4C4C",
      disabledBorder: "#929292",

      // alerts
      successAlert: "#92D36A",
      errorAlert: "#EB635F",

      // old settings
      tableBorder: "#D9E2EA",
      primary: "#2F2F2F",
      secondary: "#F1F1F1",
      gray: "#929292",
      grayHover: "#3A3A3A",
      error: "#EB635F",
      warning: "#E9C546",
      success: "#92D36A",
      white: "#FFFFFF",
      blue: "#0191FD",
      errorHover: "#BD4F4C",
      activeStep: "#E0E0E0",
      columnHeader: "#F1F1F1", // SD-484
      user: "#E0E0E0",
      planTitle: "#FFFFF",
      textEditorBackground: "#2F2F2F",
      baloo2: "'Baloo 2', cursive",
      raleway: "Inter",
      navButtonBackground: "#252525",
      navArrowBackground: "#2C67F1",
      overviewIconInactive: "#929292",
      overviewIconActive: "#E0E0E0",
      cardBackgroundActive: "#353535",
      cardBackgroundInactive: "#2F2F2F",

      timestamp: "#E0E0E0",

      primaryStatusColor: "#C5E4F7",
      primaryStatusBackgroundColor: "#1E4E7B",
      secondaryStatusBackgroundColor: "#615E5E",
      secondaryStatusColor: "#F0F2F3",
      warningStatusColor: "#F3E3C9",
      warningStatusBackgroundColor: "#AC5E03",
      successStatusColor: "#C6F5C9",
      successStatusBackgroundColor: "#3E6327",
      errorStatusColor: "#F3CAC9",
      errorStatusBackgroundColor: "#993625",
    },
    mode: "dark",
    primary: {
      main: "#F1F1F1",
    },
    secondary: {
      main: "#2F2F2F",
    },
    common: {
      black: "#000",
      white: "#FFF",
    },
    background: {
      default: "#2F2F2F",
      paper: "#353535",
    },
    success: {
      main: "#92D36A",
    },
    error: {
      main: "#EB635F",
    },
    warning: {
      main: "#EB635F",
    },
    action: {
      disabled: "#929292",
    },
    text: {
      primary: "#F1F1F1",
      secondary: "#E0E0E0",
    },
  },

  typography: {
    h1: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1,
    },
    subtitle1: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "0%",
    },
    body1: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0%",
    },
    button: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: "0%",
      textTransform: "none",
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0%",
    },
    small: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#E0E0E0",
      fontWeight: 400,
    },
    fontFamily: ['"Baloo2"', "Baloo Bhai 2", "Inter", "sans-serif"].join(","),
    navbar: {
      color: "#929292",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "'Inter', sans-serif",
    },
    navbarSelected: {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "'Inter', sans-serif",
    },
    logo: {
      color: "#F1F1F1",
      fontSize: "22px",
      fontWeight: "600",
      fontFamily: "'Baloo Bhai 2', cursive",
    },
    code: {
      color: "#F1F1F1",
      fontSize: "14px",
      fontFamily: "'Fira Code', monospace",
      fontWeight: 400,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "#929292 !important", // Default state
          },
          "&:hover fieldset": {
            borderColor: "#E0E0E0 !important", // Hover state
          },
          "&.Mui-focused fieldset": {
            borderWidth: "1.5px !important",
            borderColor: "#E0E0E0 !important", // Focused state
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          opacity: 1,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0)",
          },
          "&.Mui-checked": {
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0)",
            },
          },
          "&.Mui-checked + .MuiSwitch-track": {
            opacity: 0.4,
            backgroundColor: "#0191FD",
          },
          "&.Mui-checked .MuiSwitch-thumb": {
            backgroundColor: "#0191FD",
            boxShadow: "0px 1px 3px 0px #00000033",
          },
        },
        track: {
          opacity: 1,
          backgroundColor: "#E0E0E0",
        },
        thumb: {
          boxShadow: "0px 1px 3px 0px #00000033",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          color: "#E0E0E0",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#929292",
            },
            "&.Mui-focused fieldset": {
              borderWidth: "1px",
              borderColor: "#E0E0E0", // Outline color when focused
            },
          },
          "& .MuiInputLabel-root": {
            color: "#929292", // Label color

            "&.Mui-focused": {
              color: "#E0E0E0", // Label color when focused
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#929292",
          "&.Mui-focused": {
            color: "#E0E0E0",
          },
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "'Inter', sans-serif",
          "&.Mui-error": {
            color: "#929292",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "3px !important",
          height: "35px",
          fontSize: "14px",
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
          fontFamily: "'Inter', sans-serif",
        },
        multiline: {
          height: "unset",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          "&:hover": {
            backgroundColor: "#3A3A3A",
          },
          "&.Mui-selected": {
            backgroundColor: "#252525",
          },
          color: "#FFFFFF",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: 700,
          height: "35px",
          textTransform: "none",
          borderRadius: "3px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
          "&:focus": {
            boxShadow: "none",
          },
        },
        contained: {
          color: "#FFFFFF",
        },
        containedPrimary: {
          backgroundColor: "#2C67F1",
        },
        text: {
          ":default": {
            color: "#EB635F",
          },
        },
        outlined: {
          color: "#E0E0E0",
          borderColor: "#E0E0E0",
          "&:hover": {
            borderColor: "#E0E0E0",
          },
        },
        textPrimary: {
          color: "#FFFFFF",
          textDecoration: "underline",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#EEF7FF",
          color: "#3B4956",
          fontFamily: "'Inter', sans-serif",
          fontSize: 14,
          fontWeight: 400,
          borderRadius: "6px",
          padding: "10px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          color: "#E0E0E0",
          "&:hover": {
            backgroundColor: "#2F2F2F",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDown,
        variant: "outlined",
      },
      styleOverrides: {
        select: {
          padding: "8.5px 14px",
          maxHeight: "35px",
          color: "#E0E0E0",
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
        },
        icon: {
          margin: "5px",
          color: "#FFFFFF",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        slotProps: {
          backdrop: {
            style: {
              backgroundColor: "#3A3A3A66",
              opacity: "40%",
            },
          },
        },
      },
      styleOverrides: {
        paper: {
          backgroundColor: "#2F2F2F!important",
          borderRadius: "6px",
          borderWidth: "1px",
          borderColor: "#4D4C4C",
          borderStyle: "solid",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: "#F1F1F1",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#F1F1F1",
          background: "#2F2F2F",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: "#2F2F2F",
          overflow: "hidden",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          background: "#2F2F2F",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "always",
        color: "#FFFFFF",
      },
      styleOverrides: {
        root: {
          textDecorationColor: "inherit",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          padding: "4px",
        },
        tag: {
          margin: "0 3px",
          height: "26px",
        },
        option: {
          padding: "2px 4px",
          fontSize: "14px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
        },
        inputRoot: {
          borderRadius: "3px",
          "& input": {
            maxHeight: "35px",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CancelIcon />,
      },
      styleOverrides: {
        root: {
          height: "24px",
          borderRadius: "38px",
          border: "1px solid",
          borderColor: "#4D4C4C",
          background: "#353535",
        },
        label: {
          paddingLeft: "6px",
          paddingRight: "6px",
        },
        deleteIcon: {
          color: "#FFFFFF",
          stroke: "#596B7C",
          width: "17px",
          height: "17px",
          marginRight: "4px",
          marginLeft: "-4px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          padding: "unset",
          paddingLeft: "10px",
          color: "unset",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          backgroundColor: "#353535",
          borderRadius: "3px",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: "10px 18px",
        },
        label: {
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "18px",
          margin: "15px",
          height: "20px",
        },
        switchViewIcon: {
          color: "#0191FD",
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: "#0191FD",
        },
        spacer: {
          width: "5px",
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: "15px",
          fontWeight: 500,
          margin: "5px 12px",
          height: "18px",
          width: "18px",
          color: "#929292",
        },
        weekContainer: {
          margin: "1px",
          padding: "0",
        },
        slideTransition: {
          minHeight: "180px",
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          "&.MuiMonthPicker-root": {
            "& .PrivatePickersMonth-root": {
              fontSize: "15px",
              fontWeight: 500,
              border: "1px #353535 solid",
              borderRadius: "3px",
              padding: "10px",
            },
            "& .Mui-selected": {
              fontSize: "15px",
              fontWeight: 500,
              color: "#FFFFFF",
              backgroundColor: "#0191FD!important",
              border: "1px #353535 solid",
              borderRadius: "3px",
              padding: "10px",
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "15px",
          "&.Mui-selected": {
            color: "#FFFFFF",
            backgroundColor: "#0191FD!important",
            border: "1px #353535 solid",
            borderRadius: "3px",
            padding: "10px",
          },
          "&:not(.Mui-selected)": {
            border: "1px #353535 solid",
            borderRadius: "3px",
          },
        },
        today: {
          color: "#0191FD",
          backgroundColor: "#353535!important",
        },
        dayWithMargin: {
          borderRadius: "3px",
          margin: "5px 12px",
          fontSize: "15px",
          fontWeight: 500,
          height: "18px",
          width: "18px",
          textAlign: "right",
          color: "#E0E0E0",
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        OpenPickerButtonProps: {
          style: {
            color: "#0191FD",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          "& .PrivatePickersYear-root": {
            "& .PrivatePickersYear-yearButton": {
              fontFamily: "'Inter', sans-serif",
              fontSize: "15px",
              fontWeight: 500,
              margin: "2px 0",
              "&.Mui-selected": {
                color: "#FFFFFF",
                backgroundColor: "#0191FD!important",
                border: "1px #353535 solid",
                borderRadius: "3px",
              },
              "&:not(.Mui-selected)": {
                border: "1px #353535 solid",
                borderRadius: "3px",
              },
              "&.Mui-disabled": {
                color: "#929292",
                cursor: "default",
                pointerEvents: "none",
              },
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#4D4C4C!important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: "6px",
          color: "#2F2F2F",
          backgroundColor: "#2F2F2F",
          boxShadow: "0px 4px 6px 2px rgba(0, 0, 0, 0.12)",
          border: "1px solid #4D4C4C",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#353535",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#353535 !important",
          "&.Mui-completed": {
            color: "#0191FD !important",
          },
        },
        text: {
          fontFamily: "'Inter', sans-serif",
          fill: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: "'Inter', sans-serif",
          color: "#FFFFFF !important",
          fontSize: "14px",
          fontWeight: "600 !important",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          width: "50px",
        },
        root: {
          "&.Mui-active .MuiStepConnector-line": {
            borderColor: "#FFFFFF",
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderColor: "#FFFFFF",
          },
        },
      },
    },
  },
});
