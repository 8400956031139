import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomCircularProgress } from "../../../../atoms/CustomCircularProgress";
import { navigateToEvidence } from "../../../../components/navigators/EvidenceNavigator";
import { useOrganizationContext } from "../../../../context/OrganizationContext";
import { useEvidenceTests } from "../../../../hooks/useEvidenceTest";
import { ComplianceElement } from "../../../../models/types";
import { APITable } from "../../../APITable";
import { evidenceTestListColumnsVisibility } from "../../../impact-assessment/EvidenceTestListColumnsVisibility";

type ElementDetailsEvidenceTestsTableProps = {
  elementId: string;
  element: ComplianceElement;
};

export const ElementDetailsEvidenceTestsTable = ({
  elementId,
  element,
}: ElementDetailsEvidenceTestsTableProps) => {
  const navigate = useNavigate();
  const { setSelectedElement } = useOrganizationContext();
  return (
    <APITable
      hideOnEmpty={true}
      useGetData={useEvidenceTests}
      smallEmptyHeight={true}
      collapsable
      title={`Evidence Tests`}
      hideColumnVisibility
      collapsedInitialState={true}
      queryParams={{
        "object_relates_to_id[]": [elementId],
      }}
      titleRightComponent={
        <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
          {element?.evidences_progress && (
            <CustomCircularProgress progress={element.evidences_progress} />
          )}
        </Box>
      }
      columnsVisibility={evidenceTestListColumnsVisibility({
        showType: false,
        hideOwnerName: true,
        showFrameworks: false,
      })}
      rowOnClick={(evidence) => {
        navigateToEvidence({
          navigate,
          evidenceId: evidence.id,
          evidenceNumber: evidence.evidence_test_num,
        });
        setSelectedElement(null);
      }}
      tableSize="small"
      displayTags={true}
    />
  );
};
