import { Box } from "@mui/material";
import { useExecutionTask } from "../../../hooks/useExecutionTask";
import { ModalLoader } from "../../skeleton-loaders/ModalLoader";
import { BaseCreateTask } from "./BaseCreateTask";
import { ReadOnlyTask } from "./ReadOnlyTask";

export const BaseTaskModal = (props: {
  taskId: string;
  onClose: () => void;
  isUpdate: boolean;
  setIsUpdate: (isUpdate: boolean) => void;
}) => {
  const { taskId, onClose, isUpdate, setIsUpdate } = props;
  const { data: task, isLoading } = useExecutionTask(taskId);

  return isLoading || !task ? (
    <ModalLoader />
  ) : (
    <Box display="flex" flexDirection="column" gap={"10px"}>
      {!isUpdate && <ReadOnlyTask task={task} onClose={() => onClose()} />}
      {isUpdate && (
        <BaseCreateTask
          onClose={() => setIsUpdate(false)}
          relatedObjectId={task.parent_object_id}
          relatedObjectType={task.parent_type}
          task={task}
        />
      )}
    </Box>
  );
};
