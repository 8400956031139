import { Box, Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useImpactAssessment } from "../../../hooks/useImpactAssessment";
import { SemanticSimiliarityItem } from "../../../models/types";
import BadgeList from "../../../molecules/BadgeList";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { editImpactAssessment } from "../../../services/ImpactAssessmentService";
import { getSemanticSimilarity } from "../../../services/SemanticSimilarityAI";
import {
  breakDescriptionIn250Chars,
  capitalizeFirstLetter,
  uniqueTypeAndNumber,
} from "../../../utilities/UIHelper";

type SemanticSimilarityModalProps = {
  open: boolean;
  onClose: () => void;
  answers: string[];
  impactAssessmentId: string;
  showSemanticSearch: boolean;
};

export const SemanticSimilarityModal = (props: SemanticSimilarityModalProps) => {
  const { open, onClose, impactAssessmentId, answers, showSemanticSearch } = props;
  const theme = useTheme();
  const [regulations, setRegulations] = useState<SemanticSimiliarityItem[]>([]);
  const [showRegulations, setShowRegulations] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: impactAssessment } = useImpactAssessment(impactAssessmentId);

  const handleAddRelatedRegulation = async (
    relatedRegulation: SemanticSimiliarityItem | SemanticSimiliarityItem[]
  ) => {
    try {
      if (Array.isArray(relatedRegulation)) {
        await editImpactAssessment(impactAssessmentId, {
          metadata: {
            ...impactAssessment?.metadata,
            related_regulations: regulations,
          },
        });
        onClose();
      } else {
        const currentRegulations = impactAssessment?.metadata?.related_regulations || [];
        if (currentRegulations.some((regulation) => regulation.link === relatedRegulation.link)) {
          openSnackbar("Related regulation already added", "error");
        } else {
          await editImpactAssessment(impactAssessmentId, {
            metadata: {
              ...impactAssessment?.metadata,
              related_regulations: [...currentRegulations, relatedRegulation],
            },
          });
        }
        const updatedRegulations = regulations.filter(
          (regulation) => regulation.link !== relatedRegulation.link
        );
        setRegulations(updatedRegulations);
      }
      openSnackbar("Related regulation added successfully", "success");
    } catch (error) {
      openSnackbar("Failed to add related regulation", "error");
    }
  };

  const handleMatchRegulations = async () => {
    try {
      setLoading(true);
      let related_regulations: SemanticSimiliarityItem[] = [];
      await Promise.all(
        answers.map(async (answer) => {
          if (Array.isArray(answer)) {
            await Promise.all(
              answer.map(async (ans) => {
                const parsedAnswer = JSON.parse(ans);
                const response = await getSemanticSimilarity(
                  parsedAnswer.name,
                  parsedAnswer.description
                );
                related_regulations.push(...response);
              })
            );
          } else {
            const parsedAnswer = JSON.parse(answer);
            const response = await getSemanticSimilarity(
              parsedAnswer.name,
              parsedAnswer.description
            );
            related_regulations.push(...response);
          }
        })
      );

      const typePriority: { [type: string]: number } = {
        article: 1, // Highest priority
        recital: 2,
        annex: 3, // Lowest priority
      };
      const sorttedItems = related_regulations.sort((a, b) => {
        const typeComparison = typePriority[a.type] - typePriority[b.type];
        if (typeComparison === 0) {
          // If types are the same, sort by number
          return a.number - b.number;
        }
        return typeComparison;
      });
      setRegulations(uniqueTypeAndNumber(sorttedItems));
      setShowRegulations(true);
    } catch (e) {
      console.log(e);
      openSnackbar("Failed to fetch regulations", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showSemanticSearch) {
      handleMatchRegulations();
    }
  }, [impactAssessmentId, answers]);

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      title="Semantic Search Results"
      size="large"
      rightComponent={
        !loading && showRegulations ? (
          <Button variant="contained" onClick={() => handleAddRelatedRegulation(regulations)}>
            Add All
          </Button>
        ) : null
      }
      maxHeight="500px"
      isLoading={loading}
    >
      <Stack gap="15px">
        <RegulationTypeSection
          regulationType="Articles"
          regulations={regulations.filter((item) => item.type === "article")}
          onAdd={handleAddRelatedRegulation}
        />
        <RegulationTypeSection
          regulationType="Recitals"
          regulations={regulations.filter((item) => item.type === "recital")}
          onAdd={handleAddRelatedRegulation}
        />
        <RegulationTypeSection
          regulationType="Annexes"
          regulations={regulations.filter((item) => item.type === "annex")}
          onAdd={handleAddRelatedRegulation}
        />
      </Stack>
    </SmallModal>
  );
};

export const RegulationTypeSection = ({
  regulationType,
  regulations,
  onAdd,
}: {
  regulationType: string;
  regulations: SemanticSimiliarityItem[];
  onAdd: (relatedRegulation: SemanticSimiliarityItem | SemanticSimiliarityItem[]) => void;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box
        display="flex"
        gap="8px"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        <Typography
          variant="h2"
          padding="10px"
        >{`${regulationType} (${regulations.length})`}</Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          <NavbarIcon variant={expanded ? "chevron-up-sm" : "chevron-down-sm"} />
        </IconButton>
      </Box>
      {expanded && (
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px" padding="0px 10px">
          {regulations.map((regulation, index) => (
            <RelatedRegulationCard
              onAdd={() => onAdd(regulation)}
              tags={[regulation.intended_use]}
              key={index}
              description={regulation.blurb}
              onClick={() => window.open(regulation.link, "_blank")}
              title={`${capitalizeFirstLetter(regulation.type)}-${regulation.number}`}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export const RelatedRegulationCard = ({
  title,
  description,
  tags,
  onClick,
  onAdd,
}: {
  tooltip?: string;
  onClick: () => void;
  title: string;
  description: string;
  tags: string[];
  onAdd: () => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      flex="1 0 288px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      bgcolor={theme.palette.custom.cardBackground}
      padding="10px"
      flexDirection="column"
      gap="10px"
      minWidth="288px"
      borderRadius="3px"
    >
      <TableTextWithEllipsis value={title} onClick={() => onClick()} />
      <Typography variant="body2">{breakDescriptionIn250Chars(description, 200)}</Typography>
      <BadgeList values={tags} />
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Button variant="contained" onClick={() => onAdd()} color="primary">
          Add
        </Button>
      </Box>
    </Box>
  );
};
