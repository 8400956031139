import { Button } from "@mui/material";
import { useJiraIntegration } from "../../hooks/useJiraIntegration";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { GetJiraIssue } from "../../models/types";
import { searchJiraIssues } from "../../services/OrganizationService";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type JiraTicketProps = {
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
};

export const JiraTicket = (props: JiraTicketProps) => {
  const { value, onChange, required } = props;
  const navigate = useNavigate();
  const [selected, setSelected] = useState<GetJiraIssue | null>(value ? JSON.parse(value) : null);

  const { data: jiraInfo, isLoading } = useJiraIntegration();

  const searchIssues = async (searchText: string) => {
    const res = await searchJiraIssues(searchText);
    return res.data;
  };

  useEffect(() => {
    if (selected) {
      onChange(JSON.stringify(selected));
    }
  }, [selected]);

  return isLoading ? (
    <RectangularLoader width="100%" height="30px" />
  ) : jiraInfo ? (
    <SearchableDropdown<GetJiraIssue>
      value={selected}
      allowEmptySearch={false}
      label="Search for Jira Issue"
      emptyMessage="Start typing to search for keywords within the issue summary or description"
      onChange={setSelected}
      getOptions={searchIssues}
      getOptionLabel={(option) => option.summary ?? ""}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(object) => [object?.summary ?? object.key, object.key]}
      required={required}
    />
  ) : (
    <Button sx={{ height: "30px" }} onClick={() => navigate("/organization/integrations")}>
      Set Up Now
    </Button>
  );
};
