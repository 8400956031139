import { StatusBadge } from "../atoms/StatusBadge";
import { IAMPolicy } from "../models/types";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { DefaultBadgeList } from "../molecules/DefaultBadgeList";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";
import { capitalizeFirstLetter } from "../utilities/UIHelper";
import { Table } from "./Table";

type UserPermissionTableViewProps = {
  permissions: IAMPolicy[];
};

export const UserPermissionTableView = ({ permissions }: UserPermissionTableViewProps) => {
  type UserPermissionTableColumn = {
    id: string;
    scope: string;
    resources?: string[];
    permissions: string[];
    effect: "allow" | "deny";
  };
  const formattPolicies = (policies: IAMPolicy[]) => {
    const policyMap = new Map<string, UserPermissionTableColumn>();

    policies?.forEach((policy, idx) => {
      policy.action.forEach((action) => {
        const isAllActions = action === "*";
        const isApplyToAllScopes = action.startsWith("*:");
        const scope = isAllActions || isApplyToAllScopes ? "All" : action.split(":")[0];
        const permission = isAllActions ? "*" : action.split(":")[1];
        const resourceKey = policy?.resources?.join(",") ?? "*";
        const mapKey = `${scope}-${resourceKey}-${policy.effect}`;
        if (policyMap.has(mapKey)) {
          // If the scope/resource combination exists, add the permission to the array
          const existingEntry = policyMap.get(mapKey);
          if (existingEntry && !existingEntry.permissions.includes(permission)) {
            if (permission === "*" || existingEntry.permissions.includes("*")) {
              existingEntry.permissions = ["*"];
            } else {
              existingEntry.permissions.push(permission);
            }
          }
        } else {
          // Create a new entry in the map
          policyMap.set(mapKey, {
            id: `${policyMap.size}`,
            scope: scope,
            resources: policy?.resources?.map((resource) =>
              resource === "*" ? "All" : resource
            ) ?? ["All"],
            permissions: [permission],
            effect: policy.effect,
          });
        }
      });
    });
    return Array.from(policyMap.values());
  };

  const columns: IColumnVisibility<UserPermissionTableColumn>[] = [
    {
      field: "scope",
      headerName: "Scope",
      visible: true,
      columnMinWidth: 100,
      renderer: (permission) => (
        <TableTextWithEllipsis value={capitalizeFirstLetter(permission.scope.replace(/_/g, " "))} />
      ),
    },
    {
      field: "resources",
      headerName: "Resources",
      visible: true,
      columnMinWidth: 100,
      renderer: (permission) => (
        <DefaultBadgeList
          badges={
            permission.resources?.map((resource) => (resource === "*" ? "All" : resource)) ?? [
              "All",
            ]
          }
        />
      ),
    },
    {
      field: "permissions",
      headerName: "Actions",
      visible: true,
      columnMinWidth: 120,
      renderer: (permission) => (
        <DefaultBadgeList badges={permission.permissions?.map((p) => (p === "*" ? "All" : p))} />
      ),
    },
    {
      field: "effect",
      headerName: "Effect",
      visible: true,
      columnMinWidth: 50,
      renderer: (permission) => <StatusBadge status={permission.effect.toUpperCase() as any} />,
    },
  ];
  return (
    <Table
      visible={true}
      smallEmptyHeight={true}
      columnsVisibility={columns}
      isLoading={false}
      rows={formattPolicies(permissions)}
      onShowAllColumns={() => {}}
    />
  );
};
