import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { APIFetchOptions, APIGetListHook } from "../../models/types";

type GenericFilterProps<T extends { id: string }, P extends APIFetchOptions> = {
  useGetData: APIGetListHook<T, P>;
  params: P;
  label: string;
  getItemName: (item: T) => string;
  selected: string[];
  setSelected: (status: string[]) => void;
};

export const GenericFilter = <T extends { id: string }, P extends APIFetchOptions>({
  useGetData,
  params,
  selected,
  setSelected,
  getItemName,
  label,
}: GenericFilterProps<T, P>) => {
  const theme = useTheme();
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [loading, setLoading] = useState<boolean>(false);

  const { data, isLoading: isLoadingData } = useGetData({
    page: 1,
    page_size: 10,
    search: debouncedSearch,
    ...params,
  });

  const isLoading = isLoadingData || loading;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setLoading(false);
    }, 500); // Adjust the delay (in milliseconds) as needed

    return () => {
      clearTimeout(handler);
      setLoading(true);
    };
  }, [search]);

  const handleChange = (option: T) => {
    if (selected.includes(option.id)) {
      setSelected(selected.filter((status) => status !== option.id));
    } else {
      setSelected([...selected, option.id]);
    }
  };

  const handleClear = () => {
    setSelected([]);
  };

  const defaultMenuItemProps = {
    display: "flex",
    justifyContent: "space-between",
    height: 30,
    ".MuiCheckbox-root": {
      color: `${theme.palette.custom.blueTypography}!important`,
    },
  };

  const filtersCount = selected.length > 0 ? `(${selected.length})` : "(All)";
  const isEmptyResults = data?.results?.length === 0;
  return (
    <Select
      sx={{
        height: "35px",
        width: 170,
        borderRadius: "3px",
        borderColor: theme.palette.custom.gray,
        "&.MuiInputBase-root .MuiSelect-select": {
          padding: "5px",
        },
      }}
      renderValue={() => (
        <Box
          display="flex"
          padding="0 0 0 4px"
          flexDirection="row"
          gap="5px"
          alignItems="center"
          width="100%"
          height="30px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Typography variant="body2">{label}</Typography>
          <Typography color={theme.palette.custom.secondaryTypography} variant="h4">
            {filtersCount}
          </Typography>
        </Box>
      )}
      inputProps={{ style: { width: 100 } }}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: "4px",
            "& .MuiMenuItem-root": {
              padding: "1px 4px",
              gap: "5px",
              "&:hover": {
                backgroundColor: theme.palette.custom.planBackground,
              },
            },
          },
        },
      }}
      value={1}
    >
      <Box padding="4px 4px 8px 4px">
        <TextField
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          variant="standard"
          size="small"
          placeholder="Search"
          value={search}
          sx={{
            height: "20px",
            minHeight: "20px",
          }}
          InputProps={{
            sx: {
              "&.MuiInputBase-root": {
                padding: "2px 4px",
                fontSize: "14px",
                height: "22px",
                "&::before": {
                  borderBottom: `1px solid ${theme.palette.custom.secondaryBorder} !important`,
                },
                "&:hover:not(.Mui-disabled)::before": {
                  borderBottom: `1px solid ${theme.palette.custom.primaryBorder} !important`,
                },
                "&.Mui-focused::before": {
                  borderBottom: `1px solid ${theme.palette.custom.primaryBorder} !important`,
                },
                "&::after": {
                  borderBottom: `1px solid ${theme.palette.custom.hyperlink} !important`,
                },
              },
            },
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      {isLoading && (
        <Box display="flex" width="100%" justifyContent="center" padding="8px 0px">
          <CircularProgress size={20} />
        </Box>
      )}
      {!isLoading &&
        !isEmptyResults &&
        data?.results?.map((column, i) => (
          <MenuItem key={i} sx={defaultMenuItemProps} onClick={() => handleChange(column)}>
            <Typography variant="body2">{getItemName(column)}</Typography>
            <Checkbox
              value={column}
              size="small"
              checked={selected.includes(column.id)}
              onChange={(e) => handleChange(column)}
              sx={{
                padding: "0px",
              }}
            />
          </MenuItem>
        ))}
      {!isLoading && isEmptyResults && (
        <Typography padding="2px 4px" variant="body2">
          No results
        </Typography>
      )}
      <MenuItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ".MuiCheckbox-root": {
            color: `${theme.palette.custom.blueTypography}!important`,
          },
          borderTop: `1px solid ${theme.palette.custom.secondaryBorder}`,
          padding: "8px 0px",
          marginTop: "4px",
        }}
        disabled={selected.length === 0}
        onClick={() => handleClear()}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding="10px 0px 6px 0px"
        >
          <Button
            size="small"
            disabled={selected.length === 0}
            sx={{
              height: "25px",
              fontSize: "12px",
              fontWeight: 400,
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
        </Box>
      </MenuItem>
    </Select>
  );
};
