import { Box, Typography, useTheme } from "@mui/material";

export const NeedsAttentionSummary = (summary: { status: string; count: number }[]) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      {summary.map((item) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          gap="10px"
        >
          <Typography variant="navbar" color={theme.palette.custom.grayHover}>
            {item.status}
          </Typography>
          <Typography variant="navbar" color={theme.palette.custom.grayHover}>
            {item.count}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
