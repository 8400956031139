import { Box, useTheme } from "@mui/material";
import React from "react";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

const SecondaryBox = ({
  children,
  maxWidth,
  gap,
  padding = "10px",
}: {
  children?: React.ReactNode;
  maxWidth?: string;
  gap?: string;
  padding?: string;
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isSmallScreen = ScreenSmallerThen();
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "background.paper",
        borderRadius: "6px",
        justifyContent: "flex-start",
        flexDirection: "column",
        padding: padding,
        maxWidth: isSmallScreen ? "100%" : maxWidth || "100%",
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
        bgcolor: isDarkMode
          ? theme.palette.custom.planBackground
          : theme.palette.custom.secondaryBackground,
        gap,
      }}
    >
      {children}
    </Box>
  );
};

export default SecondaryBox;
