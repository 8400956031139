import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import CKEditorField from "../../../../components/custom-fields/CKEditorField";
import { StringCustomField } from "../../../../components/CustomFieldForm";
import { SearchableDropdown } from "../../../../components/SearchableDropdown";
import {
  useJiraIssueTypes,
  useJiraProjects,
  useJiraUsers,
} from "../../../../hooks/useJiraIntegration";
import { JiraIssue, JiraIssueType, JiraProject, JiraUser } from "../../../../models/types";

type CreateNewJiraIssueProps = {
  jiraIssue: JiraIssue | null;
  onAdd: (jiraIssue: JiraIssue | null) => void;
};
export const CreateNewJiraIssue = ({ jiraIssue, onAdd }: CreateNewJiraIssueProps) => {
  const [assignee, setAssignee] = useState<JiraUser | null>(jiraIssue?.assignee ?? null);
  const [project, setProject] = useState<JiraProject | null>(jiraIssue?.project ?? null);
  const [projectError, setProjectError] = useState<string | null>(null);
  const [issueTypeError, setIssueTypeError] = useState<string | null>(null);
  const [issueType, setIssueType] = useState<JiraIssueType | null>(jiraIssue?.issue_type ?? null);
  const [summaryError, setSummaryError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string>(jiraIssue?.summary ?? "");
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [description, setDescription] = useState<string>(jiraIssue?.description ?? "");
  const { data: jiraUsers } = useJiraUsers();
  const { data: jiraProjects } = useJiraProjects();
  const { data: issueTypes } = useJiraIssueTypes(project?.id ?? "");

  const filterJiraUsers = (search: string) => {
    if (jiraUsers) {
      return jiraUsers.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()));
    }
    return [];
  };

  const filterJiraProjects = (search: string) => {
    if (jiraProjects) {
      return jiraProjects.filter((option) =>
        option.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  };

  const filterIssueTypes = (search: string) => {
    if (issueTypes) {
      return issueTypes.filter((option) =>
        option.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  };
  const theme = useTheme();
  const inheritColor = theme.palette.custom.secondaryTypography;

  const isFormValid = () => {
    const isValidProject = !!project;
    const isValidIssueType = !!issueType;
    const isValidSummary = !!summary || (summary && summary !== "");
    const isValidDescription = !!description || (description && description !== "");

    if (!isValidProject) {
      setProjectError("Please select a project");
    } else {
      setProjectError(null);
    }
    if (!isValidIssueType) {
      setIssueTypeError("Please select an issue type");
    } else {
      setIssueTypeError(null);
    }
    if (!isValidSummary) {
      setSummaryError("Please enter a summary");
    } else {
      setSummaryError(null);
    }
    if (!isValidDescription) {
      setDescriptionError("Please enter a description");
    } else {
      setDescriptionError(null);
    }
    if (!isValidProject || !isValidIssueType || !isValidSummary || !isValidDescription) {
      return false;
    }
    return true;
  };

  const handleAddIssue = () => {
    try {
      if (!isFormValid()) {
        return;
      }
      // Form validation is checking for null scenarios
      onAdd({
        assignee: assignee,
        project: project as JiraProject,
        issue_type: issueType as JiraIssueType,
        summary: summary,
        description: description,
      });
    } catch (error) {}
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={inheritColor}>
        Project
      </Typography>
      <SearchableDropdown<JiraProject>
        value={project}
        onChange={setProject}
        getOptions={filterJiraProjects}
        error={!!projectError}
        helperText={projectError ?? ""}
        getOptionLabel={(option) => option.name ?? ""}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        getSummaryDescription={(object) => [object.name, ""]}
      />
      <Typography variant="h3" color={inheritColor}>
        Assignee
      </Typography>
      <SearchableDropdown<JiraUser>
        value={assignee}
        onChange={setAssignee}
        getOptions={filterJiraUsers}
        label="Select or search"
        getOptionLabel={(option) => option.name ?? ""}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        getSummaryDescription={(object) => [object.name, ""]}
      />
      <Typography variant="h3" color={inheritColor}>
        Issue Type
      </Typography>
      <SearchableDropdown<JiraIssueType>
        disabled={!project}
        value={issueType}
        onChange={setIssueType}
        getOptions={filterIssueTypes}
        error={!!issueTypeError}
        helperText={issueTypeError ?? ""}
        getOptionLabel={(option) => option.name ?? ""}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        getSummaryDescription={(object) => [object.name, ""]}
      />
      <Typography variant="h3" color={inheritColor}>
        Summary
      </Typography>
      <StringCustomField
        error={!!summaryError}
        errorMessage={summaryError ?? ""}
        value={summary}
        onChange={setSummary}
      />
      <Typography variant="h3" color={inheritColor}>
        Description
      </Typography>
      <CKEditorField
        value={description}
        onChange={setDescription}
        errorMessage={descriptionError}
      />
      <Button onClick={() => handleAddIssue()}>Add</Button>
    </Box>
  );
};
