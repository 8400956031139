import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { TasksColumnsVisibility } from "../../organisms/tables/tasks/TasksColumnsVisibility";
import { AssetFilesLoader } from "../../organisms/skeleton-loaders/AssetFilesLoader";

export const AssetPolicyDetailsLoader = () => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap="15px" paddingY="15px">
      <SecondaryBox>
        <AssetFilesLoader />
      </SecondaryBox>
      <SecondaryBox>
        <DetailsLoader />
      </SecondaryBox>
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      >
        <Box height={"100%"}>
          <Box
            paddingBottom={"0px"}
            borderRadius="6px"
            border="1px solid"
            minHeight="100%"
            maxHeight="100%"
            overflow="auto"
            borderColor={theme.palette.custom.secondaryBorder}
            bgcolor={theme.palette.custom.secondaryBackground}
          >
            <APITableLoader
              columnsVisibility={TasksColumnsVisibility({
                onEdit: (taskId: string) => {},
                showCompleted: false,
              })}
              tableSize="small"
              secondComponent={true}
              tableSearch={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
