import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { OrgChartSummary } from "../../../components/OrgChartSummary";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { SmallModal } from "../../../components/SmallModal";
import { searchDepartments, useDepartments } from "../../../hooks/useDepartments";
import { searchUsers, useUsers } from "../../../hooks/useUsers";
import { Department, User } from "../../../models/types";

type DefineRolesProps = {
  onDefineRoles: (assessmentOwner: string, departments: string[], reviewers: string[]) => void;
  onBack: () => void;
};

export const DefineRoles = (props: DefineRolesProps) => {
  const { onDefineRoles, onBack } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  const [loading, setLoading] = useState(false);
  const [openOrgChart, setOpenOrgChart] = useState(false);

  const [assessmentOwner, setAssessmentOwner] = useState<User | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [reviewers, setReviewers] = useState<User[]>([]);

  const { data: reviewersUsers } = useUsers({ id: reviewers.map((reviewer) => reviewer.id) });
  const { data: departmentDetails } = useDepartments({
    id: departments.map((department) => department.id),
  });

  const handleNext = () => {
    setLoading(true);
    try {
      onDefineRoles(
        assessmentOwner?.id ?? "",
        departments.map((department) => department.id),
        reviewers.map((reviewer) => reviewer.id)
      );
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallModal
        open={openOrgChart}
        onClose={() => setOpenOrgChart(false)}
        title="Organizaiton Chart"
        size="large"
      >
        <OrgChartSummary />
      </SmallModal>
      <Box display="flex" flexDirection="column" gap="10px" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gap="10px" width="100%">
          <Typography variant="h1" textAlign="center">
            Define Roles
          </Typography>
          <Typography variant="body2" textAlign="center">
            Add name, description and select the desired use case type.
          </Typography>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h3" color={textColor}>
              Assessment Owner
            </Typography>
            <Typography variant="body2">
              Enter the user responsible for assessing the use case.
            </Typography>
            <SearchableDropdown<User>
              label="Select or Search"
              value={assessmentOwner}
              onChange={setAssessmentOwner}
              getOptionLabel={(user) => user.name}
              getOptions={searchUsers}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(user) => [user.name, user.username]}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h3" color={textColor}>
              Related Teams
            </Typography>
            <Typography variant="body2">
              List the teams involved in this use case. Include all departments or groups that will
              contribute to or be affected by the use case.
            </Typography>
            <SearchableDropdown<Department>
              label="Select or Search"
              multiple={true}
              value={departments.length === 0 ? [] : departmentDetails?.results ?? []}
              onChange={(newDepartment) => newDepartment != null && setDepartments(newDepartment)}
              getOptionLabel={(department) => department.name}
              getOptions={searchDepartments}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(department) => [department.name, ""]}
              actionButton={["Manage Teams", () => setOpenOrgChart(true)]}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h3" color={textColor}>
              Reviewers
            </Typography>
            <Typography variant="body2">
              List the names of individuals who will review this use case. These reviewers will
              provide feedback and ensure the use case meets all necessary standards and
              requirements.
            </Typography>
            <SearchableDropdown<User>
              label="Select or Search"
              multiple={true}
              value={reviewers.length === 0 ? [] : reviewersUsers?.results ?? []}
              onChange={(newUser) => newUser != null && setReviewers(newUser)}
              getOptionLabel={(department) => department.name}
              getOptions={searchUsers}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(user) => [user.name, user.username]}
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" gap="10px" justifyContent="space-between">
          <LoadingButton onClick={() => onBack()}>Go back</LoadingButton>
          <LoadingButton variant="contained" onClick={() => handleNext()} loading={loading}>
            Next
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
