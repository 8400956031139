import { FileAttachment } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpFormPatchAuthenticated,
  httpFormPostAuthenticated,
  httpGetAuthenticated,
} from "./ApiService";

const fileEndpoints = {
  files: () => "/files",
  file: (fileId: string) => `/files/${fileId}`,
};

export const getFiles = (params: any) => {
  return httpGetAuthenticated<ListResponse<FileAttachment>>(fileEndpoints.files(), {
    params,
  });
};

export const getFile = (params: { id: string }) => {
  return httpGetAuthenticated<FileAttachment>(fileEndpoints.file(params.id), {
    params,
  });
};

export const addFile = async (
  objectId: string,
  attachment: File,
  queryKey: string,
  name?: string,
  description?: string
) => {
  let formData = new FormData();
  formData.append("file_object", attachment);
  formData.append("parent_object_id", objectId);
  formData.append("name", name ?? "");
  formData.append("description", description ?? "");
  const response = await httpFormPostAuthenticated(fileEndpoints.files(), formData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.File] });
  return response;
};

export const deleteAttachment = async (fileId: string, queryKey: string) => {
  const response = await httpDeleteAuthenticated(fileEndpoints.file(fileId), {});
  queryClient.invalidateQueries({ queryKey: [QueryKey.File] });
  return response;
};

export const editFile = async (fileId: string, name: string, description: string) => {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  const response = await httpFormPatchAuthenticated(fileEndpoints.file(fileId), formData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.File] });
  return response;
};
