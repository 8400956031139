import { Asset, AssetFetchOptions, ObjectSummary } from "../models/types";
import { httpGetAuthenticated } from "./ApiService";

const assetEndpoints = {
  assets: () => "/assets",
  asset: (assetId: string) => `/assets/${assetId}`,
  summary: () => "/assets/summary",
};

export const getAsset = (assetId: string) => {
  return httpGetAuthenticated<Asset>(assetEndpoints.asset(assetId));
};

export const getAssetsSummary = (params: AssetFetchOptions) => {
  return httpGetAuthenticated<ObjectSummary>(assetEndpoints.summary(), {
    params,
  });
};
