import { ImpactAssessment, ImpactAssessmentFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const impactAssessmentEndpoints = {
  impactAssessments: () => "/impact_assessments",
  impactAssessment: (impactAssessmentId: string) => `/impact_assessments/${impactAssessmentId}`,
  review: (impactAssessmentId: string) => `/impact_assessments/${impactAssessmentId}/review`,
};

export const getImpactAssessments = (params: ImpactAssessmentFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ImpactAssessment>>(
    impactAssessmentEndpoints.impactAssessments(),
    {
      params: {
        ...params,
        fairo_data: true,
      },
    }
  );
};

export const getImpactAssessment = (impactAssessmentId: string) => {
  return httpGetAuthenticated<ImpactAssessment>(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};

export const createImpactAssessmentReview = (
  impactAssessmentId: string,
  payload: {
    responses: {
      field_id: string;
      response_id: string;
    }[];
    questionnaire: string;
  }
) => {
  return httpPostAuthenticated(impactAssessmentEndpoints.review(impactAssessmentId), payload);
};

export const editImpactAssessment = async (
  impactAssessmentId: string,
  payload: {
    description?: string;
    assessment_owner?: string | null;
    related_teams?: string[];
    reviewers?: string[];
    metadata?: any;
    sections?: any;
  }
) => {
  const response = await httpPatchAuthenticated(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId),
    payload
  );
  queryClient.invalidateQueries([QueryKey.ImpactAssessment, impactAssessmentId]);
  return response;
};

export const editImpactAssessmentMetadata = (
  impactAssessmentId: string,
  payload: { metadata?: any; sections?: any }
) => {
  const response = httpPatchAuthenticated(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId),
    payload
  );
  queryClient.invalidateQueries([QueryKey.ImpactAssessment, impactAssessmentId]);
  return response;
};

export const createImpactAssessment = async (payload: any) => {
  const { data } = await httpPostAuthenticated(
    impactAssessmentEndpoints.impactAssessments(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ImpactAssessment] });
  return data;
};

export const deleteImpactAssessment = async (impactAssessmentId: string) => {
  const resp = await httpDeleteAuthenticated(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId)
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ImpactAssessment] });
  return resp;
};
