import { Box } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const AssetFilesLoader = () => {
  return (
    <Box
      sx={{ width: "100%", height: "100%", display: "flex", flex: 1, flexDirection: "column" }}
      gap="10px"
    >
      <RectangularLoader maxWidth="229px" height="30px" />
      <RectangularLoader maxWidth="100%" height="60px" />
      <Box display="flex" width="100%" gap="10px">
        {[...Array(4)].map((_, idx) => (
          <RectangularLoader width="100%" height="60px" />
        ))}
      </Box>
    </Box>
  );
};
