import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchAssets } from "../hooks/useAssets";
import { Asset } from "../models/types";
import { getAsset } from "../services/AssetService";
import { isDocumentation, isPolicy } from "../utilities/AssetsHelper";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const ASSET_NAVIGATOR_KEY = "asset-navigator";
export const useAssetPathState = (locationState: any, path: string) => {
  const assetId = locationState?.assetId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    assetId: string;
    edit: boolean;
  }>(
    [ASSET_NAVIGATOR_KEY, path],
    async () => {
      if (assetId != null) {
        return { assetId, edit };
      }

      const list = await fetchAssets({ asset_num: [number] });
      const obj = list.results.find((o) => o.asset_num === Number(number));
      if (!obj) {
        navigate("/assets");
        return { assetId: null, edit };
      }

      return { assetId: obj.id, edit };
    },
    { suspense: true }
  );
};

export const useAssetPolicyPathState = (locationState: any, path: string) => {
  const assetId = locationState?.assetId;
  const edit = locationState?.edit;
  const number = getFinalNumberFromPath(path.split("/")[3]);
  const navigate = useNavigate();
  return useQuery<{
    assetId: string;
    edit: boolean;
  }>(
    [ASSET_NAVIGATOR_KEY, path],
    async () => {
      if (assetId != null) {
        return { assetId, edit };
      }

      const list = await fetchAssets({ asset_num: [number] });
      const obj = list.results.find((o) => o.asset_num === Number(number));
      if (!obj) {
        navigate("/assets");
        return { assetId: null, edit };
      }

      return { assetId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToAsset = async (params: {
  navigate: NavigateFunction;
  assetId: string;
  assetNumber?: number;
  edit?: boolean;
  returnLink?: boolean;
  asset?: Asset;
}) => {
  const { assetId, edit, navigate, returnLink = false, asset } = params;

  let currentAsset = asset;
  if (!currentAsset) {
    console.log(assetId);
    const { data } = await getAsset(assetId);
    currentAsset = data;
  }

  if (isDocumentation(currentAsset)) {
    return navigateToAssetDocumentation({
      navigate,
      assetId,
      assetNumber: currentAsset.asset_num,
      edit,
    });
  }
  if (isPolicy(currentAsset)) {
    return navigateToAssetPolicy({ navigate, assetId, assetNumber: currentAsset.asset_num, edit });
  }
  if (returnLink) {
    return `/assets/ai-lifecycle/asset-${currentAsset.asset_num}`;
  }
  navigate(`/assets/ai-lifecycle/asset-${currentAsset.asset_num}`, {
    state: {
      assetId,
      edit,
    },
  });
};

const navigateToAssetPolicy = (params: {
  navigate: NavigateFunction;
  assetId: string;
  assetNumber: number;
  edit?: boolean;
}) => {
  const { assetId, assetNumber, edit, navigate } = params;
  navigate(`/assets/policies/policy-${assetNumber}`, {
    state: {
      assetId,
      edit,
    },
  });
};

const navigateToAssetDocumentation = (params: {
  navigate: NavigateFunction;
  assetId: string;
  assetNumber: number;
  edit?: boolean;
}) => {
  const { assetId, assetNumber, edit, navigate } = params;
  navigate(`/assets/documentation/documentation-${assetNumber}`, {
    state: {
      assetId,
      edit,
    },
  });
};

export { navigateToAsset, navigateToAssetDocumentation, navigateToAssetPolicy };
